// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Form, { FormInstance } from 'antd/lib/form';
import Input from 'antd/lib/input';
import Tooltip from 'antd/lib/tooltip';
import Checkbox from 'antd/lib/checkbox';
import Text from 'antd/lib/typography/Text';

type Props = {};

export class CreateModelForm extends React.PureComponent<Props> {
    private formRef: React.RefObject<FormInstance>;

    constructor(props: Props) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
    }
 
    public submit(): Promise<{ name: string; global: boolean }> {
        return new Promise((resolve, reject) => {
            this.formRef.current?.validateFields().then((values) => {
                resolve({
                    name: values.name,
                    global: values.global,
                });
            }).catch((errors) => {
                reject(errors);
            });
        });
    }

    public resetFields(): void {
        this.formRef.current?.resetFields();
    }

    public render(): JSX.Element {
        return (
            <Form
                ref={this.formRef}
            >
                <Row>
                    <Col span={24}>
                        <Text type='danger'>* </Text>
                        <Text className='cvat-text-color'>Name:</Text>
                    </Col>
                    <Col span={14}>
                        <Form.Item
                            name='name'
                            rules={[{
                                required: true,
                                message: 'Please, specify a model name',
                            }]}
                            hasFeedback
                        >
                            <Input placeholder='Model name' />
                        </Form.Item>
                    </Col>
                    <Col span={8} offset={2}>
                        <Tooltip title='Will this model be availabe for everyone?'>
                            <Form.Item
                                name='global'
                                initialValue={false}
                                valuePropName='checked'
                            >
                                <Checkbox>
                                    <Text className='cvat-text-color'>
                                        Load globally
                                    </Text>
                                </Checkbox>
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default CreateModelForm;
