import React from 'react';

import { Col, Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {
    index: string;
    images: {
        prediction: string;
        groundTruth: string;
    };
}

interface State {
    updating: boolean;
}

const defaultState: State = {
    updating: false,
};

class PredictionExampleItemComponent extends React.PureComponent<Props, State> {
    preview_image_url: any = null;

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
    }

    public render(): JSX.Element {
        const {
            index,
            images: {
                prediction,
                groundTruth,
            },
        } = this.props;
        const { t } = this.props;
        return (
            <div className='cvat-training-model'>
                <Row gutter={16}>
                    {`Example #${index + 1}:`}
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Text strong>{t('Ground Truth')}</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong>{t('Prediction')}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <div><img width='100%' src={groundTruth} alt='' /></div>
                    </Col>
                    <Col span={12}>
                        <div><img width='100%' src={prediction} alt='' /></div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(PredictionExampleItemComponent);
