import React from 'react';

import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import store from 'store';
import Form, { FormInstance } from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input, { InputRef } from 'antd/lib/input';
import { Row, Col } from 'antd/lib/grid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LockOutlined } from '@ant-design/icons';
import { passwordValidator } from 'utils/validation';

interface NewPasswordComponentProps {
    fetching: boolean;
    onSubmit: (code: string, password:string) => void;
}

interface NewPasswordComponentState {
    code: string,
    password: string,
    validPassword1: boolean,
    validPassword2: boolean,
}

type NewPasswordFormProps = NewPasswordComponentProps & RouteComponentProps;

class NewPasswordFormComponent extends React.Component<NewPasswordFormProps, NewPasswordComponentState> {
    private formRef: React.RefObject<FormInstance>;
    private passwordInput: React.RefObject<InputRef>;

    constructor(props: any) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
        this.passwordInput = React.createRef<InputRef>();
        this.state = {
            code: '',
            password: '',
            validPassword1: false,
            validPassword2: false,
        };
    }

    public componentDidMount(): void {
        this.passwordInput.current?.focus();
    }

    private validateConfirmation = (rule: any, value: any): Promise<any> => {
        let valid = false;
        if (value) {
            console.log(value, this.formRef.current, this.formRef.current?.getFieldValue('password1'));
            if (value !== this.formRef.current?.getFieldValue('password1')) {
                this.setState({ validPassword2: false });
                return Promise.reject('Two passwords that you enter is inconsistent!');
            } else {
                valid = true;
            }
        }
        this.setState({ validPassword2: valid });
        return Promise.resolve();
    };

    private validatePassword = (_: any, value: any): Promise<any> => {
        const msg = passwordValidator(value);
        if (msg) {
            this.setState({ validPassword1: false });
            return Promise.reject(msg);
        }
        this.setState({ validPassword1: true });
        return Promise.resolve();
    };

    render(): JSX.Element {
        const { fetching, onSubmit } = this.props;
        const {
            code,
            password,
            validPassword1,
            validPassword2,
        } = this.state;

        const email = store.get('email');

        return (
            <Form
                ref={this.formRef}
                onFinish={() => {
                    onSubmit(code, password);
                }}
            >
                <Form.Item
                    name='password1'
                    rules={[{
                        required: true,
                        message: 'Please input your password!',
                    }, {
                        validator: this.validatePassword,
                    }]}
                    hasFeedback
                >
                    <Input.Password
                        onChange={(e) => this.setState({ password: e.target.value })}
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                        autoComplete='new-password'
                        placeholder='Password'
                        type='password'
                        ref={this.passwordInput}
                    />
                </Form.Item>
                <Form.Item
                    name='password2'
                    dependencies={['password1']}
                    rules={[{
                        required: true,
                        message: 'Please confirm your password!',
                    }, {
                        validator: this.validateConfirmation,
                    }]}
                    hasFeedback
                >
                    <Input.Password
                        autoComplete='new-password'
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                        placeholder='Confirm password'
                    />
                </Form.Item>
                <Row style={{ marginTop: 10 }} justify='start' align='top'>
                    <Col>
                        <Text strong>
                            {`Verification code has been sent to ${email}`}
                        </Text>
                    </Col>
                </Row>

                <Form.Item
                    name='code'
                    rules={[{
                        required: true,
                        message: 'Please input verification code',
                    }]}
                    hasFeedback
                >
                    <Input
                        onChange={(e) => this.setState({ code: e.target.value })}
                        placeholder='Verification code'
                    />
                </Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    loading={fetching}
                    disabled={fetching || !validPassword1 || !validPassword2}
                >
                    Change password
                </Button>
            </Form>
        );
    }
}

const NewPasswordForm = withRouter(NewPasswordFormComponent);

function NewPasswordComponent(
    props: NewPasswordComponentProps & RouteComponentProps,
): JSX.Element {
    const sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 4 },
        xl: { span: 4 },
    };

    return (
        <Row justify='center' align='middle'>
            <Col {...sizes}>
                <Title level={2}>Change your password</Title>
                <NewPasswordForm {...props} />
                <Row style={{ marginTop: 10 }} justify='start' align='top'>
                    <Col>
                        <Text strong>
                            Switch to another account?
                            <Link to='/auth/login'> Login </Link>
                        </Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default withRouter(NewPasswordComponent);
