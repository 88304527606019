import React from 'react';
import { connect } from 'react-redux';

import { updateUserAttributesAsync, updateCognitoEmailAsync, deactivateAsync } from 'actions/auth-actions';
import { CombinedState, UserAgreement } from 'reducers/interfaces';
import ProfileSettingsComponent from 'components/settings-page/profile-settings';

interface StateToProps {
    fetching: boolean;
    userAgreements: UserAgreement[];
    user: any;
}

interface DispatchToProps {
    onSubmit(data: any): void;
    onEmailSubmit(oldEmail: string, newEmail: string): void;
    onDeactivateAccount(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const user = state.auth.user;
    const fetching = state.auth.fetching;
    const userAgreements = state.userAgreements.list;

    return {
        fetching,
        user,
        userAgreements,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onSubmit(data: any): void {
            dispatch(updateUserAttributesAsync(data));
        },
        onEmailSubmit(oldEmail: string, newEmail: string): void {
            dispatch(updateCognitoEmailAsync(oldEmail, newEmail));
        },
        onDeactivateAccount(): void {
            dispatch(deactivateAsync());
        },
    };
}

function ProfileSettingsContainer(props: StateToProps & DispatchToProps): JSX.Element {
    return (
        <ProfileSettingsComponent {...props} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileSettingsContainer);
