// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
// import Tabs from 'antd/lib/tabs';
// import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';
// import Paragraph from 'antd/lib/typography/Paragraph';
import Upload, { RcFile } from 'antd/lib/upload';
// import Empty from 'antd/lib/empty';
// import Tree, { AntTreeNode, TreeNodeNormal } from 'antd/lib/tree/Tree';
import { TreeNodeNormal } from 'antd/lib/tree/Tree';

import { Checkbox } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    InboxOutlined,
} from '@ant-design/icons';

export interface Files {
    local: RcFile[];
    share: string[];
    remote: string[];
}

interface State {
    files: Files;
    // expandedKeys: string[];
    active: 'local' | 'share' | 'remote';
    isFolder: boolean;
    lastTime: number;
}

interface Props {
    withRemote: boolean;
    treeData: TreeNodeNormal[];
    plan: string;
    onLoadData: (key: string, success: () => void, failure: () => void) => void;
    hintText: string;
    directoryUploadable: boolean;
    // https://github.com/i18next/react-i18next/issues/1279
    ref?: React.RefObject<FileManager>
}

function fileFilter(file: File): boolean {
    return file.name.lastIndexOf('.') > 0 && !file.name.endsWith('.');
}

class FileManager extends React.PureComponent<Props & WithTranslation, State> {
    public constructor(props: Props & WithTranslation) {
        super(props);

        this.state = {
            files: {
                local: [],
                share: [],
                remote: [],
            },
            // expandedKeys: [],
            active: 'local',
            isFolder: false,
            lastTime: (new Date()).getTime(),
        };

        this.loadData('/');
    }

    public getFiles(): Files {
        const {
            active,
            files,
        } = this.state;
        return {
            local: active === 'local' ? files.local : [],
            share: active === 'share' ? files.share : [],
            remote: active === 'remote' ? files.remote : [],
        };
    }

    private getTotalFilesSize(): number {
        const { files } = this.state;
        return files.local.reduce((acc, file) => acc + file.size, 0);
    }

    private getTotalFileSizeMB(): number {
        return Math.round((this.getTotalFilesSize() / 1024 / 1024) * 100) / 100;
    }

    private getMaxUploadSize(): number {
        const { plan } = this.props;

        // task name, labels, advansed settings, etc
        const otherSize = 1 * 1024 * 1024; // 1Mb

        let maxUploadSize = 100 * 1024 * 1024; // 100Mb
        if (plan === 'Pro') {
            maxUploadSize = 2048 * 1024 * 1024; // 2Gb
        } else if (plan === 'Business') {
            maxUploadSize = 2048 * 1024 * 1024; // 2Gb
        }
        maxUploadSize -= otherSize;
        return maxUploadSize;
    }

    public validateFileSize(): boolean {
        return this.getTotalFilesSize() <= this.getMaxUploadSize();
    }

    public checkDuplicates(): boolean {
        const { files } = this.state;

        const names = files.local.map((file) => file.name);
        const uniqueNames = new Set(names);
        return names.length !== uniqueNames.size;
    }

    private loadData = (key: string): Promise<void> => new Promise<void>(
        (resolve, reject): void => {
            const { onLoadData } = this.props;

            const success = (): void => resolve();
            const failure = (): void => reject();
            onLoadData(key, success, failure);
        },
    );

    public reset(): void {
        this.setState({
            // expandedKeys: [],
            active: 'local',
            files: {
                local: [],
                share: [],
                remote: [],
            },
            isFolder: false,
        });
    }

    private renderLocalSelector(): JSX.Element {
        const { files, isFolder, lastTime } = this.state;
        const { hintText, directoryUploadable, t } = this.props;

        return (
            <>
                {directoryUploadable && (
                    <Checkbox
                        checked={isFolder}
                        onChange={(e) => this.setState({ isFolder: e.target.checked })}
                        style={{ marginInline: '12px' }}
                    >
                        {t('Upload folder')}
                    </Checkbox>
                )}
                <Upload.Dragger
                    multiple
                    directory={directoryUploadable && isFolder}
                    listType='text'
                    fileList={files.local as any[]}
                    showUploadList={files.local.length <= 5 && {
                        showRemoveIcon: false,
                    }}
                    beforeUpload={(_: RcFile, newLocalFiles: RcFile[]): boolean => {
                        const now = (new Date()).getTime();
                        if (now - lastTime > 100) {
                            this.setState({
                                files: {
                                    ...files,
                                    local: [],
                                },
                            });
                        }
                        this.setState((state) => ({
                            files: {
                                ...state.files,
                                local: [
                                    ...state.files.local,
                                    ...newLocalFiles.filter(fileFilter),
                                ],
                            },
                            lastTime: now,
                        }));
                        return false;
                    }}
                >
                    <p className='ant-upload-drag-icon'>
                        <InboxOutlined rev='' />
                    </p>
                    <p className='ant-upload-text'>{t('Click or drag files to this area')}</p>
                    <p className='ant-upload-hint'>
                        {hintText}
                    </p>
                </Upload.Dragger>
                { files.local.length >= 5 &&
                    (
                        <>
                            <br />
                            <Text className='cvat-text-color'>
                                {`${files.local.length} files selected`}
                            </Text>
                        </>
                    )}
                <br />
                <Text className='cvat-text-color'>
                    {`${this.getTotalFileSizeMB()} MB`}
                </Text>
                { !this.validateFileSize() &&
                    (
                        <>
                            <br />
                            <Text type='danger'>
                                {t('The total size of the selected files exceeds plan limit')}
                            </Text>
                        </>
                    )}
                { this.checkDuplicates() &&
                    (
                        <>
                            <br />
                            <Text type='danger'>
                                {t('Some files have the same names')}
                            </Text>
                        </>
                    )}
            </>
        );
    }

    // private renderShareSelector(): JSX.Element {
    //     function renderTreeNodes(data: TreeNodeNormal[]): JSX.Element[] {
    //         return data.map((item: TreeNodeNormal) => {
    //             if (item.children) {
    //                 return (
    //                     <Tree.TreeNode
    //                         title={item.title}
    //                         key={item.key}
    //                         dataRef={item}
    //                         isLeaf={item.isLeaf}
    //                     >
    //                         {renderTreeNodes(item.children)}
    //                     </Tree.TreeNode>
    //                 );
    //             }

    //             return <Tree.TreeNode key={item.key} {...item} dataRef={item} />;
    //         });
    //     }

    //     const { SHARE_MOUNT_GUIDE_URL } = consts;
    //     const { treeData } = this.props;
    //     const {
    //         expandedKeys,
    //         files,
    //     } = this.state;

    //     return (
    //         <Tabs.TabPane key='share' tab='Connected file share'>
    //             { treeData[0].children && treeData[0].children.length
    //                 ? (
    //                     <Tree
    //                         className='cvat-share-tree'
    //                         checkable
    //                         showLine
    //                         checkStrictly={false}
    //                         expandedKeys={expandedKeys}
    //                         checkedKeys={files.share}
    //                         loadData={(node: AntTreeNode): Promise<void> => this.loadData(
    //                             node.props.dataRef.key,
    //                         )}
    //                         onExpand={(newExpandedKeys: string[]): void => {
    //                             this.setState({
    //                                 expandedKeys: newExpandedKeys,
    //                             });
    //                         }}
    //                         onCheck={
    //                             (checkedKeys: string[] | {
    //                                 checked: string[];
    //                                 halfChecked: string[];
    //                             }): void => {
    //                                 const keys = checkedKeys as string[];
    //                                 this.setState({
    //                                     files: {
    //                                         ...files,
    //                                         share: keys,
    //                                     },
    //                                 });
    //                             }
    //                         }
    //                     >
    //                         { renderTreeNodes(treeData) }
    //                     </Tree>
    //                 ) : (
    //                     <div className='cvat-empty-share-tree'>
    //                         <Empty />
    //                         <Paragraph className='cvat-text-color'>
    //                             Please, be sure you had
    //                             <Text strong>
    //                                 <a href={SHARE_MOUNT_GUIDE_URL}> mounted </a>
    //                             </Text>
    //                             share before you built CVAT and the shared storage contains files
    //                         </Paragraph>
    //                     </div>
    //                 )}
    //         </Tabs.TabPane>
    //     );
    // }

    // private renderRemoteSelector(): JSX.Element {
    //     const { files } = this.state;

    //     return (
    //         <Tabs.TabPane key='remote' tab='Remote sources'>
    //             <Input.TextArea
    //                 placeholder='Enter one URL per line'
    //                 rows={6}
    //                 value={[...files.remote].join('\n')}
    //                 onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    //                     this.setState({
    //                         files: {
    //                             ...files,
    //                             remote: event.target.value.split('\n'),
    //                         },
    //                     });
    //                 }}
    //             />
    //         </Tabs.TabPane>
    //     );
    // }

    public render(): JSX.Element {
        return (
            this.renderLocalSelector()
        );
    }
}

export { FileManager };
export default withTranslation(undefined, { withRef: true })(FileManager);
