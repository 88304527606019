// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { Spin } from 'antd';
import { Row, Col } from 'antd/lib/grid';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import TextArea from 'antd/lib/input/TextArea';
import { withTranslation, WithTranslation } from 'react-i18next';

import checkReachedTaskLimit from 'utils/plan';
import CreateTaskContent, { CreateTaskData } from './create-task-content';

const creationTerminateStatus = ['CREATED', 'FAILED', 'CANCELED'];

interface Props extends WithTranslation {
    onCreate: (data: CreateTaskData) => void;
    id: number | null;
    status: string;
    error: string;
    installedGit: boolean;
    user: any;
}

interface State {
    initialized: boolean;
    isReachedLimit: boolean;
    creating: boolean;
}

class CreateTaskPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { initialized: false, isReachedLimit: true, creating: false };
    }
    public componentDidMount():void {
        this.checkTaskLimit();
    }

    public componentDidUpdate(prevProps: Props): void {
        const { status } = this.props;
        const { creating } = this.state;
        if (status && prevProps.status !== status) {
            if (creating && creationTerminateStatus.includes(status)) {
                this.setState({ creating: false });
            }
            if (!creating && !creationTerminateStatus.includes(status)) {
                this.setState({ creating: true });
            }
        }
    }

    private onCreate(data: CreateTaskData): void {
        const { onCreate } = this.props;
        this.setState({ creating: true });
        onCreate(data);
    }

    private async checkTaskLimit(): Promise<void> {
        const { user } = this.props;
        const isReachedLimit = await checkReachedTaskLimit(user);
        this.setState({ initialized: true, isReachedLimit });
    }

    private checkSshKey(): void {
        const { error } = this.props;
        if (error) {
            let errorCopy = error;
            const sshKeys: string[] = [];
            while (errorCopy.length) {
                const startIndex = errorCopy.search(/'ssh/);
                if (startIndex === -1) break;
                let sshKey = errorCopy.slice(startIndex + 1);
                const stopIndex = sshKey.search(/'/);
                sshKey = sshKey.slice(0, stopIndex);
                sshKeys.push(sshKey);
                errorCopy = errorCopy.slice(stopIndex + 1);
            }

            if (sshKeys.length) {
                Modal.error({
                    width: 800,
                    title: 'Could not clone the repository',
                    content: (
                        <>
                            <Paragraph>
                                <Text>Please make sure it exists and you have access</Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Consider adding the following public ssh keys to git: </Text>
                            </Paragraph>
                            <TextArea rows={10} value={sshKeys.join('\n\n')} />
                        </>
                    ),
                });
            }
        }
    }

    public render(): JSX.Element {
        const {
            id,
            error,
            status,
            onCreate,
            installedGit,
            t,
        } = this.props;
        const { initialized, isReachedLimit, creating } = this.state;

        if (error) {
            this.checkSshKey();
        }

        return (
            <>
                <Row justify='center' align='top' className='cvat-create-task-form-wrapper'>
                    <Col md={20} lg={16} xl={14} xxl={9}>
                        <Text className='cvat-title'>{t('Create a new task')}</Text>
                        <CreateTaskContent
                            status={status}
                            id={id}
                            onCreate={(data: CreateTaskData) => {
                                this.setState({ creating: true });
                                onCreate(data);
                            }}
                            installedGit={installedGit}
                            isReachedLimit={isReachedLimit}
                            initialized={initialized}
                        />
                    </Col>
                </Row>
                {creating && (
                    <Row className='eyes-create-task-overlay' justify='center' align='middle'>
                        <Col>
                            <Spin size='large' tip={status} />
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}

export default withTranslation()(CreateTaskPage);
