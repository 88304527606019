import React from 'react';

import { Link } from 'react-router-dom';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import store from 'store';
import {
    Col,
    Row,
    Button,
} from 'antd';
import Form, { FormInstance } from 'antd/lib/form';
import Input, { InputRef } from 'antd/lib/input';
import { withTranslation, WithTranslation } from 'react-i18next';

interface PasswordResetFormProps extends WithTranslation {
    fetching: boolean;
    onSubmit: (email: string) => void;
}

interface PasswordResetFormState {
    email: string;
}

class PasswordResetFormComponent extends React.Component<PasswordResetFormProps, PasswordResetFormState> {
    private formRef: React.RefObject<FormInstance>;
    private emailInput: React.RefObject<InputRef>;

    constructor(props: any) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
        this.emailInput = React.createRef<InputRef>();
        this.state = {
            email: store.get('email'),
        };
    }

    public componentDidMount(): void {
        this.emailInput.current?.focus();
    }

    render(): JSX.Element {
        const { fetching, onSubmit } = this.props;
        const { t } = this.props;
        const { email } = this.state;

        return (
            <Form
                ref={this.formRef}
                onFinish={() => {
                    onSubmit(email);
                }}
            >
                <Form.Item
                    name='email'
                    rules={[{
                        required: true,
                        message: t('Please specify an email address'),
                    }]}
                >
                    <Input
                        name='email'
                        onChange={(e) => this.setState({ email: e.target.value })}
                        placeholder='Email address'
                        type='email'
                        ref={this.emailInput}
                    />
                </Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    loading={fetching}
                    disabled={fetching}
                >
                    {t('Reset')}
                </Button>
            </Form>
        );
    }
}

const PasswordResetForm =
    withTranslation()(PasswordResetFormComponent);

function PasswordResetComponent(
    props: PasswordResetFormProps,
): JSX.Element {
    const sizes = {
        xs: { span: 15 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 8 },
        xl: { span: 6 },
    };

    const { t } = props;

    return (
        <Row justify='center' align='middle'>
            <Col {...sizes}>
                <Title level={2}>
                    {t('Reset password of your account')}
                </Title>
                <PasswordResetForm {...props} />
                <Row
                    style={{ marginTop: 10 }}
                    justify='start'
                    align='top'
                >
                    <Col>
                        <Text strong>
                            {t('Switch to another account?')}
                            <Link to='/auth/login'>
                                {t('Login')}
                            </Link>
                        </Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default withTranslation()(PasswordResetComponent);
