import i18n, { use as i18Use, changeLanguage as i18nChaneLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import { isDev } from 'utils/enviroment';
import enTranslation from './locales/en/translation.json';
import jaTranslation from './locales/ja/translation.json';
import enSettingsPage from './locales/en/settings-page.json';
import jaSettingsPage from './locales/ja/settings-page.json';

const resources = {
    en: {
        translation: enTranslation,
        settingsPage: enSettingsPage,
    },
    ja: {
        translation: jaTranslation,
        settingsPage: jaSettingsPage,
    },
};

i18Use(initReactI18next).init({
    lng: 'ja',

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,

    fallbackLng: 'ja',
    debug: isDev(),

    interpolation: {
        escapeValue: false,
        /* not using format now
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        format(value, format, lng) {
            if (format === 'by') {
                return value ? `by ${value}` : '';
            }
            if (format === 'created-ja') {
                return value ? `作成者 ${value}` : '';
            }
            return value;
        },
        */
    },

    react: {
        useSuspense: true,
    },
    resources,
});

export default i18n;

export function changeLanguage(lang: string): void {
    moment.locale(lang);
    i18nChaneLanguage(lang);
}

// develooment convenient method (in browser console)
// window.lang.change('en')
if (process.env.NODE_ENV === 'development') {
    /*
    interface Window {
        lang: any;
    }
    */
    declare const window: Window & typeof globalThis & {
        lang: any
    };

    (() => {
        window.lang = window.lang || {
            change: (lang: any) => {
                changeLanguage(lang);
            },
        };
    })();
}
