// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import ActionsMenuContainer from 'containers/actions-menu/actions-menu';

interface DetailsComponentProps {
    taskInstance: any;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { taskInstance } = props;
    const { id } = taskInstance;
    const { t } = useTranslation();

    return (
        <Row className='cvat-task-top-bar' justify='space-between' align='middle'>
            <Col>
                <Text className='cvat-title'>
                    {`${t('Task details')} #${id}`}
                </Text>
            </Col>
            <Col>
                <ActionsMenuContainer taskInstance={taskInstance} />
            </Col>
        </Row>
    );
}
