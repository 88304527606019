import store from 'store';
import { default as Axios } from 'axios';

import {
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    CognitoUserAttribute,
    AuthenticationDetails,
    ICognitoStorage,
} from 'amazon-cognito-identity-js';

import { ProfileData } from 'reducers/interfaces';

import getCore from 'cvat-core-wrapper';

import awsConfiguration from '../../awsConfiguration';

// import { deactivateApi } from './admin';

const cvat = getCore();
const baseURL = cvat.config.backendAPI.slice(0, -7);

Axios.defaults.withXSRFToken = true;
Axios.defaults.withCredentials = true;
Axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
Axios.defaults.xsrfCookieName = 'csrftoken';

const authToken = store.get('token');
if (authToken) {
    Axios.defaults.headers.common.Authorization = `Token ${authToken}`;
}

class ServerError extends Error {
    constructor(message: any, code: any) {
        super(message);

        Object.defineProperties(this, Object.freeze({
            code: {
                get: () => code,
            },
        }));
    }
}

const UserPoolId = awsConfiguration.UserPoolId ? awsConfiguration.UserPoolId : '';
const ClientId = awsConfiguration.ClientId ? awsConfiguration.ClientId : '';
const Storage: ICognitoStorage = {
    ...window.localStorage,
    setItem: (key: string, value: string) => window.localStorage.setItem(key, value),
    getItem: (key: string) => {
        const value = window.localStorage.getItem(key);
        return value !== null ? value : '';
    },
    removeItem: (key: string) => window.localStorage.removeItem(key),
};
const userPool = new CognitoUserPool({ UserPoolId, ClientId, Storage });

function getUser(): CognitoUser {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) return cognitoUser;
    return new CognitoUser({
        Username: store.get('cognitoUsername'),
        Pool: userPool,
        Storage,
    });
}

function generateError(errorData: any): ServerError {
    if (errorData.response) {
        const message = `${errorData.message}. ${JSON.stringify(errorData.response.data) || ''}.`;
        return new ServerError(message, errorData.response.status);
    }

    // Server is unavailable (no any response)
    const message = `${errorData.message}.`; // usually is "Error Network"
    return new ServerError(message, 0);
}

// function twoFactorCognito(cognitoUser: any, code: string): Promise<void> {
//     return new Promise((resolve, reject) => {
//         cognitoUser.sendMFACode(
//             code,
//             {
//                 onSuccess: resolve,
//                 onFailure: reject,
//             },
//             'SMS_MFA',
//         );
//     });
// }

async function loginDjango(username: string): Promise<void> {
    const authenticationData = ([
        `${encodeURIComponent('username')}=${encodeURIComponent(username)}`,
    ]).join('&').replace(/%20/g, '+');

    let backendResponse = null;
    try {
        backendResponse = await Axios.post(
            `${cvat.config.backendAPI}/auth/login`,
            authenticationData,
            {
                proxy: cvat.config.proxy,
            },
        );
    } catch (error) {
        throw generateError(error);
    }

    if (backendResponse.headers['set-cookie']) {
        // Browser itself setup cookie and header is none
        // In NodeJS we need do it manually
        const cookies = backendResponse.headers['set-cookie'].join(';');
        Axios.defaults.headers.common.Cookie = cookies;
    }

    const token = backendResponse.data.key;
    store.set('token', token);
    Axios.defaults.headers.common.Authorization = `Token ${token}`;
}

function loginCognito(email: string, password: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
            Storage,
        });
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: resolve,
            onFailure: reject,
            // mfaRequired: (challengeName, challengeParameters) => reject(),
        });
    });
}

export async function loginApi(username: string, password: string): Promise<void> {
    store.set('email', username);
    try {
        const cognitoResponse = await loginCognito(username, password);
        const idToken = cognitoResponse.getIdToken().getJwtToken();

        Axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
        await loginDjango(username).then(() => {
            store.set('cognitoUsername', getUser().getUsername());
        });
    } catch (error: any) {
        if (error.challengeName === 'SMS_MFA') {
            store.set('twoFactorDestination', error.challengeParameters.CODE_DELIVERY_DESTINATION);
            store.set('cognitoUsername', error.cognitoUser.getUsername());
            store.set('session', error.cognitoUser.Session);
            return;
        }
        throw generateError(error);
    }
}

function reLogin(): void {
    const cognitoUser = getUser();
    cognitoUser.getSession((err: any) => {
        if (err) throw generateError(err);
    });
    const signInUserSession = cognitoUser.getSignInUserSession();
    if (signInUserSession === null) throw generateError('User session not found');
    const refreshToken = signInUserSession.getRefreshToken();
    cognitoUser.refreshSession(refreshToken, (err: any) => {
        if (err) throw generateError(err);
    });
    const idToken = signInUserSession.getIdToken().getJwtToken();
    Axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
    cognitoUser.getUserAttributes((err: any, result: any) => {
        if (err) {
            throw generateError(err);
        }
        const email = result.find((item: any) => item.getName() === 'email');
        if (email) {
            loginDjango(email.getValue());
        } else {
            throw generateError(err);
        }
    });
}

/*
export async function twoFactorApi(email: string, code: string, remember: boolean): Promise<void> {
    let cognitoResponse: any = null;
    try {
        const cognitoUser = new CognitoUser({
            Username: store.get('cognitoUsername'),
            Pool: userPool,
            Storage,
        });
        cognitoUser.Session = store.get('session');
        cognitoResponse = await twoFactorCognito(cognitoUser, code);
        if (remember) {
            cognitoUser.setDeviceStatusRemembered({
                onSuccess: (result) => { console.debug(result); },
                onFailure: (err) => { throw err; },
            });
        }
        store.set('cognitoUsername', null);
        store.set('session', null);
    } catch (errorData) {
        throw generateError(errorData);
    }
    const idToken = cognitoResponse.getIdToken().getJwtToken();

    Axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
    await loginDjango(email);
}
*/

export async function passwordResetApi(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
        store.set('email', email);
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
            Storage,
        });
        cognitoUser.forgotPassword(
            {
                onSuccess: resolve,
                onFailure: (e) => reject(e),
            },
        );
    });
}

export async function newPasswordApi(code: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: store.get('email'),
            Pool: userPool,
            Storage,
        });
        cognitoUser.confirmPassword(code, password,
            {
                onSuccess: resolve,
                onFailure: (e) => reject(e),
            });
    });
}

function verifyCognito(verificationCode: string): Promise<unknown> {
    const cognitoUser = getUser();
    cognitoUser.getSession((err: any) => {
        if (err) throw generateError(err);
    });
    return new Promise((resolve, reject) => {
        cognitoUser.verifyAttribute('email', verificationCode, {
            onSuccess: (result) => resolve(result),
            onFailure: (err) => reject(err),
        });
    });
}

export async function verifyApi(code: string): Promise<void> {
    try {
        await reLogin();
        await verifyCognito(code);
    } catch (errorData) {
        throw generateError(errorData);
    }
}

function resendCognito(): Promise<unknown> {
    return new Promise((resolve, reject) => {
        const cognitoUser = getUser();
        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
}

export async function resendVerificationCodeApi(): Promise<void> {
    try {
        await resendCognito();
    } catch (errorData) {
        throw generateError(errorData);
    }
}

export async function sendNotificationApi(email: string): Promise<void> {
    const cognitoUser = getUser();
    try {
        await Axios.post(
            `${cvat.config.backendAPI}/auth/send-notification`,
            JSON.stringify({ userId: cognitoUser.getUsername(), email }),
            { proxy: cvat.config.proxy, headers: { 'Content-Type': 'application/json' } },
        );
    } catch (error) {
        throw generateError(error);
    }
}

export async function updateCognitoEmailApi(email: string): Promise<void> {
    const cognitoUser = getUser();
    try {
        await Axios.post(
            `${cvat.config.backendAPI}/auth/change-cognito-email`,
            JSON.stringify({ userId: cognitoUser.getUsername(), email }),
            { proxy: cvat.config.proxy, headers: { 'Content-Type': 'application/json' } },
        );
    } catch (error) {
        throw generateError(error);
    }
}

export async function revertCognitoEmailApi(email: string): Promise<void> {
    const cognitoUser = getUser();
    try {
        await Axios.post(
            `${cvat.config.backendAPI}/auth/revert-cognito-email`,
            JSON.stringify({ userId: cognitoUser.getUsername(), email }),
            { proxy: cvat.config.proxy, headers: { 'Content-Type': 'application/json' } },
        );
    } catch (error) {
        throw generateError(error);
    }
}

export async function updateDjangoEmailApi(email: string): Promise<void> {
    const cognitoUser = getUser();
    try {
        await Axios.post(
            `${cvat.config.backendAPI}/auth/change-django-email`,
            JSON.stringify({ userId: cognitoUser.getUsername(), email }),
            { proxy: cvat.config.proxy, headers: { 'Content-Type': 'application/json' } },
        );
        await sendNotificationApi(email);
    } catch (error) {
        throw generateError(error);
    }
    await reLogin();
}

function updateCognito(data: ProfileData): Promise<unknown> {
    const cognitoUser = getUser();
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err: any) => {
            if (err) reject(err);
            else {
                let isChangedAttribute = false;
                const attributes = {
                    nickname: data.userName,
                    address: data.address,
                    given_name: data.firstName,
                    family_name: data.lastName,
                    'custom:organization': data.organization,
                    // phone_number: data.phoneNumber
                    'custom:lang': data.lang,
                    'custom:purpose': data.purpose,
                };
                cognitoUser.getUserAttributes(
                    (err2: any, oldAttributes: any) => {
                        if (err2) generateError(err2);
                        else {
                            Object.entries(attributes).map(
                                ([key, value]) => {
                                    if (oldAttributes.find((item: any) => item.getName() === key && item.getName() !== 'custom:lang').getValue() !== value) {
                                        isChangedAttribute = true;
                                        return true;
                                    }
                                    return false;
                                },
                            );
                        }
                    },
                );
                cognitoUser.updateAttributes(Object.entries(attributes).map(
                    ([key, value]) => new CognitoUserAttribute({
                        Name: key,
                        Value: value,
                    }),
                ), (err2, result2) => {
                    if (err2) reject(err2);
                    else if (data.oldPassword && data.password1) {
                        cognitoUser.changePassword(data.oldPassword, data.password1,
                            (err3, result3) => {
                                if (err3) reject(err3);
                                else {
                                    sendNotificationApi(data.email);
                                    resolve(result3);
                                }
                            });
                    } else {
                        if (isChangedAttribute) sendNotificationApi(data.email);
                        resolve(result2);
                    }
                });
            }
        });
    });
}

export async function updateAttributesApi(data: any): Promise<void> {
    try {
        await updateCognito(data).then(() => {
            store.set('userName', data.userName);
            store.set('address', data.address);
            store.set('firstName', data.firstName);
            store.set('lastName', data.lastName);
            store.set('organization', data.organization);
            store.set('lang', data.lang);
            store.set('purpose', data.purpose);
            // Login again to refresh user information on django
            reLogin();
        });
    } catch (errorData) {
        throw generateError(errorData);
    }
}

function registerCognito(email: string, password: string, attributeList: any): Promise<unknown> {
    return new Promise((resolve, reject) => {
        userPool.signUp(email, password, attributeList, [], (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
}

export async function registerApi(
    userName: string, firstName: string, lastName: string, email: string,
    organization: string, address: string,
    // phone:string,
    lang: string, purpose: string,
    password: string, // confirmations: UserConfirmation[],
): Promise<void> {
    const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: email }),
        new CognitoUserAttribute({ Name: 'nickname', Value: userName }),
        new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
        new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
        new CognitoUserAttribute({ Name: 'custom:organization', Value: organization }),
        new CognitoUserAttribute({ Name: 'address', Value: address }),
        new CognitoUserAttribute({ Name: 'phone_number', Value: '' }),
        new CognitoUserAttribute({ Name: 'custom:lang', Value: lang }),
        new CognitoUserAttribute({ Name: 'custom:plan', Value: 'Basic' }),
        new CognitoUserAttribute({ Name: 'custom:purpose', Value: purpose }),
    ];
    try {
        await registerCognito(email, password, attributeList);
    } catch (errorData) {
        throw generateError(errorData);
    }
    store.set('email', email);
}

export async function logoutApi(): Promise<void> {
    try {
        const cognitoUser = getUser();
        cognitoUser.signOut();
    } catch {
        // Do nothing
    } finally {
        store.remove('email');
        store.remove('userName');
        store.remove('address');
        store.remove('firstName');
        store.remove('lastName');
        store.remove('organization');
        store.remove('lang');
        store.remove('purpose');
        store.remove('token');
        store.remove('cognitoUsername');
    }

    try {
        await Axios.post(`${cvat.config.backendAPI}/auth/logout`, {
            proxy: cvat.config.proxy,
        });
    } catch (errorData) {
        throw generateError(errorData);
    } finally {
        Axios.defaults.headers.common.Authorization = '';
    }
}

export async function deactivateUserApi(): Promise<void> {
    const cognitoId = getUser().getUsername();
    await cvat.server.request(
        `${baseURL}/user_manage/deactivate`, {
            method: 'POST',
            data: { cognitoId },
        },
    );
}
