// Copyright (C) 2020 Humanome Lab.

import React from 'react';
import { RouteComponentProps } from 'react-router';

import {
    Tag,
    Select,
} from 'antd';

import { Row, Col } from 'antd/lib/grid';

import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Model } from 'reducers/interfaces';
import getStatusColor from 'utils/trained-model';

export interface Props extends WithTranslation {
    model: Model;
    // owner: any;
    deleted: boolean;
}

class TrainedModelItem extends React.PureComponent<Props & RouteComponentProps> {
    public render(): JSX.Element {
        const {
            model,
            deleted,
            history,
        } = this.props;
        const { t } = this.props;

        const style = {};
        if (deleted) {
            (style as any).pointerEvents = 'none';
            (style as any).opacity = 0.5;
        }

        return (
            <Row
                className='cvat-models-list-item'
                justify='space-between'
                style={{ ...style }}
                onClick={(e: React.MouseEvent): void => {
                    e.preventDefault();
                    history.push(`models/${model.id}`);
                }}
            >
                <Col span={3}>
                    <Tag color={getStatusColor(model.status)}>
                        {t(model.status)}
                    </Tag>
                </Col>
                <Col span={9}>
                    <Text className='cvat-text-color'>
                        {model.name}
                    </Text>
                </Col>
                <Col span={7}>
                    <Text className='cvat-text-color'>
                        {moment(model.uploadDate).format(t('FORMAT_CREATED'))}
                    </Text>
                </Col>
                <Col span={5}>
                    <Col
                        span={22}
                        onClick={(e: React.MouseEvent): void => {
                            e.stopPropagation();
                        }}
                    >
                        <Select
                            showSearch
                            placeholder={t('Labels')}
                            style={{ width: '100%' }}
                            value={t('Labels')}
                        >
                            {model.labels.map(
                                (label): JSX.Element => (
                                    <Select.Option key={label}>
                                        {label}
                                    </Select.Option>
                                ),
                            )}
                        </Select>
                    </Col>
                </Col>
            </Row>
        );
    }
}
export default withRouter(withTranslation()(TrainedModelItem));
