// Copyright (C) 2020 Humanome Lab.

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    deleteTrainedModelAsync,
    cancelTrainingModelAsync,
    checkJobsStatusAsync,
    getModelAsync,
    exportModelAsync,
} from 'actions/models-actions';

import { Model, CombinedState } from 'reducers/interfaces';

import TrainedModelPageComponent from 'components/trained-model-page/trained-model-page';

type OwnProps = RouteComponentProps<{ mid: string }>;

interface StateToProps {
    model: Model | null | undefined;
    fetching: boolean;
    auth: boolean;
    isExportable: boolean;
    exporting: boolean;
    cancels: {
        [tid: number]: boolean;
    };
}

interface DispatchToProps {
    getModel(): void;
    deleteTrainedModel(id: number): void;
    cancelTrainingModel(id: number): void;
    checkJobsStatus: (tids: number[], timeout: number) => void;
    exportModel(model: Model): void;
}

export const HAS_EXPORT_MODEL_PERM_GROUP = '__export_model';
export const HAS_EXPORT_MODEL_PERM_PLANS = ['Pro', 'Business'];

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const mid = +own.match.params.mid;

    const filteredModels = state.models.models.filter((model) => model.id === mid);
    const model = filteredModels[0] || (Number.isNaN(mid) ? undefined : null);

    return {
        model,
        fetching: state.models.fetching,
        auth: state.auth.user != null,
        isExportable: state.auth.user != null &&
            state.auth.user.groups.indexOf(HAS_EXPORT_MODEL_PERM_GROUP) >= 0 &&
            HAS_EXPORT_MODEL_PERM_PLANS.indexOf(state.auth.user.profile.plan) >= 0,
        exporting: state.models.exporting,
        cancels: state.models.activities.cancels,
    };
}

function mapDispatchToProps(dispatch: any, own: OwnProps): DispatchToProps {
    const mid = +own.match.params.mid;
    return {
        getModel: (): void => {
            dispatch(getModelAsync(mid));
        },
        deleteTrainedModel(id: number): void {
            dispatch(deleteTrainedModelAsync(id));
        },
        cancelTrainingModel(id: number): void {
            dispatch(cancelTrainingModelAsync(id));
        },
        checkJobsStatus: (tids: number[], timeout: number) => {
            dispatch(checkJobsStatusAsync(tids, timeout, true));
        },
        exportModel(model: Model): void {
            dispatch(exportModelAsync(model));
        },
    };
}

function TrainedModelPageContainer(props: StateToProps & DispatchToProps): JSX.Element {
    return (
        <TrainedModelPageComponent {...props} />
    );
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrainedModelPageContainer));
