import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Table from 'antd/lib/table';
import { Model } from 'reducers/interfaces';
import getCore from 'cvat-core-wrapper';

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

const paramOrder: { [key: string]: number } = {
    step: 101,
    'learning rate': 102,
    random_horizontal_flip: 103,
    random_vertical_flip: 104,
    base_model: 201,
    schedule_1_step: 301,
    schedule_1_learning_rate: 302,
    schedule_2_step: 303,
    schedule_2_learning_rate: 304,
    decay: 401,
    epsilon: 402,
    decay_steps: 403,
    decay_factor: 404,
    momentum_optimizer_value: 501,
    first_stage_features_stride: 601,
    width_stride: 602,
    height_stride: 603,
};

interface Props extends WithTranslation {
    model: Model;
}

interface HyperParameter {
    key: number;
    parameter: string;
    value: unknown ;
}

interface State {
    hyperParameters: any;
}

class HyperParametersComponent extends React.PureComponent<Props & RouteComponentProps, State> {
    constructor(props: Props & RouteComponentProps) {
        super(props);
        this.state = {
            hyperParameters: null,
        };
    }

    public componentDidMount(): void {
        this.load();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const { model } = this.props;
            if (prevProps.model.id !== model.id) {
                this.load();
            }
        }
    }

    private async fetchHyperParameters(): Promise<void> {
        const { model } = this.props;
        try {
            const response = await core.server.request(
                `${baseURL}/tensorflow/train/models/${model.id}/hyperparameters`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            this.setState({
                hyperParameters: response,
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.debug(error);
        }
    }

    private load(): void {
        this.fetchHyperParameters();
    }

    private renderHyperParameters(): JSX.Element {
        const { history, t } = this.props;
        const { hyperParameters } = this.state;
        const dataSource: HyperParameter[] = [];
        if (hyperParameters == null) {
            return (
                <></>
            );
        }
        let number = 0;
        for (const keyValue of Object.keys(hyperParameters)) {
            if (typeof (hyperParameters[keyValue]) === 'boolean') {
                if (hyperParameters[keyValue] === true) {
                    dataSource.push(
                        {
                            key: number,
                            parameter: keyValue,
                            value: 'Use',
                        },
                    );
                } else {
                    dataSource.push(
                        {
                            key: number,
                            parameter: keyValue,
                            value: 'Not Use',
                        },
                    );
                }
            } else if (keyValue === 'lr') {
                dataSource.push(
                    {
                        key: number,
                        parameter: 'learning rate',
                        value: hyperParameters[keyValue],
                    },
                );
            } else if (keyValue === 'schedules') {
                const { schedules } = hyperParameters;
                for (let idx = 0; idx < schedules.length; idx++) {
                    const prefix = `schedule_${idx + 1}_`;
                    for (const [key, value] of Object.entries(schedules[idx])) {
                        dataSource.push(
                            {
                                key: number,
                                parameter: prefix + key,
                                value,
                            },
                        );
                        number++;
                    }
                }
            } else if (keyValue === 'base_model') {
                const { id, name } = hyperParameters[keyValue];
                let nameStr = name;
                // if model is pretrained-model, translate model name
                if (id === null || id > 2 ** 32) {
                    nameStr = t(nameStr);
                }
                dataSource.push({
                    key: number,
                    parameter: 'base_model',
                    value: (
                        <a
                            onClick={(e: React.MouseEvent): void => {
                                e.preventDefault();
                                history.push(`/models/${id}`);
                            }}
                            href={`/models/${id}`}
                        >
                            {nameStr || 'no name'}
                        </a>
                    ),
                });
            } else {
                dataSource.push(
                    {
                        key: number,
                        parameter: keyValue,
                        value: hyperParameters[keyValue],
                    },
                );
            }
            number++;
        }
        const columns = [
            {
                title: 'Parameter',
                dataIndex: 'parameter',
                key: 'parameter',
            },
            {
                title: 'Value',
                dataIndex: 'value',
                key: 'value',
            },
        ];
        dataSource.sort((a: HyperParameter, b: HyperParameter) => {
            if (a.parameter in paramOrder) {
                if (b.parameter in paramOrder) {
                    return paramOrder[a.parameter] - paramOrder[b.parameter];
                }
                return -1;
            }
            if (b.parameter in paramOrder) {
                return 1;
            }
            return a.parameter.localeCompare(b.parameter);
        });

        return (
            <Table
                className='cvat-task-jobs-table'
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                size='small'
            />
        );
    }

    public render(): JSX.Element {
        const { t } = this.props;

        return (
            <div className='cvat-training-model'>
                <Row>
                    <Col>
                        <Text className='cvat-text-color cvat-jobs-header'>
                            {t('Hyper Parameters')}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {this.renderHyperParameters()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(withTranslation()(HyperParametersComponent));
