import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';

import { Col, Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import { Tag } from 'antd';
import { Model } from 'reducers/interfaces';
import getStatusColor from 'utils/trained-model';

interface Props extends WithTranslation {
    model: Model;
    onModelUpdate: (model: Model) => void;
}

interface State {
    name: string;
}

const colors = [
    '#ff811e', '#9013fe', '#0074d9',
    '#549ca4', '#e8c720', '#3d9970',
    '#6b2034', '#2c344c', '#2ecc40',
];

let currentColor = 0;

function nextColor(): string {
    const color = colors[currentColor];
    currentColor += 1;
    if (currentColor >= colors.length) {
        currentColor = 0;
    }
    return color;
}

class DetailsComponent extends React.Component<Props & RouteComponentProps, State> {
    timer: any = null;

    constructor(props: Props & RouteComponentProps) {
        super(props);
        const { model } = props;

        this.state = {
            name: model.name,
        };
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const { model } = this.props;
            const { name } = this.state;
            if (name !== model.name) {
                this.setState({ name: model.name });
            }
            super.forceUpdate();
        }
    }

    private renderModelName(): JSX.Element {
        const { name } = this.state;
        const {
            model,
            onModelUpdate,
            t,
        } = this.props;

        if (model.id === null || model.id > 2 ** 32) {
            return (
                <Title
                    level={4}
                    className='cvat-text-color eyes-task-name'
                >
                    {t(name)}
                </Title>
            );
        }

        return (
            <Title
                level={4}
                editable={{
                    onChange: (value: string): void => {
                        this.setState({ name: value });
                        model.name = value;
                        onModelUpdate(model);
                    },
                }}
                className='cvat-text-color eyes-task-name'
            >
                {name}
            </Title>
        );
    }

    private renderLabels(): JSX.Element[] {
        const { model } = this.props;
        currentColor = 0;
        return model.labels.map((label: string): JSX.Element => (
            <Tag key={label} color={nextColor()} style={{ opacity: 0.8 }}>
                { label }
            </Tag>
        ));
    }

    private renderStatus(): JSX.Element {
        const { model, history, t } = this.props;
        if (model.id !== null && model.id > 2 ** 32) return (<></>);
        return (
            <>
                <Row align='top' justify='end'>
                    <Col className='eyes-train-status'>
                        <Tag color={getStatusColor(model.status)}>
                            {t(model.status)}
                        </Tag>
                    </Col>
                </Row>
                <Row align='middle'>
                    <Col>
                        <Paragraph className='cvat-text-color' style={{ marginTop: '0.5em', marginBottom: '0.5em' }}>
                            {t('Jump to ')}
                            <a
                                href={`/tasks/${model.task_id}`}
                                onClick={(e: React.MouseEvent): void => {
                                    e.preventDefault();
                                    history.push(`/tasks/${model.task_id}`);
                                }}
                            >
                                {t('task page')}
                            </a>
                        </Paragraph>
                    </Col>
                </Row>
            </>
        );
    }

    private renderDate(): JSX.Element {
        const { model, t } = this.props;
        if (model.id !== null && model.id > 2 ** 32) return (<></>);
        return (
            <Row justify='space-between' align='middle'>
                <Col>
                    <Text className='cvat-text-color'>
                        {t('Start time')}
                        {`: ${moment(model.uploadDate).format(t('FORMAT_CREATED'))}`}
                    </Text>
                </Col>
                <Col>
                    <Text className='cvat-text-color'>
                        {t('Last update')}
                        {`: ${moment(model.updateDate).format(t('FORMAT_CREATED'))}`}
                    </Text>
                </Col>
            </Row>
        );
    }

    public render(): JSX.Element {
        const { t } = this.props;

        return (
            <div className='cvat-training-model'>
                <Row justify='space-between' align='middle' gutter={[8, 8]}>
                    <Col span={18}>
                        {this.renderModelName()}
                        <Row justify='start' gutter={8} style={{ marginBottom: '0.5em' }}>
                            <Col>
                                <Text>
                                    {t('Labels')}
                                    :
                                </Text>
                            </Col>
                            <Col>
                                {this.renderLabels()}
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        {this.renderStatus()}
                    </Col>
                </Row>
                {this.renderDate()}
            </div>
        );
    }
}

export default withRouter(withTranslation()(DetailsComponent));
