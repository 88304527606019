import React from 'react';

import { Col, Row } from 'antd';
import Spin from 'antd/lib/spin';

import Text from 'antd/lib/typography/Text';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import notification from 'antd/lib/notification';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Model } from 'reducers/interfaces';

import ConnectedFileManager, {
    FileManagerContainer,
} from 'containers/file-manager/file-manager';

import getCore from 'cvat-core-wrapper';
import ImageItem from './image-item';

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

interface ResultFileNames {
    uploaded: string;
    detected: string;
    detected_info: { [key: string]: any[] };
}
interface DetectedObjects {
    key: number;
    name: string;
    value: unknown;
}

type Props = {
    model: Model;
} & WithTranslation;

type State = {
    images: ResultFileNames[];
    loading: boolean;
    updated: boolean;
};

const defaultState = {
    images: [],
    loading: false,
    updated: false,
};

class DetectObjectTestComponent extends React.PureComponent<Props, State> {
    private fileManagerContainer: FileManagerContainer;

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
        this.fileManagerContainer = null as any as FileManagerContainer;
    }

    public componentDidMount(): void {
        this.getImages();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const { model } = this.props;
            if (prevProps.model.id !== model.id) {
                if (this.fileManagerContainer) {
                    this.fileManagerContainer.reset();
                }
                this.getImages();
            }
        }
    }

    private async getImages(): Promise<void> {
        const { model } = this.props;
        const mid = model.id;
        const data = await core.server.request(
            `${baseURL}/tensorflow/detect/test/images/model/${mid}`, {
                method: 'GET',
            },
        );
        this.setState({ images: data.images });
        this.setState({ updated: true });
    }

    private handleSubmitClick = (): void => {
        const { model } = this.props;
        const { t } = this.props;
        const { local } = this.fileManagerContainer.getFiles();

        const files = local;
        if (files.length > 5) {
            notification.error({
                message: t('Could not upload images'),
                description: t('Please upload upto 5 images'),
            });
            return;
        }

        const others: File[] = [];

        const filteredFiles: File[] = [];
        files.reduce((acc: File[], value: File): File[] => {
            const [extension] = value.name.toLowerCase().split('.').reverse();
            if (['jpg', 'jpeg', 'png', 'heic', 'heif'].includes(extension)) {
                acc.push(value);
            } else {
                others.push(value);
            }
            return acc;
        }, filteredFiles);

        if (others.length > 0) {
            notification.error({
                message: t('Could not upload images'),
                description: t('Please, specify correct files'),
            });
            return;
        }

        const data = new FormData();
        filteredFiles.forEach((file) => data.append('file', file));

        this.setState({ loading: true });
        this.setState({ updated: false });
        const mid = model.id;
        core.server.request(
            `${baseURL}/tensorflow/detect/create/task/${mid}`, {
                method: 'POST',
                data,
                contentType: false,
                processData: false,
            },
        ).then((response: { status: number }) => {
            if (response.status === 200) {
                this.getImages();
            } else {
                this.setState({ updated: true });
                notification.error({
                    message: t('Failed to submit an image'),
                    description: t('Please, retry to upload files'),
                });
            }
            this.setState({ loading: false });
        }).catch(() => {
            this.setState({ loading: false });
            this.setState({ updated: true });
            notification.error({
                message: t('Failed to submit an image'),
                description: t('Please, check and retry to upload files'),
            });
        });
    };

    /* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderDetectedObjectsTable"] }] */
    private renderDetectedObjectsTable(image: ResultFileNames, mid: number): JSX.Element {
        const dataSource: DetectedObjects[] = [];
        if (image == null || mid == null) {
            return (
                <></>
            );
        }
        let number = 0;
        for (const keyValue of Object.keys(image.detected_info)) {
            if (typeof (image.detected_info[keyValue]) === 'number') {
                dataSource.push(
                    {
                        key: number,
                        name: keyValue,
                        value: image.detected_info[keyValue],
                    },
                );
            }
            number++;
        }
        const columns = [
            {
                title: 'Detected object',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '# of objects',
                dataIndex: 'value',
                key: 'value',
            },
        ];
        return (
            <Table
                className='cvat-task-jobs-table'
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                size='small'
            />
        );
    }

    public renderResults(): JSX.Element {
        const { model } = this.props;
        const { t } = this.props;
        const { images, loading, updated } = this.state;
        if (loading === true || updated === false) {
            return (
                <Row justify='center'>
                    <Col>
                        <Spin size='large' />
                    </Col>
                </Row>
            );
        }
        if (images.length === 0) {
            return (
                <Row justify={'start'}>
                    <Col span={11}>
                        <Alert
                            message={t('Please upload an image to test this model')}
                            description=''
                            type='info'
                            showIcon
                        />
                    </Col>
                </Row>
            );
        }
        const resultItemViews = images.map(
            (image, index): JSX.Element => (
                <div key={image.uploaded} className='cvat-training-model'>
                    <Row>
                        {`#${index}: ${image.uploaded}`}
                    </Row>
                    <Row justify='space-around'>
                        <Col span={11}>
                            <ImageItem
                                title='Uploaded image'
                                url={`${baseURL}/tensorflow/detect/uploaded_image/model/${model.id}`}
                                filename={image.uploaded}
                                index={index}
                            />
                        </Col>
                        <Col span={11}>
                            <ImageItem
                                title='Detected image'
                                url={`${baseURL}/tensorflow/detect/detected_image/model/${model.id}`}
                                filename={image.detected}
                                index={index}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {t('Detected Objects')}
                    </Row>
                    <Row>
                        <Col span={24}>
                            {this.renderDetectedObjectsTable(image, index)}
                        </Col>
                    </Row>
                </div>
            ),
        );
        return (
            <div>
                {resultItemViews}
            </div>
        );
    }

    private renderInputField(): JSX.Element {
        const { t } = this.props;
        const { loading } = this.state;
        return (
            <>
                <Row align='top'>
                    <Col span={24}>
                        <ConnectedFileManager
                            ref={
                                (container: FileManagerContainer): void => {
                                    this.fileManagerContainer = container;
                                }
                            }
                            withRemote={false}
                            directoryUploadable={false}
                            hintText={t('Support for jpg and png images Please upload upto 5 images')}
                        />
                    </Col>
                </Row>
                <Row justify='end' style={{ paddingTop: 6 }}>
                    <Col>
                        <Button
                            type='primary'
                            disabled={loading}
                            loading={loading}
                            onClick={this.handleSubmitClick}
                        >
                            {t('Submit')}
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }

    public render(): JSX.Element {
        const { t } = this.props;
        return (
            <div className='cvat-training-model'>
                <Row>
                    <Col>
                        <Text className='cvat-text-color cvat-jobs-header'>
                            {t('Detect Object Test')}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {this.renderInputField()}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {this.renderResults()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(DetectObjectTestComponent);
