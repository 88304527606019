// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Text from 'antd/lib/typography/Text';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ExportOutlined, LoadingOutlined } from '@ant-design/icons';

interface Props {
    menuKey: string;
    exporters: string[];
    exportActivities: string[] | null;
    jobsLength: number;
}

export default function ExportSubmenu(props: Props): ItemType[] {
    const {
        menuKey,
        exporters,
        exportActivities,
        jobsLength,
    } = props;

    const { t } = useTranslation();

    const menus: ItemType[] = [
        {
            key: menuKey,
            label: t('Export as a dataset'),
            disabled: jobsLength === 0,
            children: exporters.map((exporter: string) => {
                const pending = (exportActivities || []).includes(exporter);
                return {
                    key: `${exporter}::${menuKey}`,
                    disabled: pending,
                    className: 'cvat-menu-load-submenu-item',
                    icon: <ExportOutlined rev='' />,
                    label: (
                        <>
                            <Text>{exporter}</Text>
                            {pending && <LoadingOutlined style={{ marginLeft: 10 }} rev='' />}
                        </>
                    ),
                };
            }),
        },
    ];
    return menus;
}
