// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Form, { FormInstance } from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Select from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    UserAddOutlined,
    MailOutlined,
    LockOutlined,
    TeamOutlined,
    EnvironmentOutlined,
    BulbOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col } from 'antd/lib/grid';
import { Tooltip } from 'antd';
import store from 'store';
import { changeLanguage } from 'i18n';
import { passwordValidator, oldPasswordValidator, usernameValidator } from 'utils/validation';

// import passwordReset from 'components/password-reset';

import { UserAgreement, Language, ProfileData } from 'reducers/interfaces';

type UpdateProfileFormProps = {
    fetching: boolean;
    userAgreements: UserAgreement[];
    onSubmit(ProfileData: ProfileData): void;
    onEmailSubmit(oldEmail: string, newEmail: string): void;
    // onDeactivateAccount(): void;
    initialData: ProfileData;
    passwordRequired: boolean;
} & WithTranslation;

const isEmpty = (value: string): boolean => (!value || value.length === 0);
const isNotEmpty = (value: string): boolean => (!!value && value.length > 0);

class UpdateProfileFormComponent
    extends React.PureComponent<UpdateProfileFormProps> {
    private formRef: React.RefObject<FormInstance>;

    public constructor(props: UpdateProfileFormProps) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
    }

    private getLanguage(): string {
        const { i18n } = this.props;
        return i18n.language ? i18n.language : Language.English;
    }

    private validateOldPassword = (_: any, value: any): Promise<any> => {
        const { passwordRequired } = this.props;
        const { t } = this.props;
        if (isEmpty(value) && isNotEmpty(this.formRef.current?.getFieldValue('password1'))) {
            return Promise.reject(new Error(t('Please input your old password!')));
        }

        if (passwordRequired || isNotEmpty(value)) {
            const msg = oldPasswordValidator(value);
            if (msg) {
                return Promise.reject(new Error(t(msg)));
            }
        }
        return Promise.resolve();
    };

    private validatePassword = (_: any, value: any): Promise<any> => {
        const { passwordRequired } = this.props;
        const { t } = this.props;
        if (passwordRequired || isNotEmpty(value)) {
            const msg = passwordValidator(value);
            if (msg) {
                return Promise.reject(new Error(t(msg)));
            }
        }
        return Promise.resolve();
    };

    private validateConfirmation = (rule: any, value: any): Promise<any> => {
        const { passwordRequired } = this.props;
        const { t } = this.props;
        const password1 = this.formRef.current?.getFieldValue('password1');
        if (!passwordRequired && !value && password1) {
            return Promise.reject(new Error(t('Please confirm your password!')));
        }
        if (isNotEmpty(value) && value !== password1) {
            return Promise.reject(new Error(t('Two passwords that you enter is inconsistent!')));
        } else {
            return Promise.resolve();
        }
    };

    private validateUsername = (_: any, value: any): Promise<any> => {
        const { t } = this.props;
        const msg = usernameValidator(value);
        if (msg) {
            return Promise.reject(new Error(t(msg)));
        }

        return Promise.resolve();
    };

    private validateAgrement = (agreement: any, value: any): Promise<any> => {
        const { userAgreements } = this.props;
        for (const userAgreement of userAgreements) {
            if (agreement.field === userAgreement.name &&
                userAgreement.required && !value) {
                return Promise.reject(new Error(`You must accept the ${userAgreement.displayText} to continue!`));
            }
        }
        return Promise.resolve();
    };

    private handleEmailSubmit = (values: any): void => {
        const {
            onEmailSubmit,
        } = this.props;

        const currentEmail = store.get('email');
        if (values.email !== currentEmail) {
            onEmailSubmit(currentEmail, values.email);
        }
    };

    private handleSubmit = (values: any): void => {
        const {
            onSubmit,
        } = this.props;

        onSubmit(values);
    };

    private renderFirstNameField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='firstName'
                initialValue={store.get('firstName') ? store.get('firstName') : initialData.firstName}
                rules={[{
                    required: true,
                    message: t('Please specify a first name'),
                    // pattern: patterns.validateName.pattern,
                }]}
                hasFeedback
            >
                <Input
                    prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('First name')}
                />
            </Form.Item>
        );
    }

    private renderLastNameField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='lastName'
                initialValue={store.get('lastName') ? store.get('lastName') : initialData.lastName}
                rules={[{
                    required: true,
                    message: t('Please specify a last name'),
                    // pattern: patterns.validateName.pattern,
                }]}
                hasFeedback
            >
                <Input
                    prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Last name')}
                />
            </Form.Item>
        );
    }

    private renderUsernameField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='userName'
                initialValue={store.get('userName') ? store.get('userName') : initialData.userName}
                rules={[{
                    required: true,
                    message: t('Please specify a user name'),
                }, {
                    validator: this.validateUsername,
                }]}
                hasFeedback
            >
                <Input
                    prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Username')}
                />
            </Form.Item>
        );
    }

    private renderEmailField(): JSX.Element {
        const { t } = this.props;

        return (
            <Form.Item
                name='email'
                initialValue={store.get('email')}
                rules={[{
                    type: 'email',
                    message: t('The input is not valid E-mail!'),
                }, {
                    required: true,
                    message: t('Please specify an email address'),
                }]}
                hasFeedback
            >
                <Input
                    autoComplete='email'
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Email address')}
                    type='email'
                />
            </Form.Item>
        );
    }

    private renderOldPasswordField(): JSX.Element {
        const { passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='oldPassword'
                dependencies={['password1']}
                rules={[{
                    required: passwordRequired,
                    // message: t('Please input your old password!'),
                }, {
                    validator: this.validateOldPassword,
                }]}
                hasFeedback
            >
                <Input.Password
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Current password (if you want to change)')}
                />
            </Form.Item>
        );
    }

    private renderPasswordField(): JSX.Element {
        const { passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='password1'
                rules={[{
                    required: passwordRequired,
                    // message: t('Please input your password!'),
                }, {
                    validator: this.validatePassword,
                }]}
                hasFeedback
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={passwordRequired ? t('Password') : t('New password')}
                />
            </Form.Item>
        );
    }

    private renderPasswordConfirmationField(): JSX.Element {
        const { passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='password2'
                dependencies={['password1']}
                rules={[{
                    required: passwordRequired,
                    // message: t('Please confirm your password!'),
                }, {
                    validator: this.validateConfirmation,
                }]}
                hasFeedback
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={passwordRequired ? t('Confirm password') : t('Confirm new password')}
                />
            </Form.Item>
        );
    }

    private renderOrganizationField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='organization'
                initialValue={store.get('organization') ? store.get('organization') : initialData.organization}
                rules={[{
                    required: true,
                    message: t('Please specify your organization'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<TeamOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Organization')}
                />
            </Form.Item>
        );
    }

    private renderAddressField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='address'
                initialValue={store.get('address') ? store.get('address') : initialData.address}
                rules={[{
                    required: true,
                    message: t('Please specify your address'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Address')}
                />
            </Form.Item>
        );
    }

    // private renderPhoneNumberField(): JSX.Element {
    //     const { form, initialData } = this.props;

    //     return (
    //         <Form.Item hasFeedback>
    //             {form.getFieldDecorator('phoneNumber', {
    //                 initialValue: initialData.phoneNumber,
    //                 rules: [{
    //                     required: true,
    //                     message: 'Please specify your phone number (e.g. +123456789012)',
    //                     pattern: patterns.validatePhone.pattern,
    //                 }],
    //             })(
    //                 <Input
    //                     prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
    //                     placeholder='Phone number'
    //                 />,
    //             )}
    //         </Form.Item>
    //     );
    // }

    private renderPurposeField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='purpose'
                initialValue={initialData.purpose}
                rules={[{
                    required: true,
                    message: t('Please describe your purpose of use'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<BulbOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Purpose of use')}
                    suffix={(
                        <Tooltip title={t('EXAMPLES_OF_PURPOSE')} placement='topRight'>
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} rev='' />
                        </Tooltip>
                    )}
                />
            </Form.Item>
        );
    }

    private renderLangField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        let lang = store.get('lang');
        if (!lang) {
            if (initialData.lang) {
                lang = initialData.lang;
            } else {
                lang = Language.English;
            }
        }

        return (
            <Row>
                <Col span={24}>
                    <Text className='cvat-text-color'>{t('Language')}</Text>
                    <Form.Item
                        name='lang'
                        initialValue={lang}
                        rules={[{
                            required: true,
                            message: t('Please specify a user name'),
                        }, {
                            validator: this.validateUsername,
                        }]}
                    >
                        <Select
                            onChange={(newLang: Language): void => {
                                changeLanguage(newLang);
                            }}
                        >
                            <Select.Option key='english' value={Language.English}>English</Select.Option>
                            <Select.Option key='japanese' value={Language.Japanese}>Japanese (日本語)</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    private renderUserAgreements(): JSX.Element[] {
        const { userAgreements } = this.props;
        const { t } = this.props;
        const getUserAgreementsElements = (): JSX.Element[] => {
            const agreementsList: JSX.Element[] = [];
            for (const userAgreement of userAgreements) {
                agreementsList.push(
                    <Form.Item
                        name={userAgreement.name}
                        key={userAgreement.name}
                        initialValue={false}
                        rules={[{
                            required: true,
                            message: t('You must accept to continue!'),
                        }, {
                            validator: this.validateAgrement,
                        }]}
                    >
                        <Checkbox>
                            {t('I read and accept the')}
                            {' '}
                            <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href={userAgreement.url}
                            >
                                { userAgreement.displayText }
                            </a>
                        </Checkbox>
                    </Form.Item>,
                );
            }
            return agreementsList;
        };

        return getUserAgreementsElements();
    }

    private renderNameFields(): JSX.Element {
        if (this.getLanguage() === Language.Japanese) {
            return (
                <Row gutter={8}>
                    <Col span={12}>
                        {this.renderLastNameField()}
                    </Col>
                    <Col span={12}>
                        {this.renderFirstNameField()}
                    </Col>
                </Row>
            );
        }
        return (
            <Row gutter={8}>
                <Col span={12}>
                    {this.renderFirstNameField()}
                </Col>
                <Col span={12}>
                    {this.renderLastNameField()}
                </Col>
            </Row>
        );
    }

    public render(): JSX.Element {
        const { fetching, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <>
                <Row>
                    <Col span={24}>
                        <Form onFinish={this.handleEmailSubmit} className='login-form'>
                            <Row gutter={8}>
                                <Col span={16}>
                                    {this.renderEmailField()}
                                </Col>
                                <Col span={8}>
                                    <Form.Item>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            className='register-form-button'
                                            loading={fetching}
                                            disabled={fetching}
                                        >
                                            {t('Update Email')}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form ref={this.formRef} onFinish={this.handleSubmit} className='login-form'>
                            {this.renderNameFields()}
                            {this.renderUsernameField()}
                            {this.renderOrganizationField()}
                            {this.renderAddressField()}
                            {/* {this.renderPhoneNumberField()} */}
                            { !passwordRequired && this.renderOldPasswordField() }
                            {this.renderPasswordField()}
                            {this.renderPasswordConfirmationField()}
                            {this.renderPurposeField()}
                            {this.renderLangField()}
                            <Row justify='end'>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        className='register-form-button'
                                        loading={fetching}
                                        disabled={fetching}
                                    >
                                        {t('Update')}
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withTranslation()(UpdateProfileFormComponent);
