// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { AnyAction } from 'redux';
import { BoundariesActionTypes } from 'actions/boundaries-actions';
import { ModelsActionTypes } from 'actions/models-actions';
import { TasksActionTypes } from 'actions/tasks-actions';
import { AuthActionTypes } from 'actions/auth-actions';
import { TrainingActionTypes } from 'actions/training-model-actions';
import { ModelsState, Model } from './interfaces';

const defaultState: ModelsState = {
    initialized: false,
    fetching: false,
    creatingStatus: '',
    models: [],
    visibleRunWindows: false,
    activeRunTask: null,
    gettingQuery: {
        owner: null,
        page: 1,
        taskId: null,
    },
    total_counts: 0,
    inferences: {},
    statusInitialized: false,
    checkingStatus: false,
    activities: {
        deletes: {},
        cancels: {},
        trainings: {},
    },
    exporting: false,
};

export default function (
    state = defaultState,
    action: AnyAction,
): ModelsState {
    switch (action.type) {
        case ModelsActionTypes.GET_MODELS: {
            return {
                ...state,
                initialized: false,
                fetching: true,
                activities: {
                    ...state.activities,
                    deletes: {},
                    cancels: {},
                },
            };
        }
        case ModelsActionTypes.GET_MODELS_SUCCESS: {
            const { query } = action.payload;
            const gettingQuery = (query === null) ? defaultState.gettingQuery : query;
            return {
                ...state,
                initialized: true,
                fetching: false,
                models: action.payload.models,
                total_counts: action.payload.totalCounts,
                gettingQuery,
            };
        }
        case ModelsActionTypes.GET_MODELS_FAILED: {
            return {
                ...state,
                initialized: true,
                fetching: false,
            };
        }
        case ModelsActionTypes.DELETE_TRAINED_MODEL: {
            const { id } = action.payload;
            const { deletes } = state.activities;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    deletes: {
                        ...deletes,
                        [id]: false,
                    },
                },
            };
        }
        case ModelsActionTypes.DELETE_TRAINED_MODEL_SUCCESS: {
            const { id } = action.payload;
            const { deletes } = state.activities;
            const { models } = state;

            return {
                ...state,
                models: models.filter((model) => model.id !== id),
                activities: {
                    ...state.activities,
                    deletes: {
                        ...deletes,
                        [id]: true,
                    },
                },
            };
        }
        case ModelsActionTypes.DELETE_TRAINED_MODEL_FAILED: {
            const { id } = action.payload;
            const { [id]: deleted, ...deletes } = state.activities.deletes;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    deletes: {
                        ...deletes,
                    },
                },
            };
        }
        case ModelsActionTypes.CANCEL_TRAINING_MODEL: {
            const { id } = action.payload;
            const { cancels } = state.activities;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    cancels: {
                        ...cancels,
                        [id]: false,
                    },
                },
            };
        }
        case ModelsActionTypes.CANCEL_TRAINING_MODEL_SUCCESS: {
            const { id } = action.payload;
            const { cancels } = state.activities;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    cancels: {
                        ...cancels,
                        [id]: true,
                    },
                },
            };
        }
        case TrainingActionTypes.TRAINING_START:
        case ModelsActionTypes.CANCEL_TRAINING_MODEL_FAILED: {
            const { id } = action.payload;
            const { [id]: deleted, ...cancels } = state.activities.cancels;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    cancels: {
                        ...cancels,
                    },
                },
            };
        }
        case ModelsActionTypes.DELETE_MODEL_SUCCESS: {
            return {
                ...state,
                models: state.models.filter(
                    (model): boolean => model.id !== action.payload.id,
                ),
            };
        }
        case ModelsActionTypes.CREATE_MODEL: {
            return {
                ...state,
                creatingStatus: '',
            };
        }
        case ModelsActionTypes.CREATE_MODEL_STATUS_UPDATED: {
            return {
                ...state,
                creatingStatus: action.payload.status,
            };
        }
        case ModelsActionTypes.GET_MODEL: {
            return {
                ...state,
            };
        }
        case ModelsActionTypes.GET_MODEL_SUCCESS: {
            const { models } = state;
            const currentModel = action.payload.model;
            let newModels = null;
            if (models.find((m: Model) => m.id === currentModel.id)) {
                newModels = models.map((model) => {
                    if (model.id === currentModel.id) {
                        return currentModel;
                    }
                    return model;
                });
            } else {
                newModels = [currentModel, ...models];
            }
            return {
                ...state,
                models: newModels,
            };
        }
        case ModelsActionTypes.UPDATE_MODEL: {
            return {
                ...state,
            };
        }
        case ModelsActionTypes.UPDATE_MODEL_SUCCESS: {
            return {
                ...state,
                models: state.models.map((model) => {
                    if (model.id === action.payload.model.id) {
                        return {
                            ...model,
                        };
                    }
                    return model;
                }),
            };
        }
        case ModelsActionTypes.UPDATE_MODEL_FAILED: {
            return {
                ...state,
                models: state.models.map((model) => {
                    if (model.id === action.payload.model.id) {
                        return {
                            ...model,
                        };
                    }
                    return model;
                }),
            };
        }
        case ModelsActionTypes.CREATE_MODEL_FAILED: {
            return {
                ...state,
                creatingStatus: '',
            };
        }
        case ModelsActionTypes.CREATE_MODEL_SUCCESS: {
            return {
                ...state,
                initialized: false,
                creatingStatus: 'CREATED',
            };
        }
        case ModelsActionTypes.SHOW_RUN_MODEL_DIALOG: {
            return {
                ...state,
                visibleRunWindows: true,
                activeRunTask: action.payload.taskInstance,
            };
        }
        case ModelsActionTypes.CLOSE_RUN_MODEL_DIALOG: {
            return {
                ...state,
                visibleRunWindows: false,
                activeRunTask: null,
            };
        }
        case ModelsActionTypes.RESERVE_CHECK_JOBS_STATUS: {
            return {
                ...state,
                checkingStatus: true,
            };
        }
        case ModelsActionTypes.CHECK_JOBS_STATUS_SUCCESS: {
            return {
                ...state,
                statusInitialized: true,
                checkingStatus: false,
                activities: {
                    ...state.activities,
                    trainings: action.payload.trainings,
                },
            };
        }
        case ModelsActionTypes.CHECK_JOBS_STATUS_FAILED: {
            return {
                ...state,
                checkingStatus: false,
            };
        }
        case ModelsActionTypes.GET_INFERENCE_STATUS_SUCCESS: {
            const {
                activeInference,
                taskID,
            } = action.payload;
            const { [taskID]: deleted, ...inferences } = state.inferences;
            if (activeInference.status !== 'finished') {
                inferences[taskID] = activeInference;
            }

            return {
                ...state,
                inferences: { ...inferences },
            };
        }
        case ModelsActionTypes.GET_INFERENCE_STATUS_FAILED: {
            const { [action.payload.task.id]: deleted, ...inferences } = state.inferences;

            return {
                ...state,
                inferences: { ...inferences },
            };
        }
        case ModelsActionTypes.CANCEL_INFERENCE_SUCCESS: {
            const { [action.payload.taskID]: deleted, ...inferences } = state.inferences;

            return {
                ...state,
                inferences: { ...inferences },
            };
        }
        case TasksActionTypes.DELETE_TASK_SUCCESS: {
            const { taskID } = action.payload;
            const { models } = state;

            return {
                ...state,
                models: models.filter((model) => model.task_id !== taskID),
            };
        }
        case ModelsActionTypes.EXPORT_MODEL: {
            return {
                ...state,
                exporting: true,
            };
        }
        case ModelsActionTypes.EXPORT_MODEL_SUCCESS: {
            return {
                ...state,
                models: state.models.map((model) => {
                    if (model.id === action.payload.model.id) {
                        return {
                            ...model,
                        };
                    }
                    return model;
                }),
                exporting: false,
            };
        }
        case ModelsActionTypes.EXPORT_MODEL_FAILED: {
            return {
                ...state,
                models: state.models.map((model) => {
                    if (model.id === action.payload.model.id) {
                        return {
                            ...model,
                        };
                    }
                    return model;
                }),
                exporting: false,
            };
        }
        case BoundariesActionTypes.RESET_AFTER_ERROR:
        case AuthActionTypes.LOGOUT_SUCCESS: {
            return { ...defaultState };
        }
        default: {
            return state;
        }
    }
}
