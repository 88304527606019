// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form, { FormInstance } from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input, { InputRef } from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Select from 'antd/lib/select';
import { Row, Col } from 'antd/lib/grid';
import { Tooltip } from 'antd';
// import Text from 'antd/lib/typography/Text';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    UserAddOutlined,
    MailOutlined,
    LockOutlined,
    TeamOutlined,
    EnvironmentOutlined,
    BulbOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import { changeLanguage } from 'i18n';
import { passwordValidator, usernameValidator } from 'utils/validation';

// import passwordReset from 'components/password-reset';

import { UserAgreement, Language } from 'reducers/interfaces';

export interface UserConfirmation {
    name: string;
    value: boolean;
}

export interface RegisterData {
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    organization: string;
    address: string;
    // phoneNumber: string;
    lang: string;
    purpose: string;
    oldPassword: string;
    password1: string;
    password2: string;
    confirmations: UserConfirmation[];
}

type RegisterFormProps = {
    fetching: boolean;
    userAgreements: UserAgreement[];
    onSubmit(registerData: RegisterData): void;
    initialData: RegisterData;
    passwordRequired: boolean;
} & WithTranslation;

class RegisterFormComponent extends React.PureComponent<RegisterFormProps> {
    private formRef: React.RefObject<FormInstance>;
    private emailInput: React.RefObject<InputRef>;

    constructor(props: RegisterFormProps) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
        this.emailInput = React.createRef<InputRef>();
    }

    public componentDidMount(): void {
        this.emailInput.current?.focus();
    }

    private getLanguage(): string {
        const { i18n } = this.props;
        return i18n.language ? i18n.language : Language.English;
    }

    private validateConfirmation = (_: any, value: any): Promise<any> => {
        const { passwordRequired } = this.props;
        const { t } = this.props;
        if (!passwordRequired && !value && this.formRef.current?.getFieldValue('password1')) {
            return Promise.reject(t('Please confirm your password!'));
        }
        if (value && value !== this.formRef.current?.getFieldValue('password1')) {
            return Promise.reject(t('Two passwords that you enter is inconsistent!'));
        }
        return Promise.resolve();
    };

    private validatePassword = (_: any, value: any): Promise<any> => {
        const { passwordRequired } = this.props;
        const { t } = this.props;
        if (passwordRequired || value) {
            const msg = passwordValidator(value);
            if (msg) {
                return Promise.reject(t(msg));
            }
        }
        return Promise.resolve();
    };

    private validateUsername = (_: any, value: any): Promise<any> => {
        const { t } = this.props;
        const msg = usernameValidator(value);
        if (msg) {
            return Promise.reject(t(msg));
        }

        return Promise.resolve();
    };

    private validateAgrement = (agreement: any, value: any): Promise<any> => {
        const { userAgreements } = this.props;
        const { t } = this.props;
        for (const userAgreement of userAgreements) {
            if (agreement.field === userAgreement.name &&
                userAgreement.required && !value) {
                return Promise.reject(t('You must accept the {{userAgreement_text}} to continue!', { userAgreement_text: t(userAgreement.displayText) }));
            }
        }
        return Promise.resolve();
    };

    private handleSubmit = (values: any): void => {
        const {
            onSubmit,
            userAgreements,
        } = this.props;

        values.confirmations = [];

        for (const userAgreement of userAgreements) {
            values.confirmations.push({
                name: userAgreement.name,
                value: values[userAgreement.name],
            });
            // eslint-disable-next-line no-param-reassign
            delete values[userAgreement.name];
        }

        onSubmit(values);
    };

    private renderFirstNameField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='firstName'
                initialValue={initialData.firstName}
                rules={[{
                    required: true,
                    message: t('Please specify a first name'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('First name')}
                />
            </Form.Item>
        );
    }

    private renderLastNameField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='lastName'
                initialValue={initialData.lastName}
                rules={[{
                    required: true,
                    message: t('Please specify a last name'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Last name')}
                />
            </Form.Item>
        );
    }

    private renderUsernameField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='userName'
                initialValue={initialData.userName}
                rules={[{
                    required: true,
                    message: t('Please specify a user name'),
                }, {
                    validator: this.validateUsername,
                }]}
                hasFeedback
            >
                <Input
                    prefix={<UserAddOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Username')}
                />
            </Form.Item>
        );
    }

    private renderEmailField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='email'
                initialValue={initialData.email}
                rules={[{
                    type: 'email',
                    message: t('The input is not valid E-mail!'),
                }, {
                    required: true,
                    message: t('Please specify an email address'),
                }]}
                hasFeedback
            >
                <Input
                    autoComplete='email'
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Email address')}
                    type='email'
                    ref={this.emailInput}
                />
            </Form.Item>
        );
    }

    private renderOldPasswordField(): JSX.Element {
        const { passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='oldPassword'
                rules={[{
                    required: passwordRequired,
                    message: t('Please input your old password!'),
                }, {
                    validator: this.validatePassword,
                }]}
                hasFeedback
            >
                <Input.Password
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Current password (if you want to change)')}
                />
            </Form.Item>
        );
    }

    private renderPasswordField(): JSX.Element {
        const { passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='password1'
                rules={[{
                    required: passwordRequired,
                    message: t('Please input your password!'),
                }, {
                    validator: this.validatePassword,
                }]}
                hasFeedback
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={passwordRequired ? t('Password') : t('New password')}
                />
            </Form.Item>
        );
    }

    private renderOrganizationField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='organization'
                initialValue={initialData.organization}
                rules={[{
                    required: true,
                    message: t('Please specify your organization'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<TeamOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Organization')}
                />
            </Form.Item>
        );
    }

    private renderAddressField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='address'
                initialValue={initialData.address}
                rules={[{
                    required: true,
                    message: t('Please specify your address'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<EnvironmentOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Address')}
                />
            </Form.Item>
        );
    }

    // private renderPhoneNumberField(): JSX.Element {
    //     const { form, initialData } = this.props;

    //     return (
    //         <Form.Item hasFeedback>
    //             {form.getFieldDecorator('phoneNumber', {
    //                 initialValue: initialData.phoneNumber,
    //                 rules: [{
    //                     required: true,
    //                     message: 'Please specify your phone number (e.g. +123456789012)',
    //                     pattern: patterns.validatePhone.pattern,
    //                 }],
    //             })(
    //                 <Input
    //                     prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
    //                     placeholder='Phone number'
    //                 />,
    //             )}
    //         </Form.Item>
    //     );
    // }

    private renderPurposeField(): JSX.Element {
        const { initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='purpose'
                initialValue={initialData.purpose}
                rules={[{
                    required: true,
                    message: t('Please describe your purpose of use'),
                }]}
                hasFeedback
            >
                <Input
                    prefix={<BulbOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Purpose of use')}
                    suffix={(
                        <Tooltip title={t('EXAMPLES_OF_PURPOSE')} placement='topRight'>
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} rev='' />
                        </Tooltip>
                    )}
                />
            </Form.Item>
        );
    }

    private renderLangField(): JSX.Element {
        return (
            <Row style={{ marginTop: '20px', marginBottom: '10px' }}>
                <Col span={24}>
                    <Form.Item
                        name='lang'
                        initialValue={this.getLanguage()}
                    >
                        <Select
                            onChange={(lang: Language): void => {
                                changeLanguage(lang);
                            }}
                        >
                            <Select.Option key='english' value={Language.English}>English</Select.Option>
                            <Select.Option key='japanese' value={Language.Japanese}>Japanese (日本語)</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    private renderPasswordConfirmationField(): JSX.Element {
        const { passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item
                name='password2'
                rules={[
                    {
                        required: passwordRequired,
                        message: t('Please input your password!'),
                    }, {
                        validator: this.validateConfirmation,
                    }]}
                dependencies={['password1']}
                hasFeedback
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={passwordRequired ? t('Confirm password') : t('Confirm new password')}
                />
            </Form.Item>
        );
    }

    private renderUserAgreements(): JSX.Element[] {
        const { userAgreements } = this.props;
        const { t } = this.props;
        const getUserAgreementsElements = (): JSX.Element[] => {
            const agreementsList: JSX.Element[] = [];
            const readAndAccept = `${t('I read and accept the')} `;
            for (const userAgreement of userAgreements) {
                agreementsList.push(
                    <Form.Item
                        name={userAgreement.name}
                        initialValue={false}
                        valuePropName='checked'
                        rules={[{
                            required: true,
                            message: t('You must accept to continue!'),
                        }, {
                            validator: this.validateAgrement,
                        }]}
                        key={userAgreement.name}
                    >
                        <Checkbox className='eyes-text-line-height-1-5'>
                            {readAndAccept}
                            <a
                                rel='noopener noreferrer'
                                target='_blank'
                                href={userAgreement.url}
                                className='eyes-display-text-nowrap'
                            >
                                {t(userAgreement.displayText)}
                            </a>
                        </Checkbox>
                    </Form.Item>,
                );
            }
            return agreementsList;
        };

        return getUserAgreementsElements();
    }

    public renderNameFields(): JSX.Element {
        if (this.getLanguage() === Language.Japanese) {
            return (
                <Row gutter={8}>
                    <Col span={12}>
                        {this.renderLastNameField()}
                    </Col>
                    <Col span={12}>
                        {this.renderFirstNameField()}
                    </Col>
                </Row>
            );
        }
        return (
            <Row gutter={8}>
                <Col span={12}>
                    {this.renderFirstNameField()}
                </Col>
                <Col span={12}>
                    {this.renderLastNameField()}
                </Col>
            </Row>
        );
    }

    public render(): JSX.Element {
        const { fetching, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form ref={this.formRef} onFinish={this.handleSubmit} className='login-form'>
                {this.renderLangField()}
                {this.renderEmailField()}
                {this.renderNameFields()}
                {this.renderUsernameField()}
                {this.renderOrganizationField()}
                {this.renderAddressField()}
                {/* {this.renderPhoneNumberField()} */}
                { !passwordRequired && this.renderOldPasswordField() }
                {this.renderPasswordField()}
                {this.renderPasswordConfirmationField()}
                {this.renderPurposeField()}
                {this.renderUserAgreements()}

                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='register-form-button'
                        loading={fetching}
                        disabled={fetching}
                    >
                        {t('Submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(RegisterFormComponent);
