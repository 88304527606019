// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Text from 'antd/lib/typography/Text';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

function isDefaultFormat(dumperName: string, taskMode: string): boolean {
    return (dumperName === 'CVAT for video 1.1' && taskMode === 'interpolation') ||
        (dumperName === 'CVAT for images 1.1' && taskMode === 'annotation');
}

interface Props {
    taskMode: string;
    menuKey: string;
    dumpers: string[];
    dumpActivities: string[] | null;
    jobsLength: number;
}

export default function DumpSubmenu(props: Props): ItemType[] {
    const {
        taskMode,
        menuKey,
        dumpers,
        dumpActivities,
        jobsLength,
    } = props;

    const { t } = useTranslation();

    const menus: ItemType[] = [
        {
            key: menuKey,
            label: t('Dump annotations'),
            disabled: jobsLength === 0,
            children: dumpers.map((dumper: string) => {
                const pending = (dumpActivities || []).includes(dumper);
                const isDefault = isDefaultFormat(dumper, taskMode);
                return {
                    key: `${dumper}::${menuKey}`,
                    disabled: pending,
                    className: 'cvat-menu-dump-submenu-item',
                    icon: <DownloadOutlined rev='' />,
                    label: (
                        <>
                            <Text strong={isDefault}>{dumper}</Text>
                            {pending && <LoadingOutlined style={{ marginLeft: 10 }} rev='' />}
                        </>
                    ),
                };
            }),
        },
    ];
    return menus;
}
