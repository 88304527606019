// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import store from 'store';
import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import { UserConfirmation } from 'components/register-page/register-form';
import getCore from 'cvat-core-wrapper';

import { browserHistory } from '../utils/history';
import {
    // twoFactorApi,
    passwordResetApi,
    newPasswordApi,
    verifyApi,
    updateDjangoEmailApi,
    updateCognitoEmailApi,
    revertCognitoEmailApi,
    updateAttributesApi,
    loginApi,
    registerApi,
    logoutApi,
    deactivateUserApi,
} from '../apis/auth';

const cvat = getCore();

export enum AuthActionTypes {
    AUTHORIZED_SUCCESS = 'AUTHORIZED_SUCCESS',
    AUTHORIZED_FAILED = 'AUTHORIZED_FAILED',
    LOGIN = 'LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    REGISTER = 'REGISTER',
    REGISTER_SUCCESS = 'REGISTER_SUCCESS',
    REGISTER_FAILED = 'REGISTER_FAILED',
    FETCH = 'FETCH',
    TWO_FACTOR = 'TWO_FACTOR',
    TWO_FACTOR_FAILED = 'TWO_FACTOR_FAILED',
    RESEND_SUCCESS = 'RESEND_SUCCESS',
    RESEND_FAILED = 'RESEND_FAILED',
    PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS',
    PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED',
    NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS',
    UPDATE_EMAIL = 'UPDATE_EMAIL',
    UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS',
    UPDATE_EMAIL_FAILED = 'UPDATE_EMAIL_FAILED',
    REVERT_EMAIL_SUCCESS = 'REVERT_EMAIL_SUCCESS',
    REVERT_EMAIL_FAILED = 'REVERT_EMAIL_FAILED',
    SHOW_VERIFICATION_MODAL = 'SHOW_VERIFICATION_MODAL',
    HIDE_VERIFICATION_MODAL = 'HIDE_VERIFICATION_MODAL',
    VERIFY_SUCCESS = 'VERIFY_SUCCESS',
    VERIFY_FAILED = 'VERIFY_FAILED',
    UPDATE_SUCCESS = 'UPDATE_SUCCESS',
    UPDATE_FAILED = 'UPDATE_FAILED',
    LOGOUT = 'LOGOUT',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    LOGOUT_FAILED = 'LOGOUT_FAILED',
    DEACTIVATE = 'DEACTIVATE',
    DEACTIVATE_SUCCESS = 'DEACTIVATE_SUCCESS',
    DEACTIVATE_FAILED = 'DEACTIVATE_FAILED',
}

export const authActions = {
    authorizeSuccess: (user: any) => createAction(AuthActionTypes.AUTHORIZED_SUCCESS, { user }),
    authorizeFailed: (error: any) => createAction(AuthActionTypes.AUTHORIZED_FAILED, { error }),
    login: () => createAction(AuthActionTypes.LOGIN),
    loginSuccess: (user: any) => createAction(AuthActionTypes.LOGIN_SUCCESS, { user }),
    loginFailed: (error: any) => createAction(AuthActionTypes.LOGIN_FAILED, { error }),
    register: () => createAction(AuthActionTypes.REGISTER),
    registerSuccess: () => createAction(AuthActionTypes.REGISTER_SUCCESS),
    registerFailed: (error: any) => createAction(AuthActionTypes.REGISTER_FAILED, { error }),
    fetch: () => createAction(AuthActionTypes.FETCH),
    twoFactorFailed: (error: any) => createAction(AuthActionTypes.TWO_FACTOR_FAILED, { error }),
    resendSuccess: (type: string, destination: string) => createAction(
        AuthActionTypes.RESEND_SUCCESS, { type, destination },
    ),
    resendFailed: (type: string, error: any) => createAction(AuthActionTypes.RESEND_FAILED, { error, type }),
    passwordResetSuccess: () => createAction(AuthActionTypes.PASSWORD_RESET_SUCCESS),
    passwordResetFailed: (error: any) => createAction(AuthActionTypes.PASSWORD_RESET_FAILED, { error }),
    newPasswordSuccess: () => createAction(AuthActionTypes.NEW_PASSWORD_SUCCESS),
    updateEmail: () => createAction(AuthActionTypes.UPDATE_EMAIL),
    updateEmailSuccess: () => createAction(AuthActionTypes.UPDATE_EMAIL_SUCCESS),
    updateEmailFailed: (error: any) => createAction(AuthActionTypes.UPDATE_EMAIL_FAILED, { error }),
    revertEmailSuccess: () => createAction(AuthActionTypes.REVERT_EMAIL_SUCCESS),
    revertEmailFailed: (error: any) => createAction(AuthActionTypes.REVERT_EMAIL_FAILED, { error }),
    verifySuccess: () => createAction(AuthActionTypes.VERIFY_SUCCESS),
    verifyFailed: (error: any) => createAction(AuthActionTypes.VERIFY_FAILED, { error }),
    showVerificationModal: (oldEmail: string, newEmail: string) => createAction(
        AuthActionTypes.SHOW_VERIFICATION_MODAL, { oldEmail, newEmail },
    ),
    hideVerificationModal: () => createAction(AuthActionTypes.HIDE_VERIFICATION_MODAL),
    updateSuccess: (nickname: string, lang: string) => createAction(AuthActionTypes.UPDATE_SUCCESS, { nickname, lang }),
    updateFailed: (error: any) => createAction(AuthActionTypes.UPDATE_FAILED, { error }),
    logout: () => createAction(AuthActionTypes.LOGOUT),
    logoutSuccess: () => createAction(AuthActionTypes.LOGOUT_SUCCESS),
    logoutFailed: (error: any) => createAction(AuthActionTypes.LOGOUT_FAILED, { error }),
    deactivate: () => createAction(AuthActionTypes.DEACTIVATE),
    deactivateSuccess: () => createAction(AuthActionTypes.DEACTIVATE_SUCCESS),
    deactivateFailed: (error: any) => createAction(AuthActionTypes.DEACTIVATE_FAILED, { error }),
};

export type AuthActions = ActionUnion<typeof authActions>;

export const registerAsync = (
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    organization: string, // Humanome Eyes
    address: string, // Humanome Eyes
    // phone: string, // Humanome Eyes
    lang: string, // Humanome Eyes
    purpose: string, // Humanome Eyes
    password1: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    password2: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    confirmations: UserConfirmation[],
): ThunkAction => async (
    dispatch,
) => {
    dispatch(authActions.register());

    try {
        // CVAT
        // await cvat.server.register(username, firstName, lastName, email, password1, password2, confirmations);
        // const users = await cvat.users.get({ self: true });
        // dispatch(authActions.registerSuccess(users[0]));

        // Humanome Eyes
        await registerApi(userName, firstName, lastName, email, organization, address, lang, purpose, password1);
        store.set('afterRegistration', true);
        dispatch(authActions.registerSuccess());
    } catch (error) {
        dispatch(authActions.registerFailed(error));
    }
};

export const passwordResetAsync = (email: string): ThunkAction => async (dispatch) => {
    dispatch(authActions.fetch());
    try {
        await passwordResetApi(email);
        store.set('email', email);
        dispatch(authActions.passwordResetSuccess());
        browserHistory.push('/auth/new-password');
        window.location.reload();
    } catch (error) {
        dispatch(authActions.passwordResetFailed(error));
    }
};

export const newPasswordAsync = (code: string, password: string): ThunkAction => async (
    dispatch,
) => {
    dispatch(authActions.fetch());
    try {
        await newPasswordApi(code, password);
        dispatch(authActions.newPasswordSuccess());
    } catch (error) {
        dispatch(authActions.passwordResetFailed(error));
    }
};

export const updateCognitoEmailAsync = (oldEmail: string, newEmail: string): ThunkAction => async (dispatch) => {
    try {
        dispatch(authActions.showVerificationModal(oldEmail, newEmail));
        store.set('email', newEmail);
        await updateCognitoEmailApi(newEmail);
    } catch (error) {
        store.set('email', oldEmail);
        dispatch(authActions.hideVerificationModal());
        dispatch(authActions.updateEmailFailed(error));
    }
};

export const revertEmailAsync = (oldEmail:string): ThunkAction => async (dispatch) => {
    dispatch(authActions.fetch());
    try {
        store.set('email', oldEmail);
        await revertCognitoEmailApi(oldEmail);
        dispatch(authActions.revertEmailSuccess());
    } catch (error) {
        store.set('email', oldEmail);
        dispatch(authActions.revertEmailFailed(error));
    }
};

export const verifyAsync = (oldEmail: string, newEmail: string, code: string): ThunkAction => async (dispatch) => {
    dispatch(authActions.fetch());
    try {
        await verifyApi(code);
        dispatch(authActions.verifySuccess());
        await updateDjangoEmailApi(newEmail);
        dispatch(authActions.hideVerificationModal());
        dispatch(authActions.updateEmailSuccess());
    } catch (error) {
        store.set('email', oldEmail);
        dispatch(revertEmailAsync(oldEmail));
        dispatch(authActions.verifyFailed(error));
        dispatch(authActions.hideVerificationModal());
        dispatch(authActions.updateEmailFailed(error));
    }
};

// export const twoFactorAsync = (email: string, code: string, remember: boolean
// ): ThunkAction => async (dispatch) => {
//     dispatch(authActions.fetch());
//     try {
//         await twoFactorApi(email, code, remember);
//         const users = await cvat.users.get({ self: true });
//         dispatch(authActions.loginSuccess(users[0]));
//     } catch (error) {
//         dispatch(authActions.twoFactorFailed(error));
//     }
// }

export const loginAsync = (username: string, password: string): ThunkAction => async (dispatch) => {
    dispatch(authActions.login());

    try {
        await loginApi(username, password);
        try {
            const users = await cvat.users.get({ self: true });
            dispatch(authActions.loginSuccess(users[0]));
        } catch (error) {
            // browserHistory.push('/auth/two-factor');
            window.location.reload();
        }
    } catch (error) {
        dispatch(authActions.loginFailed(error));
    }
};

export const logoutAsync = (): ThunkAction => async (dispatch) => {
    dispatch(authActions.logout());

    try {
        await logoutApi();
        dispatch(authActions.logoutSuccess());
    } catch (error) {
        dispatch(authActions.logoutFailed(error));
    }
};

export const authorizedAsync = (): ThunkAction => async (dispatch) => {
    try {
        dispatch(authActions.fetch());

        const userInstance = (await cvat.users.get({ self: true }))[0];
        dispatch(authActions.authorizeSuccess(userInstance));
    } catch (error: any) {
        if (error.code !== undefined && error.code === 401) {
            dispatch(authActions.authorizeSuccess(null));
        } else {
            dispatch(authActions.authorizeFailed(error));
        }
    }
};

export const updateUserAttributesAsync = (data: any): ThunkAction => async (dispatch) => {
    dispatch(authActions.fetch());
    try {
        await updateAttributesApi(data);
        dispatch(authActions.updateSuccess(data.userName, data.lang));
    } catch (error) {
        dispatch(authActions.updateFailed(error));
    }
};

export const deactivateAsync = (): ThunkAction => async (dispatch) => {
    dispatch(authActions.deactivate());

    try {
        await deactivateUserApi();
        dispatch(authActions.deactivateSuccess());
        dispatch(authActions.logout());
        try {
            await logoutApi();
            dispatch(authActions.logoutSuccess());
        } catch (error) {
            dispatch(authActions.logoutFailed(error));
        }
    } catch (error) {
        dispatch(authActions.deactivateFailed(error));
    }
};
