// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { BoundariesActions, BoundariesActionTypes } from 'actions/boundaries-actions';
import { AuthActions, AuthActionTypes } from 'actions/auth-actions';
import { AdminAction, AdminActionTypes } from 'actions/admin-actions';
import { AuthState } from './interfaces';

const defaultState: AuthState = {
    initialized: false,
    fetching: false,
    verifying: false,
    newEmail: '',
    oldEmail: '',
    redirectTo: '',
    user: null,
};

export default function (state = defaultState, action: AuthActions | BoundariesActions | AdminAction): AuthState {
    switch (action.type) {
        case AuthActionTypes.AUTHORIZED_SUCCESS:
            return {
                ...state,
                initialized: true,
                user: action.payload.user,
                fetching: false,
            };
        case AuthActionTypes.AUTHORIZED_FAILED:
            return {
                ...state,
                initialized: true,
                fetching: false,
            };
        case AuthActionTypes.LOGIN:
            return {
                ...state,
                fetching: true,
            };
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                fetching: false,
                initialized: true,
                user: action.payload.user,
            };
        case AuthActionTypes.LOGIN_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                fetching: true,
            };
        case AuthActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                fetching: false,
                user: null,
            };
        case AuthActionTypes.REGISTER:
            return {
                ...state,
                fetching: true,
                user: null,
            };
        case AuthActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.REGISTER_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.FETCH:
            return {
                ...state,
                fetching: true,
            };
        // case AuthActionTypes.TWO_FACTOR_FAILED:
        // case AuthActionTypes.PASSWORD_RESET_SUCCESS:
        // case AuthActionTypes.UPDATE_EMAIL:
        case AuthActionTypes.PASSWORD_RESET_FAILED:
            if (action.payload.error.code === 'CodeMismatchException' ||
                action.payload.error.code === 'LimitExceededException') {
                return {
                    ...state,
                    fetching: false,
                };
            }
            return state;
        case AuthActionTypes.UPDATE_EMAIL_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.UPDATE_EMAIL_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.VERIFY_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.VERIFY_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.SHOW_VERIFICATION_MODAL:
            return {
                ...state,
                verifying: true,
                oldEmail: action.payload.oldEmail,
                newEmail: action.payload.newEmail,
            };
        case AuthActionTypes.HIDE_VERIFICATION_MODAL:
            return {
                ...state,
                verifying: false,
                fetching: false,
                oldEmail: '',
                newEmail: '',
            };
        case AuthActionTypes.UPDATE_SUCCESS: {
            const user = state.user.clone();
            user.profile.nickname = action.payload.nickname;
            user.profile.lang = action.payload.lang;
            return {
                ...state,
                fetching: false,
                user,
            };
        }
        case AuthActionTypes.UPDATE_FAILED:
            return {
                ...state,
                fetching: false,
            };
        case AuthActionTypes.NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                fetching: false,
                redirectTo: '/auth/login',
            };
        case AdminActionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                initialized: false,
            };
        case BoundariesActionTypes.RESET_AFTER_ERROR: {
            return { ...defaultState };
        }
        default:
            return state;
    }
}
