// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Tag from 'antd/lib/tag';
import Select from 'antd/lib/select';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';
import type { MenuProps } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';
import moment from 'moment';

import { MenuIcon } from 'icons';
import { Model } from 'reducers/interfaces';

interface Props {
    model: Model;
    onDelete(): void;
}

export default function UploadedModelItem(props: Props): JSX.Element {
    const {
        model,
        onDelete,
    } = props;

    const menus: MenuProps = {
        rootClassName: 'cvat-task-item-menu',
        items: [
            {
                key: 'delete',
                onClick: (): void => {
                    onDelete();
                },
                label: 'Delete',
            },
        ],
    };

    return (
        <Row className='cvat-models-list-item'>
            <Col span={4} xxl={3}>
                <Tag color='purple'>OpenVINO</Tag>
            </Col>
            <Col span={5} xxl={7}>
                <Text className='cvat-text-color'>
                    {model.name}
                </Text>
            </Col>
            <Col span={3}>
                <Text className='cvat-text-color'>
                    {model.owner ? model.owner.nickname : 'undefined'}
                </Text>
            </Col>
            <Col span={4}>
                <Text className='cvat-text-color'>
                    {moment(model.uploadDate).format('MMMM Do YYYY')}
                </Text>
            </Col>
            <Col span={5}>
                <Select
                    showSearch
                    placeholder='Supported labels'
                    style={{ width: '90%' }}
                    value='Supported labels'
                >
                    {model.labels.map(
                        (label): JSX.Element => (
                            <Select.Option key={label}>
                                {label}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </Col>
            <Col span={3} xxl={2}>
                <Text className='cvat-text-color'>Actions</Text>
                <Dropdown menu={menus}>
                    <Icon className='cvat-menu-icon' component={MenuIcon} rev='' />
                </Dropdown>
            </Col>
        </Row>
    );
}
