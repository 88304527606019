import React from 'react';
import { connect } from 'react-redux';

import { CombinedState, Model, JobStatus } from 'reducers/interfaces';
import LossAndAccuracyComponent from 'components/trained-model-page/loss-and-accuracy';

interface StateToProps {
    jobStatus: JobStatus | null;
}

// interface DispatchToProps {
// }

interface OwnProps {
    model: Model;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const tid = own.model.task_id;
    const { trainings } = state.models.activities;
    const jobStatus = (tid != null && tid in trainings) ? trainings[tid] : null;
    return { jobStatus };
}

// function mapDispatchToProps(dispatch: any): DispatchToProps {
//     return {};
// }

function LossAndAccuracyContainer(props: StateToProps & OwnProps): JSX.Element {
    return <LossAndAccuracyComponent {...props} />;
}

export default connect(
    mapStateToProps,
    // mapDispatchToProps,
)(LossAndAccuracyContainer);
