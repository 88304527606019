// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Timeline from 'antd/lib/timeline';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import {
    SaveIcon,
    UndoIcon,
    RedoIcon,
} from 'icons';

interface Props {
    saving: boolean;
    savingStatuses: string[];
    undoAction?: string;
    redoAction?: string;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
}

function LeftGroup(props: Props): JSX.Element {
    const {
        saving,
        savingStatuses,
        undoAction,
        redoAction,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
    } = props;
    const { t } = useTranslation();

    const pending = t(savingStatuses[savingStatuses.length - 1]) || 'Pending..';

    return (
        <Col className='cvat-annotation-header-left-group'>
            <AnnotationMenuContainer />
            <Button
                title={`${t('Save current changes')} ${saveShortcut}`}
                onClick={saving ? undefined : onSaveAnnotation}
                type='link'
                className={saving ? 'cvat-annotation-disabled-header-button' : 'cvat-annotation-header-button'}
            >
                <Icon component={SaveIcon} rev='' />
                { saving ? t('Saving') : t('Save') }
                <Modal
                    title={t('Saving changes on the server')}
                    open={saving}
                    footer={[]}
                    closable={false}
                >
                    <Timeline pending={pending}>
                        {
                            savingStatuses.slice(0, -1)
                                .map((
                                    status: string,
                                    id: number,
                                // eslint-disable-next-line react/no-array-index-key
                                // status cvat-core/src/annotations-saver.js
                                ) => <Timeline.Item key={id}>{t(status)}</Timeline.Item>)
                        }
                    </Timeline>
                </Modal>
            </Button>
            <Button
                title={`${t('Undo')}: ${t(undoAction || '')} ${undoShortcut}`}
                disabled={!undoAction}
                style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                type='link'
                className='cvat-annotation-header-button'
                onClick={onUndoClick}
            >
                <Icon component={UndoIcon} rev='' />
                <span>{t('Undo')}</span>
            </Button>
            <Button
                title={`${t('Redo')}: ${t(redoAction || '')} ${redoShortcut}`}
                disabled={!redoAction}
                style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                type='link'
                className='cvat-annotation-header-button'
                onClick={onRedoClick}
            >
                <Icon component={RedoIcon} rev='' />
                {t('Redo')}
            </Button>
        </Col>
    );
}

export default React.memo(LeftGroup);
