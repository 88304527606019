// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';

import { Row, Col } from 'antd/lib/grid';
import Slider from 'antd/lib/slider';
import { InputNumber } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    LinkOutlined,
} from '@ant-design/icons';

import { EyesTooltip as Tooltip } from 'utils/custom-antd';
import { isHoverSupported } from 'utils/enviroment';

import { clamp } from 'utils/math';

interface Props {
    startFrame: number;
    stopFrame: number;
    frameNumber: number;
    frameFilename: string;
    focusFrameInputShortcut: string;
    inputFrameRef: React.RefObject<HTMLInputElement>;
    onSliderChange(value: number): void;
    onInputChange(value: number): void;
    onURLIconClick(): void;
}

function PlayerNavigation(props: Props): JSX.Element {
    const {
        startFrame,
        stopFrame,
        frameNumber,
        frameFilename,
        focusFrameInputShortcut,
        inputFrameRef,
        onSliderChange,
        onInputChange,
        onURLIconClick,
    } = props;
    const { t } = useTranslation();

    const [frameInputValue, setFrameInputValue] = useState<number>(frameNumber);

    useEffect(() => {
        if (frameNumber !== frameInputValue) {
            setFrameInputValue(frameNumber);
        }
    }, [frameNumber]);

    return (
        <>
            <Col className='cvat-player-controls'>
                <Row>
                    <Col>
                        <Slider
                            className='cvat-player-slider'
                            min={startFrame}
                            max={stopFrame}
                            value={frameNumber || 0}
                            onChange={onSliderChange}
                            tooltip={isHoverSupported() ? undefined : { open: false }}
                        />
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col className='cvat-player-filename-wrapper'>
                        <Tooltip title={frameFilename}>
                            <Text type='secondary'>{frameFilename}</Text>
                        </Tooltip>
                    </Col>
                    <Col offset={1}>
                        <Tooltip title={t('Create frame URL')}>
                            <LinkOutlined className='cvat-player-frame-url-icon' onClick={onURLIconClick} rev='' />
                        </Tooltip>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Tooltip title={t('Press {{focusFrameInputShortcut}} to focus here', { focusFrameInputShortcut })}>
                    <InputNumber
                        className='cvat-player-frame-selector'
                        type='number'
                        value={frameInputValue}
                        onChange={(value: number | null) => {
                            if (typeof (value) === 'number') {
                                setFrameInputValue(Math.floor(
                                    clamp(value, startFrame, stopFrame),
                                ));
                            }
                        }}
                        onBlur={() => {
                            onInputChange(frameInputValue);
                        }}
                        onPressEnter={() => {
                            onInputChange(frameInputValue);
                        }}
                        ref={inputFrameRef}
                    />
                </Tooltip>
            </Col>
        </>
    );
}

export default React.memo(PlayerNavigation);
