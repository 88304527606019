// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Form, Button } from 'antd';
import Input, { InputRef } from 'antd/lib/input';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { withTranslation, WithTranslation } from 'react-i18next';
// // eslint-disable-next-line import/no-extraneous-dependencies
// import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

export interface LoginData {
    username: string;
    password: string;
}

type LoginFormProps = {
    fetching: boolean;
    onSubmit(loginData: LoginData): void;
} & WithTranslation;

class LoginFormComponent extends React.PureComponent<LoginFormProps> {
    private emailInput = React.createRef<InputRef>();
    private passwordInput = React.createRef<InputRef>();

    public componentDidMount(): void {
        this.emailInput.current?.focus();
    }

    private handleFinish = (values: LoginData): void => {
        const {
            onSubmit,
        } = this.props;
        console.log(values);
        onSubmit(values);
        this.passwordInput.current?.select();
    };

    private handleFinishFailed = (/*errorInfo: ValidateErrorEntity<LoginData>*/): void => {
        if (!this.emailInput.current?.input?.value) {
            this.emailInput.current?.focus();
        } else {
            this.passwordInput.current?.focus();
        }
    };

    private renderUsernameField(): JSX.Element {
        const { t } = this.props;

        return (
            <Form.Item name='username' hasFeedback rules={[{
                required: true,
                message: t('Please specify your Email address'),
            }]}>
                <Input
                    autoComplete='email'
                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Email address')}
                    type='email'
                    ref={this.emailInput}
                    onPressEnter={(e) => {
                        if (this.emailInput.current?.input?.value && !this.passwordInput.current?.input?.value) {
                            this.passwordInput.current?.focus();
                            e.preventDefault();
                        }
                    }}
                />
            </Form.Item>
        );
    }

    private renderPasswordField(): JSX.Element {
        const { t } = this.props;

        return (
            <Form.Item name='password' hasFeedback rules={[{
                required: true,
                message: t('Please specify a password'),
            }]}>
                <Input.Password
                    autoComplete='current-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} rev='' />}
                    placeholder={t('Password')}
                    type='password'
                    ref={this.passwordInput}
                />
            </Form.Item>
        );
    }

    public render(): JSX.Element {
        const { fetching } = this.props;
        const { t } = this.props;
        return (
            <Form onFinish={this.handleFinish} onFinishFailed={this.handleFinishFailed} className='login-form'>
                {this.renderUsernameField()}
                {this.renderPasswordField()}

                <Form.Item>
                    <Button
                        type='primary'
                        loading={fetching}
                        disabled={fetching}
                        htmlType='submit'
                        className='login-form-button'
                    >
                        {t('Sign in')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(LoginFormComponent);
