import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Col, Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Alert from 'antd/lib/alert';
import { Model, ModelStatus, JobStatus } from 'reducers/interfaces';
import getCore from 'cvat-core-wrapper';

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

interface Props extends WithTranslation {
    model: Model;
    jobStatus: JobStatus | null;
}

interface State {
    chartData: any;
}

function getMaxSizeType(chartData: any[]): string {
    let maxValue = 0.0;
    let sizeType = 'large';
    for (const val of chartData) {
        if (maxValue < val.large) {
            maxValue = val.large;
            sizeType = 'large';
        }
        if (maxValue < val.medium) {
            maxValue = val.medium;
            sizeType = 'medium';
        }
        if (maxValue < val.small) {
            maxValue = val.small;
            sizeType = 'small';
        }
    }
    return sizeType;
}

function formatToolTipValue(
    value: string | number | (string | number)[],
    digits = 4,
): string | number | (string | number)[] {
    function format(x: string | number): string | number {
        return Number.isNaN(x) ? x : Number(x).toFixed(digits);
    }
    if (Array.isArray(value)) {
        return value.map((x) => format(x));
    }
    return format(value);
}

const modelStoppedStatus = [
    ModelStatus.FINISHED,
    ModelStatus.FAILED,
    ModelStatus.STOPPED,
    ModelStatus.NOT_FOUND,
    ModelStatus.UNKNOWN,
];

class LossAndAccuracyComponent extends React.PureComponent<Props & RouteComponentProps, State> {
    timer: any = null;

    constructor(props: Props & RouteComponentProps) {
        super(props);

        this.state = {
            chartData: null,
        };
    }

    public componentDidMount(): void {
        const { model, jobStatus } = this.props;
        if (modelStoppedStatus.includes(model.status)) {
            this.load();
        } else if (model.status === ModelStatus.TRAINING &&
        jobStatus && jobStatus.has_checkpoint) {
            this.load();
            if (this.timer == null) {
                this.timer = setInterval(this.load.bind(this), 10000);
            }
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const { model, jobStatus } = this.props;
            if (prevProps.model.id !== model.id) {
                this.load();
            }
            if (this.timer == null &&
            model.status === ModelStatus.TRAINING &&
            jobStatus && jobStatus.has_checkpoint) {
                this.load();
                this.timer = setInterval(this.load.bind(this), 10000);
            }
            if (prevProps.model.status !== model.status && modelStoppedStatus.includes(model.status)) {
                this.load();
            }
            if (this.timer != null && modelStoppedStatus.includes(model.status)) {
                clearInterval(this.timer);
            }
        }
    }

    public componentWillUnmount(): void {
        if (this.timer != null) clearInterval(this.timer);
    }

    private async fetchChartData(): Promise<void> {
        const { model } = this.props;
        try {
            const response = await core.server.request(
                `${baseURL}/tensorflow/train/models/${model.id}/graph`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.epoch) {
                const chartData = [];
                for (let i = 0; i < response.epoch.length; i++) {
                    const chartHash: { [key: string]: string } = {};
                    for (const key of Object.keys(response)) {
                        chartHash[key] = response[key][i];
                    }
                    chartData.push(chartHash);
                }
                this.setState({ chartData });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.debug(error);
        }
        if (this.timer != null && modelStoppedStatus.includes(model.status)) {
            clearInterval(this.timer);
        }
    }

    private load(): void {
        this.fetchChartData();
    }

    private renderChart(): JSX.Element {
        const { chartData } = this.state;
        const { model, t } = this.props;

        if (chartData == null) {
            if (model !== null) {
                if (model.status === ModelStatus.TRAINING) {
                    return (
                        <Alert
                            message={t('On Training')}
                            description={t('Learning curve (Loss, precision and recall) will be displayed after a while.')}
                            type='info'
                            showIcon
                        />
                    );
                }
                if ([ModelStatus.PREPARING, ModelStatus.QUEUED, ModelStatus.PRETRAINING].includes(model.status)) {
                    return (
                        <Alert
                            message={t('Preparing for Training')}
                            description={t('Learning curve (Loss, precision and recall) will be displayed after a while.')}
                            type='info'
                            showIcon
                        />
                    );
                }
            }
            return (
                <></>
            );
        }

        const precisionKey = {
            large: 'DetectionBoxes_Precision/mAP (large)',
            medium: 'DetectionBoxes_Precision/mAP (medium)',
            small: 'DetectionBoxes_Precision/mAP (small)',
        };
        const recallKey = {
            large: 'DetectionBoxes_Recall/AR@100 (large)',
            medium: 'DetectionBoxes_Recall/AR@100 (medium)',
            small: 'DetectionBoxes_Recall/AR@100 (small)',
        };

        const cut = chartData.reduce((acc: any, record: any) => {
            if (acc.precision.large && record[precisionKey.large] > 0) {
                acc.precision.large = false;
            }
            if (acc.precision.medium && record[precisionKey.medium] > 0) {
                acc.precision.medium = false;
            }
            if (acc.precision.small && record[precisionKey.small] > 0) {
                acc.precision.small = false;
            }
            if (acc.recall.large && record[recallKey.large] > 0) {
                acc.recall.large = false;
            }
            if (acc.recall.medium && record[recallKey.medium] > 0) {
                acc.recall.medium = false;
            }
            if (acc.recall.small && record[recallKey.small] > 0) {
                acc.recall.small = false;
            }
            return acc;
        }, {
            precision: { large: true, medium: true, small: true },
            recall: { large: true, medium: true, small: true },
        });

        const precision = [];
        const recall = [];
        for (const val of chartData) {
            precision.push(
                {
                    epoch: val.epoch,
                    large: cut.precision.large ? null : val[precisionKey.large],
                    medium: cut.precision.medium ? null : val[precisionKey.medium],
                    small: cut.precision.small ? null : val[precisionKey.small],
                },
            );
            recall.push(
                {
                    epoch: val.epoch,
                    large: cut.recall.large ? null : val[recallKey.large],
                    medium: cut.recall.medium ? null : val[recallKey.medium],
                    small: cut.recall.small ? null : val[recallKey.small],
                },
            );
        }

        return (
            <>
                <Row>
                    <Col span={24}>
                        <LineChart width={600} height={300} data={chartData}>
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis
                                dataKey='epoch'
                                type='number'
                                label={{
                                    value: 'epoch',
                                    offset: 0,
                                    position: 'insideBottom',
                                }}
                            />
                            <YAxis
                                dataKey='Loss/total_loss'
                                yAxisId='left'
                                label={{
                                    value: 'Total Loss',
                                    angle: -90,
                                    position: 'insideLeft',
                                }}
                            />
                            <Tooltip
                                label='epoch'
                                labelFormatter={(num) => `epoch : ${num}`}
                                formatter={(value) => formatToolTipValue(value)}
                            />
                            <Legend verticalAlign='top' height={50} align='right' />
                            <Line dataKey='Loss/total_loss' yAxisId='left' stroke='#1f9189' />
                        </LineChart>
                    </Col>
                </Row>
                {chartData !== null && 'DetectionBoxes_Precision/mAP' in chartData[0] && (
                    <>
                        <Row>
                            <Col span={24}>
                                <LineChart
                                    width={600}
                                    height={300}
                                    data={precision}
                                >
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis
                                        dataKey='epoch'
                                        type='number'
                                        label={{
                                            value: 'epoch',
                                            offset: 0,
                                            position: 'insideBottom',
                                        }}
                                    />
                                    <YAxis
                                        dataKey={getMaxSizeType(precision)}
                                        yAxisId='left'
                                        label={{
                                            value: 'Precision',
                                            angle: -90,
                                            position: 'insideLeft',
                                        }}
                                    />
                                    <Tooltip
                                        label='epoch'
                                        labelFormatter={(num) => `epoch : ${num}`}
                                        formatter={(value) => formatToolTipValue(value)}
                                    />
                                    <Legend verticalAlign='top' height={50} align='right' />
                                    <Line dataKey='large' yAxisId='left' stroke='#1f9189' />
                                    <Line dataKey='medium' yAxisId='left' stroke='#82ca9d' />
                                    <Line dataKey='small' yAxisId='left' stroke='#8ec5c1' />
                                </LineChart>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <LineChart width={600} height={300} data={recall}>
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis
                                        dataKey='epoch'
                                        type='number'
                                        label={{
                                            value: 'epoch',
                                            offset: 0,
                                            position: 'insideBottom',
                                        }}
                                    />
                                    <YAxis
                                        dataKey={getMaxSizeType(recall)}
                                        yAxisId='left'
                                        label={{
                                            value: 'Recall',
                                            angle: -90,
                                            position: 'insideLeft',
                                        }}
                                    />
                                    <Tooltip
                                        label='epoch'
                                        labelFormatter={(num) => `epoch : ${num}`}
                                        formatter={(value) => formatToolTipValue(value)}
                                    />
                                    <Legend verticalAlign='top' height={50} align='right' />
                                    <Line dataKey='large' yAxisId='left' stroke='#1f9189' />
                                    <Line dataKey='medium' yAxisId='left' stroke='#82ca9d' />
                                    <Line dataKey='small' yAxisId='left' stroke='#8ec5c1' />
                                </LineChart>
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    }

    public render(): JSX.Element {
        const { model } = this.props;
        const { t } = this.props;

        if ([
            ModelStatus.FAILED,
            ModelStatus.STOPPED,
            ModelStatus.NOT_FOUND,
            ModelStatus.UNKNOWN,
        ].includes(model.status)) {
            return (
                <></>
            );
        }
        return (
            <div className='cvat-training-model'>
                <Row>
                    <Col>
                        <Text className='cvat-text-color cvat-jobs-header'>
                            {t('Validation Loss and Accuracy')}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.renderChart()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(withTranslation()(LossAndAccuracyComponent));
