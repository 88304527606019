// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
// import Title from 'antd/lib/typography/Title';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import {
    Tabs,
    Col,
    Row,
} from 'antd';
import { UpgradeImage } from 'icons';
// import getCore from 'cvat-core-wrapper';

// const core = getCore();
// const baseURL = core.config.backendAPI;

const sizes = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 20 },
    xl: { span: 18 },
    xxl: { span: 18 },
};

interface UpgradeComponentProps extends WithTranslation {
    user: any;

    // dataKey: string;

}

export interface PlanState {
    plan: string;
    timeline_visible: boolean;
    visible: boolean;
    upgrade_status: boolean;
}
type State = PlanState;
const defaultState = {
    plan: '',
    timeline_visible: false,
    visible: false,
    upgrade_status: false,
};

class UpgradeComponent extends React.PureComponent<UpgradeComponentProps & RouteComponentProps, State> {
    public constructor(props: UpgradeComponentProps & RouteComponentProps) {
        super(props);
        this.state = { ...defaultState };
    }

    public componentDidMount(): void {
        const {
            user,
        } = this.props;
        let { plan } = user.profile;
        const { plan: statePlan } = this.state;
        if (statePlan === 'Pro') {
            plan = 'Pro';
        }
        this.setState({ plan });
    }

    public componentDidUpdate(): void {
        const {
            user,
        } = this.props;
        let { plan } = user.profile;
        const { plan: statePlan } = this.state;
        if (statePlan === 'Pro') {
            plan = 'Pro';
        }
        this.setState({ plan });
    }

    hide = (): void => {
        // The following react state was previously used but is now supposed to be unused.
        // eslint-disable-next-line react/no-unused-state
        this.setState({ visible: false });
    };

    handleVisibleChange = (visible: boolean): void => {
        // The following react state was previously used but is now supposed to be unused.
        // eslint-disable-next-line react/no-unused-state
        this.setState({ visible });
    };

    showTimelineModal = (): void => {
        this.setState({
            timeline_visible: true,
        });
    };

    handleTimelineOk = (): void => {
        this.setState({
            timeline_visible: false,
        });
    };

    handleTimelineCancel = (e: any): void => {
        // eslint-disable-next-line no-console
        console.log(e);
        this.setState({
            timeline_visible: false,
        });
    };
    /*
    private downgradePlan = (): boolean => {
        const {
            user,
        } = this.props;
        const response = core.server.request(
            `${baseURL}/auth/downgrade`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        try {
            if (response.status) {
                console.debug('downgradedPayment: ', user.profile.plan);
            }
        } catch (err) {
            return false;
        }
        this.setState({ plan: 'Basic' });
        this.forceUpdate();
        return true;
    };

    private async upgradePlan(payload: any): Promise<void> {
        try {
            const response = await core.server.request(
                `${baseURL}/auth/upgrade`, {
                    method: 'POST',
                    data: JSON.stringify({
                        "payjp-token": payload.token,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
            if (response.status === 'success') {
                this.hide
                this.setState( state => ({
                    plan: "Pro",
                    upgrade_status: true
                }));
                notification.info({
                    // Humanome Eyes
                    // message: 'The task has been created',
                    message: (
                        <>
                            <Paragraph>
                                Proプランへのアップグレード、ありがとうございます。
                                プラン変更反映のため、一度画面を
                                <a href="">リロード</a>してください。
                            </Paragraph>
                        </>),
                    duration: 30,
                });
            } else {
                console.debug('upgradePayment was failed');
                this.hide
                this.setState( state => ({
                    upgrade_status: false
                }));
                notification.error({
                    message: 'Your card was not accepted',
                    description: payload.message,
                });
            }
        } catch (err) {
            console.debug('failed caused by error.', err);
            this.hide
            this.setState( state => ({
                upgrade_status: false
            }));
            notification.error({
                message: 'Your card was not accepted',
                description: payload.message,
            });
        }
    }
    */

    render(): JSX.Element {
        const {
            user,
        } = this.props;
        const { t } = this.props;
        const { timeline_visible: timelineVisible } = this.state;
        let { plan: currentPlan } = this.state;
        if (currentPlan === '') {
            currentPlan = user.profile.plan;
        }

        return (
            <div className='cvat-settings-page'>
                {(currentPlan === 'Basic') &&
                    (
                        <>
                            <Row style={{ marginTop: '20px' }} justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Text strong style={{ fontSize: '20pt' }}>Pro プランへのアップグレード</Text>
                                </Col>
                            </Row>
                            <Row justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Text strong>
                                        {t('Current plan')}
                                        :
                                        {' '}
                                        { currentPlan }
                                    </Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Button type='primary' href='https://docs.google.com/forms/d/1TaY65_Gm-2geeZOiVRvkPGg06h0t6mBUJ2u2XP9erGY/viewform' target='_blank' rel='noopener noreferrer'>
                                        下記に同意し、Proプランを申し込む
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Text>Proプランへのアップグレードは、下記の内容をご確認の上、上記のボタンよりお申し込み下さい。</Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Text>
                                        <a href='https://humanome.jp/activities/eyes/agreement/'>Humanome Eyes利用約款</a>
                                        をご確認ください。
                                        また、プラン変更と決済のタイミングについては下記のボタンより内容をご確認ください。
                                    </Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Button onClick={this.showTimelineModal}>
                                        プラン変更と決済のタイミングについて
                                    </Button>
                                    <Modal
                                        title='プランの変更と決済のタイミングについて'
                                        open={timelineVisible}
                                        onOk={this.handleTimelineOk}
                                        onCancel={this.handleTimelineCancel}
                                        width='800'
                                        footer=''
                                    >
                                        <Tabs
                                            items={[
                                                {
                                                    label: 'アップグレード',
                                                    key: '1',
                                                    children: <img width='100%' src='https://humanome.jp/wp-content/uploads/2021/08/pay-fig007.png' alt='' />,
                                                },
                                                {
                                                    label: 'ダウングレード① 毎月24日まで',
                                                    key: '2',
                                                    children: <img width='100%' src='https://humanome.jp/wp-content/uploads/2021/02/pay-fig06.png' alt='' />,
                                                },
                                                {
                                                    label: 'ダウングレード② 毎月25日〜月末',
                                                    key: '3',
                                                    children: <img width='100%' src='https://humanome.jp/wp-content/uploads/2021/08/pay-fig005.png' alt='' />,
                                                },
                                            ]}
                                            defaultActiveKey='1'
                                        />
                                    </Modal>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Text strong style={{ fontSize: '10pt' }}>月額: 59,400円（税込）</Text>
                                </Col>
                                <Col {...sizes}>
                                    <Text>利用料は、毎月1日に当月分を請求いたします。</Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                                <Col {...sizes}>
                                    <Text>
                                        ご質問がございましたら、
                                        <a href='https://humanome.jp/contact/eyes-operation/'>お問い合わせ窓口</a>
                                        より、連絡をお願いいたします。
                                    </Text>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                                <Col span={9} />
                                <Col span={1}>
                                    <img alt='Upgrade' src={UpgradeImage} style={{ opacity: 0.5 }} />
                                </Col>
                            </Row>
                        </>
                    )}
                {(currentPlan === 'Pro') &&
                (
                    <>
                        <Row style={{ marginTop: '20px' }} justify='center' align='middle'>
                            <Col {...sizes}>
                                <Text strong style={{ fontSize: '15pt' }}>Basicプランへのダウングレード</Text>
                            </Col>
                        </Row>
                        <Row justify='center' align='middle'>
                            <Col {...sizes}>
                                <Text strong>
                                    {t('Current plan')}
                                    :
                                    {' '}
                                    { currentPlan }
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }} justify='center' align='middle'>
                            <Col {...sizes}>
                                <Text>
                                    お支払いに関する質問は、お手数ですが
                                    <a href='https://humanome.jp/contact/eyes-operation/'>お問い合わせ窓口</a>
                                    より、連絡をお願いいたします。
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                            <Col {...sizes}>
                                <Text>
                                    Basicプランへのダウングレード依頼は、
                                    <a href='https://humanome.jp/contact/eyes-operation/'>お問い合わせ窓口</a>
                                    より、受け付けております。
                                </Text>
                                <Text>ダウングレード後も、今月末までは Proプランにてご利用いただけます。</Text>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                            <Col {...sizes}>
                                <Text>
                                    If you would like to downgrade to Basic plan or
                                    {' '}
                                    if you have any questions about payments, please contact us through
                                    {' '}
                                    <a href='https://humanome.jp/contact/eyes-operation/'>inquiry desk</a>
                                    . Thank you for your cooperation.
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }} justify='center' align='middle'>
                            <Col {...sizes}>
                                <Text>After cancel it, Pro plan will continue to the end of this month.</Text>
                            </Col>
                        </Row>
                        {/* { false && // Please set uncomnent and change true when you debug downgrade plan form
                            (
                                <Row style={{ marginTop: '10px' }} type='flex' justify='center' align='middle'>
                                    <Col {...sizes}>
                                        <Button
                                            size='small'
                                            type='primary'
                                            onClick={() => this.downgradePlan()}
                                        >
                                            Cancel Pro Plan and Downgrade to Basic
                                        </Button>
                                    </Col>
                                </Row>
                            )} */}
                    </>
                )}
                {(currentPlan !== 'Basic') && (currentPlan !== 'Pro') && (
                    <Row justify='center' align='middle'>
                        <Col {...sizes}>
                            <Text strong>
                                {t('Current plan')}
                                :
                                {' '}
                                { currentPlan }
                            </Text>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

export default withRouter(withTranslation()(UpgradeComponent));
