// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Row, Col } from 'antd/lib/grid';
import {
    Tag,
    Select,
} from 'antd';
import Text from 'antd/lib/typography/Text';

import { withTranslation, WithTranslation } from 'react-i18next';

import { Model } from 'reducers/interfaces';

interface Props extends RouteComponentProps, WithTranslation {
    model: Model;
}

class BuildModelItemComponent extends React.PureComponent<Props> {
    public render(): JSX.Element {
        const { model, history, t } = this.props;
        return (
            <Row
                className='cvat-models-list-item'
                onClick={(e: React.MouseEvent): void => {
                    e.preventDefault();
                    history.push(`models/${model.id}`);
                }}
            >
                <Col span={4}>
                    <Tag color='orange'>{t('Pre-Trained')}</Tag>
                </Col>
                <Col span={10}>
                    <Text className='cvat-text-color'>
                        {t(model.printName)/* actions/models-actions.ts */}
                    </Text>
                </Col>
                <Col span={10}>
                    <Col
                        span={22}
                        onClick={(e: React.MouseEvent): void => {
                            e.stopPropagation();
                        }}
                    >
                        <Select
                            showSearch
                            placeholder={t('Supported labels')}
                            style={{ width: '100%' }}
                            value={t('Supported labels')}
                        >
                            {model.labels.map(
                                (label): JSX.Element => (
                                    <Select.Option key={label}>
                                        {label}
                                    </Select.Option>
                                ),
                            )}
                        </Select>
                    </Col>
                </Col>
            </Row>
        );
    }
}

export default withRouter(withTranslation()(BuildModelItemComponent));
