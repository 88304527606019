// Copyright (C) 2020 Humanome Lab.

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
    Row,
    Col,
    Tag,
    Button,
    Table,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Model, ModelStatus } from 'reducers/interfaces';
import getStatusColor from 'utils/trained-model';

interface Props extends WithTranslation {
    models: Model[];
    training: boolean;
    jobStatus: {
        mid: number;
        status: ModelStatus;
    } | null;
}

interface State {
    data: any[];
}

const defaultState = {
    data: [],
};

class TaskModelsListComponent extends React.Component<Props & RouteComponentProps, State> {
    constructor(props: Props & RouteComponentProps) {
        super(props);
        this.state = defaultState;
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const {
                jobStatus,
            } = this.props;
            const { data } = this.state;

            if (jobStatus !== null) {
                const newData = data.map((model) => {
                    if (model.id === jobStatus.mid && model.status !== jobStatus.status) {
                        return {
                            ...model,
                            status: jobStatus.status,
                        };
                    }
                    return model;
                });
                this.setState({ data: newData });
            }
        }
    }

    private getColumns(): any {
        const { history } = this.props;
        const { t } = this.props;

        return [
            {
                title: t('Status'),
                dataIndex: 'status',
                key: 'status',
                render: (status: ModelStatus): JSX.Element => (
                    <Tag color={getStatusColor(status)}>
                        {t(status)}
                    </Tag>
                ),
            }, {
                title: t('Name'),
                dataIndex: 'name',
                key: 'name',
                render: (name: string, model: Model): JSX.Element => (
                    <div>
                        <Button
                            type='link'
                            onClick={(e: React.MouseEvent): void => {
                                e.preventDefault();
                                history.push(`/models/${model.id}`);
                            }}
                            href={`/models/${model.id}`}
                        >
                            {name || 'no name'}
                        </Button>
                    </div>
                ),
            }, {
                title: t('Owner'),
                dataIndex: 'owner',
                key: 'owner',
                render: (owner: any): JSX.Element => (
                    <Text className='cvat-text-color'>
                        {(owner) ? owner.nickname : '-'}
                    </Text>
                ),
            }, {
                title: t('Trained at'),
                dataIndex: 'uploadDate',
                key: 'uploadDate',
                render: (uploadDate: string): JSX.Element => (
                    <Text className='cvat-text-color'>
                        {moment(uploadDate).format(t('FORMAT_CREATED'))}
                    </Text>
                ),
            },
        ];
    }

    public render(): JSX.Element {
        const { models, t } = this.props;
        return (
            <div className='cvat-task-models-list'>
                <Row justify='space-between' align='middle'>
                    <Col>
                        <Text className='cvat-text-color cvat-jobs-header'>
                            {t('Trained models')}
                        </Text>
                    </Col>
                </Row>
                <Table
                    className='cvat-task-jobs-table'
                    columns={this.getColumns()}
                    dataSource={models}
                    rowKey='id'
                    size='small'
                    pagination={{ position: ['bottomCenter'] }}
                />
            </div>
        );
    }
}

export default withRouter(withTranslation()(TaskModelsListComponent));
