// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';
import Tooltip from 'antd/lib/tooltip';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlusOutlined } from '@ant-design/icons';

interface VisibleTopBarProps {
    onSearch: (value: string) => void;
    searchValue: string;
    numberOfVisibleTasks: number;
    isReachedLimit: boolean;
}

function TopBarComponent(props: VisibleTopBarProps & RouteComponentProps): JSX.Element {
    const {
        searchValue,
        history,
        onSearch,
        isReachedLimit,
    } = props;

    const { t } = useTranslation();
    return (
        <>
            <Row justify='center' align='middle'>
                <Col md={22} lg={18} xl={16} xxl={14} />
            </Row>
            <Row justify='center' align='middle'>
                <Col md={11} lg={9} xl={8} xxl={7}>
                    <Text className='cvat-title'>{t('Tasks')}</Text>
                    <Input.Search
                        className='cvat-task-page-search-task'
                        defaultValue={searchValue}
                        onSearch={onSearch}
                        size='large'
                        placeholder={t('Search')}
                    />
                </Col>
                <Col
                    md={{ span: 11 }}
                    lg={{ span: 9 }}
                    xl={{ span: 8 }}
                    xxl={{ span: 7 }}
                >
                    {(!isReachedLimit) ?
                        (
                            <Button
                                size='large'
                                id='cvat-create-task-button'
                                type='primary'
                                onClick={
                                    (): void => history.push('/tasks/create')
                                }
                                icon={<PlusOutlined rev='' />}
                            >
                                {t('Create new task')}
                            </Button>
                        ) :
                        (
                            <Tooltip title={t('Need to upgrade to create a new task')}>
                                <Button
                                    size='large'
                                    id='cvat-create-task-button'
                                    type='primary'
                                    icon={<PlusOutlined rev='' />}
                                    disabled
                                >
                                    {t('Create new task')}
                                </Button>
                            </Tooltip>
                        )}
                </Col>
            </Row>
        </>
    );
}

export default withRouter(TopBarComponent);
