// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';
import { trainingAsync, cancelTrainingAsync } from 'actions/training-model-actions';
import TrainingComponent from 'components/task-page/training';
import { CombinedState, ModelStatus, Model } from 'reducers/interfaces';
import { TrainingData } from 'components/task-page/training-form';
import { checkJobsStatusAsync } from 'actions/models-actions';

interface OwnProps {
    taskId: number;
    models: Model[];
    jobStatus: ModelStatus;
}

interface StateToProps {
    loaded: boolean;
    modelsInitialized: boolean;
    statusInitialized: boolean;
    checkingStatus: boolean;
    plan: string;
}

interface DispatchToProps {
    onStartTraining: (trainingData: TrainingData) => void;
    onTerminateTraining: () => void;
    checkJobsStatus: (timeout: number) => void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const {
        models: {
            initialized: modelsInitialized,
            statusInitialized,
            checkingStatus,
        },
        auth,
    } = state;
    return {
        ...own,
        loaded: state.tasks.loaded,
        modelsInitialized,
        statusInitialized,
        checkingStatus,
        plan: auth.user.profile.plan,
    };
}

function mapDispatchToProps(dispatch: any, own: OwnProps): DispatchToProps {
    const { taskId } = own;
    return {
        onStartTraining: (trainingData: TrainingData): void => {
            dispatch(trainingAsync(taskId, trainingData));
        },
        onTerminateTraining: (): void => dispatch(cancelTrainingAsync(taskId)),
        checkJobsStatus: (timeout: number) => {
            dispatch(checkJobsStatusAsync([taskId], timeout, true));
        },
    };
}

function TrainingContainer(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    return (
        <TrainingComponent {...props} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrainingContainer);
