import { connect } from 'react-redux';

import TrainedModelItem from 'components/models-page/trained-model-item';
import {
    CombinedState, Model,
} from 'reducers/interfaces';
import {
    deleteTrainedModelAsync,
    cancelTrainingModelAsync,
} from 'actions/models-actions';

interface StateToProps {
    // owner: any;
    model: Model;
    deleted: boolean;
}

interface DispatchToProps {
    deleteTrainedModel(id: number): void;
    cancelTrainingModel(id: number): void;
}

interface OwnProps {
    model: Model;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const { model } = own;
    const mid = model.id;
    const { deletes } = state.models.activities;
    // const owner = state.users.users.filter((user) => user.id === model.ownerID)[0];

    if (mid === null) {
        return {
            // owner,
            model,
            deleted: false,
        };
    }
    return {
        // owner,
        model,
        deleted: mid in deletes ? deletes[mid] === true : false,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        deleteTrainedModel(id: number): void {
            dispatch(deleteTrainedModelAsync(id));
        },
        cancelTrainingModel(id: number): void {
            dispatch(cancelTrainingModelAsync(id));
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrainedModelItem);
