
import React from 'react';
import { connect } from 'react-redux';

import {
    CombinedState,
    ModelStatus,
    Model,
} from 'reducers/interfaces';
import TaskModelsListComponent from 'components/task-page/models-list';

interface StateToProps {
    training: boolean;
    jobStatus: {
        mid: number;
        status: ModelStatus;
    } | null;
}

interface OwnProps {
    taskId: number;
    models: Model[];
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    if (own.taskId in state.models.activities.trainings) {
        const { mid, status } = state.models.activities.trainings[own.taskId];
        return {
            training: true,
            jobStatus: { mid, status },
        };
    }
    return {
        training: false,
        jobStatus: null,
    };
}

function TaskModelsListContainer(props: OwnProps & StateToProps): JSX.Element {
    return (
        <TaskModelsListComponent {...props} />
    );
}

export default connect(
    mapStateToProps,
)(TaskModelsListContainer);
