// Copyright (C) 2021 Humanome Lab., Inc.
//
import patterns from 'utils/validation-patterns';

function validator(
    testPatterns: {
        pattern: RegExp, message: string
    }[],
    value: string,
): string {
    let msg = '';

    for (const test of testPatterns) {
        if (!test.pattern.test(value)) {
            msg = test.message;
            break;
        }
    }

    return msg;
}

export function passwordValidator(value: string): string {
    const testPatterns = [
        patterns.validatePasswordLength,
        patterns.passwordContainsNumericCharacters,
        patterns.passwordContainsUpperCaseCharacter,
        patterns.passwordContainsLowerCaseCharacter,
        patterns.passwordContainsSymbolCharacter,
        patterns.passwordBeginNonSpaceCharacter,
        patterns.passwordEndNonSpaceCharacter,
        patterns.passwordAllUsableCharacters,
    ];

    return validator(testPatterns, value);
}

export function oldPasswordValidator(value: string): string {
    const testPatterns = [
        patterns.validatePasswordLength,
        patterns.passwordContainsNumericCharacters,
        patterns.passwordContainsUpperCaseCharacter,
        patterns.passwordContainsLowerCaseCharacter,
        patterns.passwordContainsSymbolCharacter,
        patterns.passwordBeginNonSpaceCharacter,
        patterns.passwordEndNonSpaceCharacter,
    ];

    return validator(testPatterns, value);
}

export function usernameValidator(value: string): string {
    const testPatterns = [
        patterns.validateUsernameLength,
        patterns.validateUsernameCharacters,
    ];

    return validator(testPatterns, value);
}
