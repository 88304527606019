// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
// import Tag from 'antd/lib/tag';
// import Icon from 'antd/lib/icon';
// import Modal from 'antd/lib/modal';
// import Button from 'antd/lib/button';
// import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';

import getCore from 'cvat-core-wrapper';
// import patterns from 'utils/validation-patterns';
// import { getReposData, syncRepos } from 'utils/git-utils';
// import UserSelector from './user-selector';
import LabelsEditorComponent from '../labels-editor/labels-editor';

const core = getCore();

interface Props extends WithTranslation {
    previewImage: string;
    taskInstance: any;
    installedGit: boolean; // change to git repos url
    // registeredUsers: any[];
    onTaskUpdate: (taskInstance: any) => void;
}

interface State {
    name: string;
    // bugTracker: string;
    // repository: string;
    // repositoryStatus: string;
}

class DetailsComponent extends React.PureComponent<Props & RouteComponentProps, State> {
    private mounted: boolean;
    private previewImageElement: HTMLImageElement;
    private previewWrapperRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props & RouteComponentProps) {
        super(props);

        const { taskInstance } = props;

        this.mounted = false;
        this.previewImageElement = new Image();
        this.previewWrapperRef = React.createRef<HTMLDivElement>();
        this.state = {
            name: taskInstance.name,
            // bugTracker: taskInstance.bugTracker,
            // repository: '',
            // repositoryStatus: '',
        };
    }

    public componentDidMount(): void {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { taskInstance, previewImage } = this.props;
        const { previewImageElement, previewWrapperRef } = this;
        this.mounted = true;

        previewImageElement.onload = () => {
            const { height, width } = previewImageElement;
            if (width > height) {
                previewImageElement.style.width = '100%';
            } else {
                previewImageElement.style.height = '100%';
            }
        };

        previewImageElement.src = previewImage;
        previewImageElement.alt = 'Preview';
        if (previewWrapperRef.current) {
            previewWrapperRef.current.appendChild(previewImageElement);
        }

        // getReposData(taskInstance.id)
        //     .then((data): void => {
        //         if (data !== null && this.mounted) {
        //             if (data.status.error) {
        //                 notification.error({
        //                     message: 'Could not receive repository status',
        //                     description: data.status.error,
        //                 });
        //             } else {
        //                 this.setState({
        //                     repositoryStatus: data.status.value,
        //                 });
        //             }

        //             this.setState({
        //                 repository: data.url,
        //             });
        //         }
        //     }).catch((error): void => {
        //         if (this.mounted) {
        //             notification.error({
        //                 message: 'Could not receive repository status',
        //                 description: error.toString(),
        //             });
        //         }
        //     });
    }

    public componentDidUpdate(prevProps: Props): void {
        const { taskInstance } = this.props;

        if (prevProps !== this.props) {
            this.setState({
                name: taskInstance.name,
                // bugTracker: taskInstance.bugTracker,
            });
        }
    }

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    private renderTaskName(): JSX.Element {
        const { name } = this.state;
        const {
            taskInstance,
            onTaskUpdate,
        } = this.props;

        if (taskInstance.jobs.length === 0) {
            return <Title level={4} className='cvat-text-color eyes-task-name'>{name}</Title>;
        }
        return (
            <Title
                level={4}
                editable={{
                    onChange: (value: string): void => {
                        this.setState({
                            name: value,
                        });

                        taskInstance.name = value;
                        onTaskUpdate(taskInstance);
                    },
                }}
                className='cvat-text-color eyes-task-name'
            >
                {name}
            </Title>
        );
    }

    private renderPreview(): JSX.Element {
        const { previewWrapperRef } = this;

        // Add image on mount after get its width and height to fit it into wrapper
        return (
            <div ref={previewWrapperRef} className='cvat-task-preview-wrapper' />
        );
    }

    private renderParameters(): JSX.Element {
        const { taskInstance } = this.props;
        const { overlap } = taskInstance;
        const { segmentSize } = taskInstance;
        const { imageQuality } = taskInstance;
        const zOrder = taskInstance.zOrder.toString();
        const { t } = this.props;

        return (
            <>
                <Row justify='start' align='middle'>
                    <Col span={12}>
                        <Text strong className='cvat-text-color'>{t('Overlap size')}</Text>
                        <br />
                        <Text className='cvat-text-color'>{overlap}</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong className='cvat-text-color'>{t('Segment size')}</Text>
                        <br />
                        <Text className='cvat-text-color'>{segmentSize}</Text>
                    </Col>
                </Row>
                <Row justify='space-between' align='middle'>
                    <Col span={12}>
                        <Text strong className='cvat-text-color'>{t('Image quality')}</Text>
                        <br />
                        <Text className='cvat-text-color'>{imageQuality}</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong className='cvat-text-color'>{t('Z-order')}</Text>
                        <br />
                        <Text className='cvat-text-color'>{zOrder}</Text>
                    </Col>
                </Row>
            </>
        );
    }

    private renderUsers(): JSX.Element {
        const {
            taskInstance,
        } = this.props;
        const { t } = this.props;
        const owner = taskInstance.owner ? taskInstance.owner.profile.nickname : null;
        const created = moment(taskInstance.createdDate).format(t('FORMAT_CREATED'));

        return (
            <Row justify='space-between' align='middle'>
                <Col span={22}>
                    { owner && (
                        <Text type='secondary'>
                            {t('Key-Created', { owner, created })}
                        </Text>
                    )}
                </Col>
            </Row>
        );
    }

    // private renderDatasetRepository(): JSX.Element | boolean {
    //     const { taskInstance } = this.props;
    //     const {
    //         repository,
    //         repositoryStatus,
    //     } = this.state;

    //     return (
    //         !!repository
    //             && (
    //                 <Row>
    //                     <Col className='cvat-dataset-repository-url'>
    //                         <Text strong className='cvat-text-color'>Dataset Repository</Text>
    //                         <br />
    //                         <a href={repository} rel='noopener noreferrer' target='_blank'>{repository}</a>
    //                         {repositoryStatus === 'sync'
    //                             && (
    //                                 <Tag color='blue'>
    //                                     <Icon type='check-circle' />
    //                                     Synchronized
    //                                 </Tag>
    //                             )}
    //                         {repositoryStatus === 'merged'
    //                             && (
    //                                 <Tag color='green'>
    //                                     <Icon type='check-circle' />
    //                                     Merged
    //                                 </Tag>
    //                             )}
    //                         {repositoryStatus === 'syncing'
    //                             && (
    //                                 <Tag color='purple'>
    //                                     <Icon type='loading' />
    //                                     Syncing
    //                                 </Tag>
    //                             )}
    //                         {repositoryStatus === '!sync'
    //                             && (
    //                                 <Tag
    //                                     color='red'
    //                                     onClick={(): void => {
    //                                         this.setState({
    //                                             repositoryStatus: 'syncing',
    //                                         });

    //                                         syncRepos(taskInstance.id).then((): void => {
    //                                             if (this.mounted) {
    //                                                 this.setState({
    //                                                     repositoryStatus: 'sync',
    //                                                 });
    //                                             }
    //                                         }).catch((error): void => {
    //                                             if (this.mounted) {
    //                                                 Modal.error({
    //                                                     width: 800,
    //                                                     title: 'Could not synchronize the repository',
    //                                                     content: error.toString(),
    //                                                 });

    //                                                 this.setState({
    //                                                     repositoryStatus: '!sync',
    //                                                 });
    //                                             }
    //                                         });
    //                                     }}
    //                                 >
    //                                     <Icon type='warning' />
    //                                     Synchronize
    //                                 </Tag>
    //                             )}
    //                     </Col>
    //                 </Row>
    //             )
    //     );
    // }

    private renderLabelsEditor(): JSX.Element {
        const {
            taskInstance,
            onTaskUpdate,
        } = this.props;

        if (taskInstance.jobs.length === 0) {
            return <></>;
        }
        return (
            <Row>
                <Col span={24}>
                    <LabelsEditorComponent
                        labels={taskInstance.labels.map(
                            (label: any): string => label.toJSON(),
                        )}
                        onSubmit={(labels: any[]): void => {
                            taskInstance.labels = labels
                                .map((labelData): any => new core.classes.Label(labelData));
                            onTaskUpdate(taskInstance);
                        }}
                    />
                </Col>
            </Row>
        );
    }

    public render(): JSX.Element {
        return (
            <div className='cvat-task-details'>
                <Row justify='space-between' align='top'>
                    <Col span={24} className='cvat-task-details-task-name'>{this.renderTaskName()}</Col>
                </Row>
                <Row justify='space-between' align='top'>
                    <Col md={8} lg={7} xl={7} xxl={6}>
                        <Row justify='start' align='middle'>
                            <Col span={24}>
                                { this.renderPreview() }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                { this.renderParameters() }
                            </Col>
                        </Row>
                    </Col>
                    <Col md={16} lg={17} xl={17} xxl={18}>
                        { this.renderUsers() }
                        { this.renderLabelsEditor() }
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(withTranslation()(DetailsComponent));
