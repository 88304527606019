// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Popover from 'antd/lib/popover';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

import { RotateIcon } from 'icons';
import { Rotation } from 'reducers/interfaces';
import { EyesTooltip as Tooltip } from 'utils/custom-antd';
import withVisibilityHandling from './handle-popover-visibility';

export interface Props {
    clockwiseShortcut: string;
    anticlockwiseShortcut: string;
    rotateFrame(rotation: Rotation): void;
}

const CustomPopover = withVisibilityHandling(Popover, 'rotate-canvas');
function RotateControl(props: Props): JSX.Element {
    const {
        anticlockwiseShortcut,
        clockwiseShortcut,
        rotateFrame,
    } = props;
    const { t } = useTranslation();

    return (
        <CustomPopover
            overlayClassName='cvat-rotate-canvas-controls'
            placement='right'
            content={(
                <>
                    <Tooltip title={t('Rotate the image anticlockwise {{anticlockwiseShortcut}}', { anticlockwiseShortcut })} placement='topRight'>
                        <Icon
                            className='cvat-rotate-canvas-controls-left'
                            onClick={(): void => rotateFrame(Rotation.ANTICLOCKWISE90)}
                            component={RotateIcon}
                            rev=''
                        />
                    </Tooltip>
                    <Tooltip title={t('Rotate the image clockwise {{clockwiseShortcut}}', { clockwiseShortcut })} placement='topRight'>
                        <Icon
                            className='cvat-rotate-canvas-controls-right'
                            onClick={(): void => rotateFrame(Rotation.CLOCKWISE90)}
                            component={RotateIcon}
                            rev=''
                        />
                    </Tooltip>
                </>
            )}
            trigger='hover'
        >
            <Icon className='cvat-rotate-canvas-control' component={RotateIcon} rev='' />
        </CustomPopover>
    );
}

export default React.memo(RotateControl);
