// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Upload from 'antd/lib/upload';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';

interface Props {
    menuKey: string;
    loaders: string[];
    loadActivity: string | null;
    inferenceIsActive: boolean;
    isTraining: boolean;
    jobsLength: number;
    onFileUpload(file: File): void;
}

export default function LoadSubmenu(props: Props): ItemType[] {
    const {
        menuKey,
        loaders,
        loadActivity,
        inferenceIsActive,
        isTraining,
        jobsLength,
        onFileUpload,
    } = props;

    const { t } = useTranslation();

    const menus: ItemType[] = [
        {
            key: menuKey,
            label: t('Upload annotations'),
            disabled: jobsLength === 0,
            children: loaders.map((_loader: string) => {
                const [loader, accept] = _loader.split('::');
                const pending = loadActivity === loader;
                return {
                    key: `${loader}::${menuKey}`,
                    disabled: isTraining || !!loadActivity || inferenceIsActive,
                    className: 'cvat-menu-load-submenu-item',
                    label: (
                        <>
                            <Upload
                                accept={accept}
                                multiple={false}
                                showUploadList={false}
                                beforeUpload={(file: File): boolean => {
                                    onFileUpload(file);
                                    return false;
                                }}
                            >
                                <Button
                                    block
                                    type='link'
                                    disabled={isTraining || !!loadActivity || inferenceIsActive}
                                    className='cvat-menu-load-submenu-item-button'
                                    icon={<UploadOutlined rev='' />}
                                >
                                    <Text>{loader}</Text>
                                    {pending && <LoadingOutlined style={{ marginLeft: 10 }} rev='' />}
                                </Button>
                            </Upload>
                        </>
                    ),
                };
            }),
        },
    ];
    return menus;
}
