
import React from 'react';
import { connect } from 'react-redux';

import {
    Model,
    ModelsQuery,
} from 'reducers/interfaces';

import { getModelsAsync } from 'actions/models-actions';

import TrainedModelsListComponent from 'components/models-page/trained-models-list';

interface DispatchToProps {
    getModels: (query: ModelsQuery) => void;
}

interface OwnProps {
    models: Model[];
    pageSize: number;
    currentPage: number;
    numberOfModels: number;
    onSwitchPage: (page: number) => void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        getModels: (query: ModelsQuery): void => {
            dispatch(getModelsAsync(query));
        },
    };
}

type TrainedModelsListContainerProps = DispatchToProps & OwnProps;

function TrainedModelsListContainer(props: TrainedModelsListContainerProps): JSX.Element {
    return (
        <TrainedModelsListComponent {...props} />
    );
}

export default connect(
    mapDispatchToProps,
)(TrainedModelsListContainer);
