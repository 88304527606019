// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DatabaseOutlined,
    LineChartOutlined,
    RobotOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';

// const SubMenu = Menu.SubMenu;

const limitCollapseWindowsSize = 1200;

export interface SiderContainerProps extends WithTranslation {
    onLogout: () => void;
    logoutFetching: boolean;
    isStaff: boolean;
    serverHost: string;
    username: string;
    toolName: string;
    serverVersion: string;
    serverDescription: string;
    coreVersion: string;
    canvasVersion: string;
    uiVersion: string;
    switchSettingsShortcut: string;
}

interface State {
    collapsed: boolean;
}

class SiderContainer extends React.PureComponent<SiderContainerProps & RouteComponentProps, State> {
    public constructor(props: SiderContainerProps & RouteComponentProps) {
        super(props);
        this.state = {
            collapsed: window.innerWidth < limitCollapseWindowsSize,
        };
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = (): void => {
        const { collapsed } = this.state;
        if (!collapsed && window.innerWidth < limitCollapseWindowsSize) {
            this.setState({
                collapsed: true,
            });
        }
    };

    toggleCollapsed = ():void => {
        const { collapsed } = this.state;
        this.setState({
            collapsed: !collapsed,
        });
    };

    public aboutModal(): void {
        const {
            toolName,
            // serverHost,
            serverVersion,
            serverDescription,
            coreVersion,
            canvasVersion,
            uiVersion,
        } = this.props;

        Modal.info({
            title: `${toolName}`,
            content: (
                <div>
                    <p>
                        {`${serverDescription}`}
                    </p>
                    <p>
                        <Text strong>
                            Server version:
                        </Text>
                        <Text type='secondary'>
                            {` ${serverVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Core version:
                        </Text>
                        <Text type='secondary'>
                            {` ${coreVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Canvas version:
                        </Text>
                        <Text type='secondary'>
                            {` ${canvasVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            UI version:
                        </Text>
                        <Text type='secondary'>
                            {` ${uiVersion}`}
                        </Text>
                    </p>
                    <Row justify='space-around' />
                </div>
            ),
            width: 800,
            okButtonProps: {
                style: {
                    width: '100px',
                },
            },
        });
    }

    public render(): JSX.Element {
        const {
            // onLogout,
            // logoutFetching,
            isStaff,
            // serverHost,
            // switchSettingsShortcut,
            history,
            location,
            t,
        } = this.props;
        const { collapsed } = this.state;
        let selectedKey = 'tasks';
        const { pathname } = location;
        // const useragreementUrl = `${serverHost}/api/v1/restrictions/terms-of-use`;
        if (pathname.indexOf('/tasks') === 0) {
            selectedKey = 'tasks';
        } else if (pathname.indexOf('/models') === 0) {
            selectedKey = 'models';
        } else if (isStaff && pathname.indexOf('/user_manage') === 0) {
            selectedKey = 'admin';
        } else {
            selectedKey = '';
        }

        const items: MenuProps['items'] = [
            {
                key: 'tasks',
                label: (
                    <span>{t('Tasks')}</span>
                ),
                className: 'cvat-sider-button',
                onClick: (): void => {
                    history.push('/tasks?page=1');
                },
                icon: <DatabaseOutlined rev='' />,
            },
            {
                key: 'models',
                label: (
                    <span>{t('Models')}</span>
                ),
                className: 'cvat-sider-button',
                onClick: (): void => {
                    history.push('/models');
                },
                icon: <LineChartOutlined rev='' />,
            },
        ];
        if (isStaff) {
            items.push(
                {
                    key: 'admin',
                    label: (
                        <span>{t('Admin')}</span>
                    ),
                    className: 'cvat-sider-button',
                    onClick: (): void => {
                        history.push('/user_manage');
                    },
                    icon: <RobotOutlined rev='' />,
                },
            );
        }
        items.push(
            {
                style: { marginTop: 20 },
                key: 'help',
                label: (
                    <span>{t('Help')}</span>
                ),
                className: 'cvat-sider-button',
                onClick: (): void => {
                    // false positive
                    // eslint-disable-next-line
                    window.open(`https://humanome.jp/activities/eyes/beginner/`, '_blank');
                    // history.push('/tasks');
                },
                icon: <QuestionCircleOutlined rev='' />,
            },
        );

        return (
            <Layout.Sider
                className='cvat-sider'
                trigger={null}
                collapsible
                collapsed={collapsed}
                collapsedWidth='70px'
            >
                <div>
                    <Button className='cvat-header-collapsed' type='primary' onClick={this.toggleCollapsed}>
                        {collapsed ? <MenuUnfoldOutlined rev='' /> : <MenuFoldOutlined rev='' />}
                    </Button>
                    <Menu
                        style={{ marginTop: 15 }}
                        mode='inline'
                        selectedKeys={[selectedKey]}
                        className='cvat-sider'
                        items={items}
                    />
                </div>
                <div className='cvat-bottom-sider'>
                    <ul className='cvat-bottom-sider-ul'>
                        <li className='cvat-bottom-sider-li'><span><a href='https://humanome.jp/contact/eyes-operation/' target='_blank' rel='noopener noreferrer'>{t('Feedback')}</a></span></li>
                        <li className='cvat-bottom-sider-li'><span><a href='https://humanome.jp/activities/eyes/agreement/' target='_blank' rel='noopener noreferrer'>{t('Terms of Use')}</a></span></li>
                        <li className='cvat-bottom-sider-li'><span><a href='https://humanome.jp/company/privacy_policy/' target='_blank' rel='noopener noreferrer'>{t('Privacy Policy')}</a></span></li>
                        <li className='cvat-bottom-sider-li'><span><a href='https://humanome.jp/activities/specific-commercial/' target='_blank' rel='noopener noreferrer'>{t('Specified Commercial Transaction Act')}</a></span></li>
                        <li className='cvat-bottom-sider-li'><span><a href='https://humanome.jp/company/external-transmission/' target='_blank' rel='noopener noreferrer'>{t('External Transmission of User Information')}</a></span></li>
                        <li className='cvat-bottom-sider-li'><span><a href='https://humanome.jp/' target='_blank' rel='noopener noreferrer'>Humanome Lab., Inc.</a></span></li>
                        <li className='cvat-bottom-sider-li'> © 2020-2022</li>
                    </ul>
                </div>
            </Layout.Sider>
        );
    }
}

export default withRouter(withTranslation()(SiderContainer));
