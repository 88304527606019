import { ModelStatus } from 'reducers/interfaces';

export default function getStatusColor(status: ModelStatus): string {
    switch (status) {
        case ModelStatus.PREPARING:
            return 'magenta';
        case ModelStatus.QUEUED:
            return 'orange';
        case ModelStatus.PRETRAINING:
            return 'orange';
        case ModelStatus.TRAINING:
            return 'green';
        case ModelStatus.POSTTRAINING:
            return 'green';
        case ModelStatus.STOPPING:
            return 'blue';
        case ModelStatus.STOPPED:
            return 'geekblue';
        case ModelStatus.FINISHED:
            return 'purple';
        case ModelStatus.FAILED:
            return 'red';
        case ModelStatus.DELETED:
            return '';
        case ModelStatus.NOT_FOUND:
            return '';
        case ModelStatus.UNKNOWN:
            return '';
        default:
            return '';
    }
}
