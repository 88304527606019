// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Tabs from 'antd/lib/tabs';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import type { Tab } from 'rc-tabs/lib/interface';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    UserOutlined,
    PlayCircleOutlined,
    LaptopOutlined,
    PayCircleOutlined,
} from '@ant-design/icons';
import { RouteComponentProps } from 'react-router';

import WorkspaceSettingsContainer from 'containers/settings-page/workspace-settings';
import PlayerSettingsContainer from 'containers/settings-page/player-settings';
import ProfileSettingsContainer from 'containers/settings-page/profile-settings';
import UpgradeContainer from 'containers/settings-page/upgrade';

function SettingsPage(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('settingsPage');

    const items: Tab[] = [
        {
            key: 'profile',
            label: <span className='settings-user'>
                <UserOutlined rev='' />
                <Text>{t('Profile')}</Text>
            </span>,
            children: <ProfileSettingsContainer />,
        },
        {
            key: 'player',
            label: <span className='settings-player'>
                <PlayCircleOutlined rev='' />
                <Text>{t('Player')}</Text>
            </span>,
            children: <PlayerSettingsContainer />,
        },
        {
            key: 'workspace',
            label: <span className='settings-workspace'>
                <LaptopOutlined rev='' />
                <Text>{t('Workspace')}</Text>
            </span>,
            children: <WorkspaceSettingsContainer />,
        },
        {
            key: 'upgrade',
            label: <span className='settings-upgrade'>
                <PayCircleOutlined rev='' />
                <Text>{t('Upgrade plan')}</Text>
            </span>,
            children: <UpgradeContainer />,
        },
    ];
    return (
        <div className='cvat-settings-page'>
            <Row justify='center'>
                <Col>
                    <Text className='cvat-title'>
                        {t('Settings')}
                    </Text>
                </Col>
            </Row>
            <Row justify='center'>
                <Col md={22} lg={22} xl={18} xxl={18}>
                    <Tabs
                        type='card'
                        tabBarStyle={{ marginBottom: '0px', marginLeft: '-1px' }}
                        items={items}
                    />
                </Col>
            </Row>
            <Row justify='center'>
                <Col md={14} lg={12} xl={10} xxl={9} className='cvat-settings-page-back-button-wrapper'>
                    <Button
                        className='cvat-settings-page-back-button'
                        type='primary'
                        onClick={(): void => {
                            props.history.goBack();
                        }}
                    >
                        {t('Go Back')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default SettingsPage;
