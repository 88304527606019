// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
// import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';
import { EyesLogo } from 'icons';

import { UserAgreement } from 'reducers/interfaces';
// import CookieDrawer from 'components/login-page/cookie-policy-drawer';
import RegisterForm, { RegisterData, UserConfirmation } from './register-form';

interface RegisterPageComponentProps {
    fetching: boolean;
    userAgreements: UserAgreement[];
    onRegister: (username: string, firstName: string,
        lastName: string, email: string,
        organization: string, address: string, // Humanome Eyes
        // phone: string, // Humanome Eyes
        lang: string, purpose: string, // Humanome Eyes
        password1: string, password2: string,
        confirmations: UserConfirmation[]) => void;
}

function RegisterPageComponent(
    props: RegisterPageComponentProps & RouteComponentProps,
): JSX.Element {
    const sizes = {
        xs: { span: 16 },
        sm: { span: 12 },
        md: { span: 10 },
        lg: { span: 8 },
        xl: { span: 8 },
    };

    const {
        fetching,
        userAgreements,
        onRegister,
    } = props;
    const { t } = useTranslation();

    const initialData: RegisterData = {
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        address: '',
        lang: '',
        purpose: '',
        oldPassword: '',
        password1: '',
        password2: '',
        confirmations: [],
    };

    return (
        <div className='cvat-register-page'>
            <Row style={{ marginTop: 10 }} justify='center' align='middle'>
                <Col {...sizes}>
                    <Row>
                        <Icon className='cvat-login-logo-icon' component={EyesLogo} rev='' />
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Text strong>
                            {t('Create an account')}
                        </Text>
                    </Row>
                    <RegisterForm
                        fetching={fetching}
                        userAgreements={userAgreements}
                        onSubmit={(registerData: RegisterData): void => {
                            onRegister(
                                registerData.userName,
                                registerData.firstName,
                                registerData.lastName,
                                registerData.email,
                                registerData.organization, // Humanome Eyes
                                registerData.address, // Humanome Eyes
                                // registerData.phoneNumber, // Humanome Eyes
                                registerData.lang, // Humanome Eyes
                                registerData.purpose, // Humanome Eyes
                                registerData.password1,
                                registerData.password2,
                                registerData.confirmations,
                            );
                        }}
                        initialData={initialData}
                        passwordRequired
                    />
                    <Row justify='start' align='top'>
                        <Col>
                            <Text strong>
                                {t('Already have an account?')}
                                <Link to='/auth/login'>
                                    &nbsp;
                                    {t('Login')}
                                </Link>
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }} justify='start' align='top'>
                        <Col>
                            <Text>
                                <a
                                    href='https://humanome.jp/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Humanome Lab., Inc.
                                </a>
                                &nbsp;© 2020
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <CookieDrawer /> */}
        </div>
    );
}

export default withRouter(RegisterPageComponent);
