// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import thunk from 'redux-thunk';
import {
    createStore,
    applyMiddleware,
    Store,
    Reducer,
} from 'redux';
import { createLogger } from 'redux-logger';
import watch from 'redux-watch';
import { isDev } from 'utils/enviroment';
import { changeLanguage } from 'i18n';

const logger = createLogger({
    predicate: isDev,
    collapsed: true,
});

const middlewares = [
    thunk,
    logger,
];

let store: Store | null = null;

export default function createCVATStore(createRootReducer: () => Reducer): void {
    let appliedMiddlewares = applyMiddleware(...middlewares);

    if (isDev()) {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires, import/no-extraneous-dependencies
        const { composeWithDevTools } = require('@redux-devtools/extension');

        appliedMiddlewares = composeWithDevTools(appliedMiddlewares);
    }

    store = createStore(
        createRootReducer(),
        appliedMiddlewares,
    );

    const w = watch(store.getState, 'auth.user');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    store.subscribe(w((newVal: any, oldVal: any, objectPath: any) => {
        // console.debug('%s changed from %s to %s', objectPath, oldVal, newVal)
        let lang = 'en';
        if (oldVal && oldVal.profile) {
            lang = oldVal.profile.lang;
        }
        if (newVal && newVal.profile) {
            lang = newVal.profile.lang == null ? 'en' : newVal.profile.lang;
        }
        changeLanguage(lang);
    }));
}

export function getCVATStore(): Store {
    if (store) {
        return store;
    }

    throw new Error('First create a store');
}
