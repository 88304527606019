// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import moment from 'moment';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    FileOutlined,
    EditOutlined,
    ClockCircleOutlined,
    UserOutlined,
} from '@ant-design/icons';

import { ActiveInference } from 'reducers/interfaces';
import { NoImageImage } from 'icons';

export interface TaskItemProps extends WithTranslation {
    taskInstance: any;
    previewImage: string;
    deleted: boolean;
    hidden: boolean;
    activeInference: ActiveInference | null;
    cancelAutoAnnotation(): void;
}

class TaskItemComponent extends React.PureComponent<TaskItemProps & RouteComponentProps> {
    private renderPreview(): JSX.Element {
        const { previewImage } = this.props;
        return (
            <Col span={4}>
                <div className='cvat-task-item-preview-wrapper'>
                    <img alt='Preview' className='cvat-task-item-preview' src={previewImage || NoImageImage} />
                </div>
            </Col>
        );
    }

    private renderDescription(): JSX.Element {
        // Task info
        const { taskInstance } = this.props;
        const { t } = this.props;
        const { id, size, mode } = taskInstance;
        const owner = taskInstance.owner ? taskInstance.owner.profile.nickname : null;
        const updated = moment(taskInstance.updatedDate).fromNow();
        // const created = moment(taskInstance.createdDate).format('LL');
        const created = moment(taskInstance.createdDate).format(t('FORMAT_CREATED'));

        // Get and truncate a task name
        const name = `${taskInstance.name.substring(0, 70)}${taskInstance.name.length > 70 ? '...' : ''}`;

        let sizeText = 'No Data';
        if (size) {
            sizeText = (mode === 'interpolation') ? t('# of Frames: {{size}}', { size }) : t('# of Images: {{size}}', { size });
        }

        return (
            <>
                <Col span={20} className='cvat-task-item-description'>
                    <Row>
                        <Text strong type='secondary' className='cvat-item-task-id'>{`#${id}: `}</Text>
                        <Text strong className='cvat-item-task-name'>{name}</Text>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <FileOutlined className='cvat-task-item-icon' rev='' />
                            <Text type='secondary'>
                                {sizeText}
                            </Text>
                        </Col>
                        <Col span={16}>
                            <Row>
                                <EditOutlined className='cvat-task-item-icon' rev='' />
                                <span><Text type='secondary'>{t('Key-Lastupdated', { updated })}</Text></span>
                            </Row>
                            <Row>
                                <ClockCircleOutlined className='cvat-task-item-icon' rev='' />
                                <Text type='secondary'>{t('Key-Created', { created })}</Text>
                            </Row>
                            <Row>
                                <UserOutlined className='cvat-task-item-icon' rev='' />
                                <Text type='secondary'>{t('Key-Owner', { owner })}</Text>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </>
        );
    }

    private renderNavigation(): JSX.Element {
        const {
            taskInstance,
            history,
        } = this.props;
        const { id } = taskInstance;
        const { t } = this.props;

        return (
            <Col span={4}>
                <Row justify='end'>
                    <Col>
                        <Button
                            className='cvat-item-open-task-button'
                            type='primary'
                            size='large'
                            ghost
                            href={`/tasks/${id}`}
                            onClick={(e: React.MouseEvent): void => {
                                e.preventDefault();
                                history.push(`/tasks/${id}`);
                            }}
                        >
                            {t('Open')}
                        </Button>
                    </Col>
                </Row>
            </Col>
        );
    }

    public render(): JSX.Element {
        const {
            deleted,
            hidden,
            taskInstance,
            history,
        } = this.props;
        const { id } = taskInstance;
        const style = {};
        if (deleted) {
            (style as any).pointerEvents = 'none';
            (style as any).opacity = 0.5;
        }

        if (hidden) {
            (style as any).display = 'none';
            return (<></>);
        }

        return (
            <Row
                className='cvat-tasks-list-item'
                justify='center'
                align='top'
                style={{ ...style }}
                onClick={(e: React.MouseEvent): void => {
                    e.preventDefault();
                    history.push(`/tasks/${id}`);
                }}
            >
                {this.renderPreview()}
                {this.renderDescription()}
            </Row>
        );
    }
}

export default withRouter(withTranslation()(TaskItemComponent));
