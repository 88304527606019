import React from 'react';

import { Col, Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Spin from 'antd/lib/spin';
import Alert from 'antd/lib/alert';

import { withTranslation, WithTranslation } from 'react-i18next';

import getCore from 'cvat-core-wrapper';

import { Model, ModelStatus, JobStatus } from 'reducers/interfaces';
import PredictionExampleItemComponent from './prediction-example-item';

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

interface Props extends WithTranslation {
    model: Model;
    jobStatus: JobStatus | null;
}

interface State {
    indexList: string[];
    lastUpdate: string;
    updating: boolean;
    imageData: {
        [index: string]: {
            prediction: string;
            groundTruth: string;
        };
    };
}

const defaultState = {
    indexList: [],
    lastUpdate: '',
    updating: false,
    imageData: {},
};

const modelStoppedStatus = [
    ModelStatus.FINISHED,
    ModelStatus.FAILED,
    ModelStatus.STOPPED,
    ModelStatus.NOT_FOUND,
    ModelStatus.UNKNOWN,
];

class PredictionExamplesComponent extends React.Component<Props, State> {
    preview_image_url: any = null;
    timer: any = null;
    updating = false;

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
    }

    public componentDidMount(): void {
        const { model, jobStatus } = this.props;
        if (modelStoppedStatus.includes(model.status)) {
            this.getImageGenTime();
        } else if (model.status === ModelStatus.TRAINING &&
            jobStatus && jobStatus.has_checkpoint) {
            this.getImageGenTime();
            if (this.timer == null) {
                this.timer = setInterval(() => this.getImageGenTime(), 10000);
            }
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const { model, jobStatus } = this.props;
            if (prevProps.model.id !== model.id) {
                this.setState({
                    indexList: [],
                    updating: true,
                    imageData: {},
                });
                this.loadResource();
            }
            if (this.timer == null &&
                model.status === ModelStatus.TRAINING &&
                jobStatus && jobStatus.has_checkpoint) {
                this.getImageGenTime();
                this.timer = setInterval(() => this.getImageGenTime(), 10000);
            }
            if (prevProps.model.status !== model.status && modelStoppedStatus.includes(model.status)) {
                this.getImageGenTime();
            }
            if (this.timer != null && modelStoppedStatus.includes(model.status)) {
                clearInterval(this.timer);
            }
        }
    }

    public componentWillUnmount(): void {
        if (this.timer != null) clearInterval(this.timer);
    }

    private async getImageGenTime(): Promise<void> {
        const { model: { id: mid } } = this.props;
        const { lastUpdate } = this.state;
        const response = await core.server.request(
            `${baseURL}/tensorflow/train/models/${mid}/prediction_image_time/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        if (response.last_update) {
            if (lastUpdate !== response.last_update) {
                this.setState({ updating: true, lastUpdate: response.last_update });
                this.loadResource();
            } else {
                this.setState({ updating: false });
            }
        }
    }

    private async getImage(index: string): Promise<void> {
        const { model: { id: mid } } = this.props;
        const ab = await core.server.request(
            `${baseURL}/tensorflow/train/models/${mid}/prediction_image/${index}`, {
                method: 'GET',
                responseType: 'blob',
            },
        );
        const blob = new Blob([ab], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        const image = new Image();
        const dataURLs: { prediction: string; groundTruth: string } = { prediction: '', groundTruth: '' };
        image.src = url;
        image.onload = () => {
            const { height } = image;
            const width = Math.floor(image.width / 2);

            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const context = canvas.getContext('2d');
            if (context === null) return;
            context.drawImage(image, 0, 0, width, height, 0, 0, width, height);
            dataURLs.prediction = canvas.toDataURL();
            context.drawImage(image, width, 0, width, height, 0, 0, width, height);
            dataURLs.groundTruth = canvas.toDataURL();
            const { imageData } = this.state;
            this.setState({ imageData: { ...imageData, [index]: dataURLs } });
        };
    }

    private async loadResource(): Promise<void> {
        const { model: { id: mid } } = this.props;
        const { index_list: indexList }: { index_list: string[] } = await core.server.request(
            `${baseURL}/tensorflow/train/models/${mid}/predictions`, {
                method: 'GET',
            },
        );
        this.setState({ indexList });
        await Promise.all(indexList.map((index) => this.getImage(index)));
        this.setState({ updating: false });
    }

    private renderItems(): JSX.Element {
        const { model, t } = this.props;
        const { indexList, updating, imageData } = this.state;

        if (updating && Object.keys(imageData).length === 0) {
            return (<Spin size='large' />);
        }
        if (indexList.length > 0) {
            const predictionExampleItemViews = indexList.reduce(
                (acc: JSX.Element[], index): JSX.Element[] => {
                    if (index in imageData) {
                        acc.push(
                            (
                                <PredictionExampleItemComponent
                                    key={index}
                                    index={index}
                                    images={imageData[index]}
                                />
                            ),
                        );
                    }
                    return acc;
                }, [],
            );
            return (
                <div>
                    {predictionExampleItemViews}
                </div>
            );
        }
        if (model === null) {
            return (<Spin size='large' />);
        }
        if ([ModelStatus.PREPARING, ModelStatus.QUEUED, ModelStatus.PRETRAINING].includes(model.status)) {
            return (
                <Alert
                    message={t('Preparing for Training')}
                    description={t('Prediction example images will be displayed after a while.')}
                    type='info'
                    showIcon
                />
            );
        }
        if (model.status === ModelStatus.TRAINING) {
            return (
                <Alert
                    message={t('On Training')}
                    description={t('Prediction example images will be displayed after a while.')}
                    type='info'
                    showIcon
                />
            );
        }
        if ([ModelStatus.FINISHED, ModelStatus.POSTTRAINING].includes(model.status)) {
            return (
                <Alert
                    message={t('Training finished')}
                    description={t('Prediction example images were not created.')}
                    type='info'
                    showIcon
                />
            );
        }
        return (
            <Alert
                message={t('Training are suspended')}
                description={t('Prediction example images were not created.')}
                type='info'
                showIcon
            />
        );
    }

    public render(): JSX.Element {
        const { t } = this.props;

        return (
            <div className='cvat-training-model'>
                <Row>
                    <Col>
                        <Text className='cvat-text-color cvat-jobs-header'>
                            {t('Prediction Examples')}
                        </Text>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col span={24}>
                        {this.renderItems()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(PredictionExamplesComponent);
