import React from 'react';
import { useTranslation } from 'react-i18next';

import Paragraph from 'antd/lib/typography/Paragraph';
import {
    Row, Col, Modal, Button,
} from 'antd';
import { UserAgreement, ProfileData } from 'reducers/interfaces';
import VerificationModalContainer from 'containers/settings-page/verification-modal';
import UpdateProfileForm from './update-form';

interface Props {
    fetching: boolean;
    user: any;
    userAgreements: UserAgreement[];
    onSubmit(data: ProfileData): void;
    onEmailSubmit(oldEmail: string, newEmail: string): void;
    onDeactivateAccount(): void;
}

function renderDactivateAccountButton(props: Props): JSX.Element {
    const { onDeactivateAccount } = props;
    const { t } = useTranslation();
    const messageJP = (
        <Paragraph>
            OKをクリックするとアカウントが非有効化され、ログインすることができなくなります。
            お客様の情報は一定期間経過後に削除されます。
            アカウント削除の取り消しを希望される方は、<a href='https://humanome.jp/contact/eyes-operation/'>お問い合わせ窓口</a>より、連絡をお願い致します。
        </Paragraph>
    );
    const messageENG = (
        <Paragraph>
            Clicking OK will deactivate your account and you will not be able to log in.
            Your information will be deleted after a certain period of time.
            If you would like to cancel your account deletion, please contact us from <a href='https://humanome.jp/contact/eyes-operation/'> Inquiry Desk </a>.
        </Paragraph>
    );
    return (
        <Row justify='center'>
            <Col>
                <Button
                    danger
                    ghost
                    onClick={(): void => {
                        Modal.confirm({
                            title: t('Delete your account'),
                            content: (
                                <>
                                    {messageJP}
                                    {messageENG}
                                </>
                            ),
                            okType: 'danger',
                            okText: t('Delete Account'),
                            cancelText: t('Cancel'),
                            centered: true,
                            autoFocusButton: 'cancel',
                            onOk: () => onDeactivateAccount(),
                        });
                    }}
                >
                    {t('Delete your account')}
                </Button>
            </Col>
        </Row>
    );
}

export default function ProfileSettingsComponent(props: Props): JSX.Element {
    const {
        user,
        userAgreements,
        onSubmit,
        onEmailSubmit,
        fetching,
    } = props;

    return (
        <>
            <div className='cvat-profile-settings'>
                <UpdateProfileForm
                    fetching={fetching}
                    userAgreements={userAgreements}
                    onSubmit={onSubmit}
                    onEmailSubmit={onEmailSubmit}
                    initialData={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        userName: user.profile.nickname,
                        email: user.email,
                        organization: user.profile.organization,
                        address: user.profile.address,
                        // phoneNumber: user.profile.phone
                        lang: user.profile.lang,
                        purpose: user.profile.purpose,
                        oldPassword: null,
                        password1: null,
                        password2: null,
                    }}
                    passwordRequired={false}
                />
                {renderDactivateAccountButton(props)}
            </div>
            <VerificationModalContainer />
        </>
    );
}
