// Copyright (C) 2020 Humanome Lab.

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Result from 'antd/lib/result';

import { Col, Row } from 'antd/lib/grid';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';

import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';
import type { MenuProps } from 'antd';

import { Model, ModelStatus } from 'reducers/interfaces';

import DetailsContainer from 'containers/trained-model-page/details';
import HyperParametersContainer from 'containers/trained-model-page/hyper-parameters';
import LossAndAccuracyContainer from 'containers/trained-model-page/loss-and-accuracy';
import PredictionExamplesContainer from 'containers/trained-model-page/prediction-examples';
import DetectObjectTestContainer from 'containers/trained-model-page/detect-object-test';
import { MenuIcon } from '../../icons';

interface TrainedModelPageProps extends WithTranslation {
    model: Model | null | undefined;
    fetching: boolean;
    auth: boolean;
    isExportable: boolean;
    exporting: boolean;
    cancels: {
        [tid: number]: boolean;
    },
    getModel: () => void;
    deleteTrainedModel(id: number): void;
    cancelTrainingModel(id: number): void;
    checkJobsStatus: (tids: number[], timeout: number) => void;
    exportModel(model: Model): void;
}

type Props = TrainedModelPageProps & RouteComponentProps<{ mid: string }>;

class TrainedModelPageComponent extends React.Component<Props> {
    public componentDidUpdate(prevProps: Props): void {
        const { t } = this.props;
        if (prevProps !== this.props) {
            const { model, checkJobsStatus, exporting } = this.props;
            if (prevProps.model === null && model && model.task_id) {
                checkJobsStatus([model.task_id], 0);
            }
            this.forceUpdate();
            if (!exporting && prevProps.exporting) {
                notification.close('export model');
            }
            if (exporting && !prevProps.exporting) {
                notification.info({
                    key: 'export model',
                    message: t('Preparing to export the Trained Model'),
                    duration: null,
                });
            }
        }
    }

    public componentWillUnmount(): void {
        notification.close('export model');
    }

    private renderMenu(): JSX.Element {
        const {
            model,
            deleteTrainedModel,
            cancelTrainingModel,
            exportModel,
            history,
            isExportable,
            cancels,
        } = this.props;
        const { t } = this.props;
        const runningStatuses = [
            ModelStatus.PREPARING,
            ModelStatus.QUEUED,
            ModelStatus.PRETRAINING,
            ModelStatus.TRAINING,
            ModelStatus.POSTTRAINING,
            ModelStatus.STOPPING,
        ];

        if (model == null || model.id >= 2 ** 32) return (<></>);
        if (model.task_id === null && runningStatuses.includes(model.status)) return (<></>);

        const menus: MenuProps = {
            rootClassName: 'cvat-task-item-menu',
        };
        if (runningStatuses.includes(model.status)) {
            menus.items = [
                {
                    key: 'cancel',
                    label: t('Cancel'),
                    onClick: (): void => {
                        cancelTrainingModel(Number(model.task_id));
                        // history.goBack();
                    },
                    disabled: (model.task_id && model.task_id in cancels) || model.status === ModelStatus.STOPPING,
                },
            ];
        } else if (isExportable && model.status === ModelStatus.FINISHED) {
            menus.items = [
                {
                    key: 'export',
                    label: t('Export Trained Model data'),
                    onClick: (): void => {
                        exportModel(model);
                    },
                },
                {
                    type: 'divider',
                },
                {
                    key: 'delete',
                    label: t('Delete'),
                    onClick: (): void => {
                        deleteTrainedModel(model.id);
                        history.goBack();
                    },
                    disabled: ![
                        ModelStatus.STOPPED,
                        ModelStatus.FAILED,
                        ModelStatus.FINISHED,
                    ].includes(model.status),
                },
            ];
        } else {
            menus.items = [
                {
                    key: 'delete',
                    label: t('Delete'),
                    onClick: (): void => {
                        deleteTrainedModel(model.id);
                        history.goBack();
                    },
                    disabled: ![
                        ModelStatus.STOPPED,
                        ModelStatus.FAILED,
                        ModelStatus.FINISHED,
                    ].includes(model.status),
                },
            ];
        }
        return (
            <Dropdown menu={menus} trigger={['hover', 'click']}>
                <Button size='large'>
                    <Text className='cvat-text-color'>{t('Actions')}</Text>
                    <Icon className='cvat-menu-icon' component={MenuIcon} rev='' />
                </Button>
            </Dropdown>
        );
    }

    public render(): JSX.Element {
        const {
            model,
            fetching,
            auth,
            getModel,
        } = this.props;
        const { t } = this.props;

        if (!auth) {
            return <></>;
        }

        if (model === null) {
            if (!fetching) {
                getModel();
            }
            return (
                <Spin size='large' className='cvat-spinner' />
            );
        }

        if (typeof (model) === 'undefined') {
            return (
                <Result
                    className='cvat-not-found'
                    status='404'
                    title={t('Sorry, but this model was not found')}
                    subTitle={t('Please, be sure information you tried to get exist and you have access')}
                />
            );
        }

        return (
            <>
                <Row justify='center' align='top' className='cvat-task-details-wrapper'>
                    <Col md={22} lg={18} xl={16} xxl={14}>
                        <Row className='cvat-task-top-bar' justify='space-between' align='middle'>
                            <Col>
                                <Text className='cvat-title'>{t('Model Detail Page')}</Text>
                            </Col>
                            <Col>
                                {this.renderMenu()}
                            </Col>
                        </Row>
                        <DetailsContainer model={(model as Model)} />
                        {(model.status === ModelStatus.FINISHED || model.id >= 2 ** 32) &&
                            <DetectObjectTestContainer model={(model as Model)} />}
                        {(model !== null && model.id < 2 ** 32) &&
                            (
                                <>
                                    <LossAndAccuracyContainer model={(model as Model)} />
                                    <PredictionExamplesContainer model={(model as Model)} />
                                    <HyperParametersContainer model={(model as Model)} />
                                </>
                            )}
                    </Col>
                </Row>
            </>
        );
    }
}

export default withRouter(withTranslation()(TrainedModelPageComponent));
