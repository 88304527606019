// Copyright (C) 2020 Humanome Lab., Inc.
//

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Spin, Switch, Row, Col, Button, Select } from 'antd';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import EditableFormTable from './users-table';

interface Props extends WithTranslation {
    fetching: boolean;
    users: any[];
    getUsers: () => void;
    activateUser: (cognitoId: string) => void;
    deactivateUser: (cognitoId: string) => void;
    updateUser: (username: string, attributes: any[]) => void;
    updateUsers: (userinfos: any[]) => void;
}

interface State {
    pagination: number;
    selectedRowKeys: string[] | number[];
    showBatchChange: boolean;
    selectdBatchPlan: string;
}

const defaultState = {
    pagination: 20,
    selectedRowKeys: [],
    showBatchChange: false,
    selectdBatchPlan: '',
};

class AdminPageComponent extends React.Component<Props & RouteComponentProps, State> {
    constructor(props: Props & RouteComponentProps) {
        super(props);
        this.state = defaultState;
    }
    
    public componentDidMount(): void {
        const { getUsers } = this.props;
        getUsers();
    }

    onPaginationChange = (pagination: string): void => {
        this.setState({ pagination: Number(pagination) });
    };

    switchBatchChange = (showBatchChange: boolean): void => {
        this.setState({ showBatchChange });
        if (showBatchChange === false) {
            this.setState({ selectedRowKeys: [] });
        }
    };

    onBatchPlanChange = (value: string): void => {
        this.setState({ selectdBatchPlan: value });
    };

    onSelectChange = (selectedRowKeys: string[] | number[]): void => {
        this.setState({ selectedRowKeys });
    };

    executeBatchChange = (): void => {
        const { updateUsers } = this.props;
        const { selectedRowKeys, selectdBatchPlan } = this.state;

        Modal.confirm({
            title: 'Multiple Users Plan Change',
            content: `${selectedRowKeys.length} users will be changed to plan ${selectdBatchPlan}. Are you sure?`,
            className: 'cvat-modal-confirm-delete-task',
            onOk: () => {
                const userinfos: any[] = [];
                selectedRowKeys.forEach((cognitoId: string | number) => {
                    const attributes: any[] = [];
                    attributes.push({ Name: 'custom:plan', Value: selectdBatchPlan });
        
                    userinfos.push({ cognitoId, attributes });
                    
                });
        
                updateUsers(userinfos);
            },
            okButtonProps: {
                danger: true,
            },
            okText: 'Confirm Change',
        });
    };
    
    public render(): JSX.Element {
        const {
            fetching,
            users,
            activateUser,
            deactivateUser,
            updateUser,
            t,
        } = this.props;

        const { pagination, selectedRowKeys, showBatchChange, selectdBatchPlan } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        const enableBatchExecution = selectedRowKeys.length > 0 && selectdBatchPlan != '';

        return (
            <div className='eyes-admin-page'>
                <Row justify='center' align='top'>
                    <Col span={23}>
                        <Text className='cvat-title'>{t('User Management Page')}</Text>
                    </Col>
                    <Col span={19}>
                        Pagination:
                        <Select
                            onChange={this.onPaginationChange}
                            defaultValue={pagination.toString()}
                            style={{ width: 70, marginLeft: 8 }}
                        >
                            <Select.Option value='20'>20</Select.Option>
                            <Select.Option value='50'>50</Select.Option>
                            <Select.Option value='100'>100</Select.Option>
                        </Select>

                        <Switch onChange={this.switchBatchChange} style={{ marginLeft: 8 }} /> Batch Change
                        {
                            showBatchChange && (
                                <>
                                    <Select onChange={this.onBatchPlanChange} style={{ width: 120, marginLeft: 8 }}>
                                        <Select.Option value='Basic'>Basic</Select.Option>
                                        <Select.Option value='Business'>Business</Select.Option>
                                        <Select.Option value='Pro'>Pro</Select.Option>
                                    </Select>
                                    <Button
                                        type='primary'
                                        onClick={this.executeBatchChange}
                                        className={!enableBatchExecution ? 'disabled' : ''}
                                        style={{ marginLeft: 8 }}
                                    >
                                        Execute Batch Change
                                    </Button>
                                </>
                            )
                        }
                    </Col>
                </Row>
                <Row justify='center' align='middle'>
                    <Col span={23}>
                        { fetching ? (
                            <Spin size='large' className='cvat-spinner' />
                        ) : (
                            <EditableFormTable
                                data={users}
                                rowSelection={showBatchChange ? rowSelection : undefined}
                                pageSize={pagination}
                                activateUser={activateUser}
                                deactivateUser={deactivateUser}
                                updateUser={updateUser}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(withTranslation()(AdminPageComponent));
