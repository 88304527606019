// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Spin from 'antd/lib/spin';

import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import TrainedModelsListContainer from 'containers/models-page/trained-model-list';
import TopBarComponent from './top-bar';
import UploadedModelsList from './uploaded-models-list';
import BuiltModelsList from './built-models-list';
import EmptyListComponent from './empty-list';
import { Model, ModelsQuery } from '../../reducers/interfaces';

interface Props {
    installedAutoAnnotation: boolean;
    installedTFSegmentation: boolean;
    installedTFAnnotation: boolean;
    installedTFTrain: boolean;
    modelsInitialized: boolean;
    modelsFetching: boolean;
    // registeredUsers: any[];
    models: Model[];
    onGetModels(gettingQuery: ModelsQuery): void;
    deleteModel(id: number): void;
    gettingQuery: ModelsQuery;
}

interface State {
    page: number;
}

class ModelsPageComponent extends React.PureComponent<Props & RouteComponentProps, State> {
    constructor(props: Props & RouteComponentProps) {
        super(props);
        this.state = { page: 1 };
    }

    public componentDidMount(): void {
        const {
            gettingQuery,
            location,
        } = this.props;

        this.updateQuery(gettingQuery, location.search);
    }

    public componentDidUpdate(prevProps: Props & RouteComponentProps): void {
        const {
            location,
            gettingQuery,
        } = this.props;

        if (prevProps.location.search !== location.search) {
            // get new tasks if any query changes
            this.updateQuery(gettingQuery, location.search);
        }
    }

    private handlePagination = (page: number): void => {
        const {
            gettingQuery,
        } = this.props;

        // modify query object
        const query = { ...gettingQuery };
        query.page = page;

        // update url according to new query object
        this.updateURL(query);
    };

    private updateQuery(previousQuery: ModelsQuery, searchString: string): ModelsQuery {
        const params = new URLSearchParams(searchString);
        const query = { ...previousQuery };
        for (const field of Object.keys(query)) {
            if (params.has(field)) {
                const value = params.get(field);
                if (value) {
                    if (field === 'id' || field === 'page') {
                        if (Number.isInteger(+value)) {
                            query[field] = +value;
                        }
                    } else {
                        query[field] = value;
                    }
                }
            } else if (field === 'page') {
                query[field] = 1;
            } else {
                query[field] = null;
            }
        }
        this.setState({ page: query.page || 1 });
        return query;
    }

    private updateURL(gettingQuery: ModelsQuery): void {
        const { history } = this.props;
        let queryString = '?';
        for (const field of Object.keys(gettingQuery)) {
            if (gettingQuery[field] !== null) {
                queryString += `${field}=${gettingQuery[field]}&`;
            }
        }

        const oldQueryString = history.location.search;
        if (oldQueryString !== queryString) {
            history.push({
                search: queryString.slice(0, -1),
            });

            // force update if any changes
            this.forceUpdate();
        }
    }

    public render(): JSX.Element {
        const {
            installedAutoAnnotation,
            installedTFSegmentation,
            installedTFAnnotation,
            installedTFTrain,
            modelsFetching,
            // registeredUsers,
            models,
            deleteModel,
        } = this.props;

        const { page } = this.state;
        const pageSize = 5;

        if (modelsFetching) {
            return (
                <Spin size='large' className='cvat-spinner' />
            );
        }

        const trainedModels = models.filter((model): boolean => model.type === 'trained');
        const uploadedModels = models.filter((model): boolean => model.type === 'openvino');
        const integratedModels = models.filter((model): boolean => model.type === 'preinstalled');

        const paginatedTrainedModels = trainedModels.slice((page - 1) * pageSize, page * pageSize);

        return (
            <div className='cvat-models-page'>
                <TopBarComponent installedAutoAnnotation={installedAutoAnnotation} />
                { !!integratedModels.length &&
                    <BuiltModelsList models={integratedModels} />}
                { !!trainedModels.length && (
                    <TrainedModelsListContainer
                        models={paginatedTrainedModels}
                        pageSize={pageSize}
                        currentPage={page}
                        numberOfModels={trainedModels.length}
                        onSwitchPage={this.handlePagination}
                    />
                )}
                { !!uploadedModels.length && (
                    <UploadedModelsList
                        // registeredUsers={registeredUsers}
                        models={uploadedModels}
                        deleteModel={deleteModel}
                    />
                )}
                { installedAutoAnnotation &&
                    !uploadedModels.length &&
                    !installedTFAnnotation &&
                    !installedTFSegmentation &&
                    !installedTFTrain &&
                    <EmptyListComponent />}
            </div>
        );
    }
}
export default withRouter(ModelsPageComponent);
