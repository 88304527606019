// Copyright (C) 2021 Humanome Lab., Inc.
//

import getCore from 'cvat-core-wrapper';

const core = getCore();

function limitOfTasks(plan: string): number {
    if (plan === 'Business') {
        return 10000;
    } if (plan === 'Pro' || plan === 'Team' || plan === 'Entry') {
        return 10;
    }
    return 1;
}

export default async function checkReachedTaskLimit(user: any): Promise<boolean> {
    const plan = (user && user.profile) ? user.profile.plan : null;
    const count = await core.server.request(`${core.config.backendAPI}/tasks/count`, { method: 'GET' });
    const limit = limitOfTasks(plan);
    return count >= limit;
}
