// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { ModelStatus, Model } from 'reducers/interfaces';
import TrainingForm, { TrainingData } from './training-form';

interface TrainingComponentProps {
    taskId: number;
    models: Model[];
    loaded: boolean;
    jobStatus: ModelStatus;
    modelsInitialized: boolean;
    statusInitialized: boolean;
    checkingStatus: boolean;
    plan: string;
    onStartTraining: (traingingData: TrainingData) => void;
    onTerminateTraining: () => void;
    checkJobsStatus: (timeout: number) => void;
}

function TrainingComponent(props: TrainingComponentProps & RouteComponentProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className='cvat-training-model'>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Text className='cvat-text-color cvat-jobs-header'>
                        {t('Training new model')}
                    </Text>
                </Col>
            </Row>
            <TrainingForm {...{ ...props, t }} />
        </div>
    );
}

export default withRouter(TrainingComponent);
