// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Tooltip from 'antd/lib/tooltip';
import Form, { FormInstance } from 'antd/lib/form';
import Text from 'antd/lib/typography/Text';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    PercentageOutlined,
} from '@ant-design/icons';

import patterns from 'utils/validation-patterns';

export interface AdvancedConfiguration {
    bugTracker?: string;
    zOrder: boolean;
    imageQuality?: number;
    overlapSize?: number;
    segmentSize?: number;
    startFrame?: number;
    stopFrame?: number;
    frameFilter?: string;
    lfs: boolean;
    repository?: string;
    useZipChunks: boolean;
    dataChunkSize?: number;
    applyOrientation: boolean;
}

type Props = {
    onSubmit(values: AdvancedConfiguration): void;
    installedGit: boolean;
};

function isPositiveInteger(_: any, value: any): Promise<any> {
    if (!value) {
        return Promise.resolve();
    }

    const intValue = +value;
    if (Number.isNaN(intValue) ||
        !Number.isInteger(intValue) || intValue < 1) {
        return Promise.reject(new Error('Value must be a positive integer'));
    }

    return Promise.resolve();
}

function isNonNegativeInteger(_: any, value: any): Promise<any> {
    if (!value) {
        return Promise.resolve();
    }

    const intValue = +value;
    if (Number.isNaN(intValue) || intValue < 0) {
        return Promise.reject(new Error('Value must be a non negative integer'));
    }

    return Promise.resolve();
}

function isIntegerRange(min: number, max: number, _: any, value: any): Promise<any> {
    if (!value) {
        return Promise.resolve();
    }

    const intValue = +value;
    if (Number.isNaN(intValue) ||
        !Number.isInteger(intValue) ||
        intValue < min || intValue > max
    ) {
        return Promise.reject(new Error(`Value must be an integer [${min}, ${max}]`));
    }

    return Promise.resolve();
}

class AdvancedConfigurationForm extends React.PureComponent<Props> {
    private formRef: React.RefObject<FormInstance>;

    public constructor(props: Props) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
    }

    public submit(): Promise<void> {
        return new Promise((resolve, reject) => {
            const {
                onSubmit,
            } = this.props;

            if (this.formRef.current) {
                this.formRef.current.validateFields().then((values: any) => {
                    const filteredValues = { ...values };
                    delete filteredValues.frameStep;
    
                    if (values.overlapSize && +values.segmentSize <= +values.overlapSize) {
                        reject(new Error('Overlap size must be more than segment size'));
                    }
    
                    if (typeof (values.startFrame) !== 'undefined' && typeof (values.stopFrame) !== 'undefined' &&
                        +values.stopFrame < +values.startFrame
                    ) {
                        reject(new Error('Stop frame must be more or equal start frame'));
                    }
    
                    onSubmit({
                        ...values,
                        frameFilter: values.frameStep ? `step=${values.frameStep}` : undefined,
                    });
                    resolve();
                }).catch(() => {
                    reject();
                });
            } else {
                reject();
            }
        });
    }

    public resetFields(): void {
        this.formRef.current?.resetFields();
    }

    private renderZOrder(): JSX.Element {
        return (
            <Form.Item
                name='zOrder'
                help='Enables order for shapes. Useful for segmentation tasks'
                initialValue={false}
                valuePropName='checked'
            >
                <Checkbox>
                    <Text className='cvat-text-color'>
                        Z-order
                    </Text>
                </Checkbox>
            </Form.Item>
        );
    }

    private renderImageQuality(): JSX.Element {
        return (
            <Tooltip title='Defines image compression level'>
                <Form.Item
                    name='imageQuality'
                    label={<span>Image quality</span>}
                    initialValue={70}
                    rules={[{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isIntegerRange.bind(null, 5, 100),
                    }]}
                    className='eyes-form-display-table'
                >
                    <Input
                        size='large'
                        type='number'
                        suffix={<PercentageOutlined rev='' />}
                    />
                </Form.Item>
            </Tooltip>
        );
    }

    private renderOverlap(): JSX.Element {
        return (
            <Tooltip title='Defines a number of intersected frames between different segments'>
                <Form.Item
                    name='overlapSize'
                    label={<span>Overlap size</span>}
                    rules={[{
                        validator: isNonNegativeInteger,
                    }]}
                    className='eyes-form-display-table'
                >
                    <Input size='large' type='number' />
                </Form.Item>
            </Tooltip>
        );
    }

    private renderSegmentSize(): JSX.Element {
        return (
            <Tooltip title='Defines a number of frames in a segment'>
                <Form.Item
                    name='segmentSize'
                    label={<span>Segment size</span>}
                    rules={[{
                        validator: isPositiveInteger,
                    }]}
                    className='eyes-form-display-table'
                >
                    <Input size='large' type='number' />
                </Form.Item>
            </Tooltip>
        );
    }

    private renderStartFrame(): JSX.Element {
        return (
            <Form.Item
                name='startFrame'
                label={<span>Start frame</span>}
                rules={[{
                    validator: isNonNegativeInteger,
                }]}
                className='eyes-form-display-table'
            >
                <Input
                    size='large'
                    type='number'
                    min={0}
                    step={1}
                />
            </Form.Item>
        );
    }

    private renderStopFrame(): JSX.Element {
        return (
            <Form.Item
                name='stopFrame'
                label={<span>Stop frame</span>}
                rules={[{
                    validator: isNonNegativeInteger,
                }]}
                className='eyes-form-display-table'
            >
                <Input
                    size='large'
                    type='number'
                    min={0}
                    step={1}
                />
            </Form.Item>
        );
    }

    private renderFrameStep(): JSX.Element {
        return (
            <Form.Item
                name='frameStep'
                label={<span>Frame step</span>}
                rules={[{
                    validator: isPositiveInteger,
                }]}
                className='eyes-form-display-table'
            >
                <Input
                    size='large'
                    type='number'
                    min={1}
                    step={1}
                />
            </Form.Item>
        );
    }

    private renderGitLFSBox(): JSX.Element {
        return (
            <Form.Item
                name='lfs'
                help='If annotation files are large, you can use git LFS feature'
                valuePropName='checked'
                initialValue={false}
            >
                <Checkbox>
                    <Text className='cvat-text-color'>
                        Use LFS (Large File Support):
                    </Text>
                </Checkbox>,
            </Form.Item>
        );
    }

    private renderGitRepositoryURL(): JSX.Element {
        return (
            <Form.Item
                name='repository'
                hasFeedback
                label={<span>Dataset repository URL</span>}
                extra='Attach a repository to store annotations there'
                rules={[{
                    validator: (_, value): Promise<any> => {
                        if (!value) {
                            return Promise.resolve();
                        } else {
                            const [url, path] = value.split(/\s+/);
                            if (!patterns.validateURL.pattern.test(url)) {
                                return Promise.reject(new Error('Git URL is not a valid'));
                            }

                            if (path && !patterns.validatePath.pattern.test(path)) {
                                return Promise.reject(new Error('Git path is not a valid'));
                            }

                            return Promise.resolve();
                        }
                    },
                }]}
            >
                <Input
                    size='large'
                    placeholder='e.g. https//github.com/user/repos [annotation/<anno_file_name>.zip]'
                />
            </Form.Item>
        );
    }

    private renderGit(): JSX.Element {
        return (
            <>
                <Row>
                    <Col>
                        {this.renderGitRepositoryURL()}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.renderGitLFSBox()}
                    </Col>
                </Row>
            </>
        );
    }

    private renderBugTracker(): JSX.Element {
        return (
            <Form.Item
                name='bugTracker'
                hasFeedback
                label={<span>Issue tracker</span>}
                extra='Attach issue tracker where the task is described'
                rules={[{
                    validator: (_, value): Promise<any> => {
                        if (value && !patterns.validateURL.pattern.test(value)) {
                            return Promise.reject(new Error('Issue tracker must be URL'));
                        } else {
                            return Promise.resolve();
                        }
                    },
                }]}
            >
                <Input size='large' />
            </Form.Item>
        );
    }

    private renderUzeZipChunks(): JSX.Element {
        return (
            <Form.Item
                name='useZipChunks'
                help='Force to use zip chunks as compressed data. Actual for videos only.'
                initialValue={true}
                valuePropName='checked'
            >
                <Checkbox>
                    <Text className='cvat-text-color'>
                        Use zip chunks
                    </Text>
                </Checkbox>
            </Form.Item>
        );
    }

    private renderApplyOrientation(): JSX.Element {
        return (
            <Form.Item
                name='applyOrientation'
                initialValue={false}
                valuePropName='checked'
            >
                <Checkbox>
                    <Text className='cvat-text-color'>
                        Apply Image Orientation
                    </Text>
                </Checkbox>
            </Form.Item>
        );
    }

    private renderChunkSize(): JSX.Element {
        return (
            <Tooltip
                title={(
                    <>
                        Defines a number of frames to be packed in
                        a chunk when send from client to server.
                        Server defines automatically if empty.
                        <br />
                        Recommended values:
                        <br />
                        1080p or less: 36
                        <br />
                        2k or less: 8 - 16
                        <br />
                        4k or less: 4 - 8
                        <br />
                        More: 1 - 4
                    </>
                )}
            >
                <Form.Item
                    name='dataChunkSize'
                    label={<span>Chunk size</span>}
                    rules={[{
                        validator: isPositiveInteger,
                    }]}
                    className='eyes-form-display-table'
                >
                    <Input size='large' type='number' />
                </Form.Item>
            </Tooltip>
        );
    }

    public render(): JSX.Element {
        return (
            <Form ref={this.formRef}>
                <Row>
                    <Col>
                        {this.renderZOrder()}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {this.renderUzeZipChunks()}
                    </Col>
                </Row>

                <Row justify='start'>
                    <Col span={7}>
                        {this.renderImageQuality()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderOverlap()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderSegmentSize()}
                    </Col>
                </Row>

                <Row justify='start'>
                    <Col span={7}>
                        {this.renderStartFrame()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderStopFrame()}
                    </Col>
                    <Col span={7} offset={1}>
                        {this.renderFrameStep()}
                    </Col>
                </Row>

                <Row justify='start'>
                    <Col span={7}>
                        {this.renderChunkSize()}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {this.renderApplyOrientation()}
                    </Col>
                </Row>

            </Form>
        );
    }
}

export default AdvancedConfigurationForm;
