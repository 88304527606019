// Copyright (C) 2020 Humanome Lab.

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import getCore from 'cvat-core-wrapper';
import { TrainingData } from 'components/task-page/training-form';

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

export enum TrainingActionTypes {
    TRAINING = 'TRAINING',
    TRAINING_START = 'TRAINING_START',
    TRAINING_FAILED = 'TRAINING_FAILED',
    TRAINING_RUNNING = 'TRAINING_RUNNING',
    TRAINING_FINISHED = 'TRAINING_FINISHED',
    CANCEL = 'CANCEL',
}

const trainingActions = {
    training: () => createAction(TrainingActionTypes.TRAINING),
    trainingStart: (id: number) => createAction(TrainingActionTypes.TRAINING_START, { id }),
    trainingFailed: (error: any) => createAction(TrainingActionTypes.TRAINING_FAILED, { error }),
    trainingFinished: () => createAction(TrainingActionTypes.TRAINING_FINISHED),
    cancel: () => createAction(TrainingActionTypes.CANCEL),
};

export type TrainingActions = ActionUnion<typeof trainingActions>;

export const trainingAsync = (
    taskId: number, data: TrainingData,
): ThunkAction => async (dispatch) => {
    dispatch(trainingActions.training());
    try {
        await core.server.request(
            `${baseURL}/tensorflow/train/create/task/${taskId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data,
            },
        );
        dispatch(trainingActions.trainingStart(taskId));
    } catch (error) {
        dispatch(trainingActions.trainingFailed(error));
    }
};

export const cancelTrainingAsync = (
    taskId: number,
): ThunkAction => async (dispatch) => {
    dispatch(trainingActions.cancel());
    try {
        await core.server.request(
            `${baseURL}/tensorflow/train/cancel/task/${taskId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify([]),
            },
        );
        dispatch(trainingActions.trainingFinished());
    } catch (error) {
        dispatch(trainingActions.trainingFailed(error));
    }
};
