// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Input, { InputRef } from 'antd/lib/input';
import Form from 'antd/lib/form';
import { Translation } from 'react-i18next';
import { FormInstance } from 'antd/es/form/Form';

export interface BaseConfiguration {
    name: string;
}

type Props = {
    onSubmit(values: BaseConfiguration): void;
};

class BasicConfigurationForm extends React.PureComponent<Props> {
    private formRef: React.RefObject<FormInstance>;
    private namelInput: React.RefObject<InputRef>;

    public constructor(props: Props) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
        this.namelInput = React.createRef<InputRef>();
    }

    public componentDidMount(): void {
        this.namelInput.current?.focus();
    }

    public submit(): Promise<void> {
        return new Promise((resolve, reject) => {
            const {
                onSubmit,
            } = this.props;

            this.formRef.current?.validateFields().then((values) => {
                onSubmit({
                    name: values.name,
                });
                resolve();
            }).catch(() => {
                reject();
            });
        });
    }

    public resetFields(): void {
        this.formRef.current?.resetFields();
    }

    public render(): JSX.Element {
        return (
            <Translation>
                {
                    (t) => (
                        <Form
                            ref={this.formRef}
                        >
                            <Form.Item
                                name='name'
                                hasFeedback
                                label={<span>{t('Name')}</span>}
                                rules={[{
                                    required: true,
                                    message: 'Please, specify a name',
                                }]}
                                className='eyes-form-display-table'
                            >
                                <Input
                                    ref={this.namelInput}
                                />
                            </Form.Item>
                        </Form>
                    )
                }
            </Translation>
        );
    }
}

export default BasicConfigurationForm;
