// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import type { MenuProps } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import DumpSubmenu from 'components/actions-menu/dump-submenu';
import LoadSubmenu from 'components/actions-menu/load-submenu';
// import ExportSubmenu from 'components/actions-menu/export-submenu';
// import ReIDPlugin from './reid-plugin';
import {
    MainMenuIcon,
} from 'icons';

interface Props {
    taskMode: string;
    loaders: string[];
    dumpers: string[];
    loadActivity: string | null;
    dumpActivities: string[] | null;
    exportActivities: string[] | null;
    installedReID: boolean;
    taskID: number;
    onClickMenu(params: MenuInfo, file?: File): void;
}

export enum Actions {
    DUMP_TASK_ANNO = 'dump_task_anno',
    LOAD_JOB_ANNO = 'load_job_anno',
    EXPORT_TASK_DATASET = 'export_task_dataset',
    REMOVE_ANNO = 'remove_anno',
    OPEN_TASK = 'open_task',
}

export default function AnnotationMenuComponent(props: Props): JSX.Element {
    const {
        taskMode,
        loaders,
        dumpers,
        onClickMenu,
        loadActivity,
        dumpActivities,
        // exportActivities,
        // installedReID,
        taskID,
    } = props;
    const { t } = useTranslation();

    let latestParams: MenuInfo | null = null;
    function onClickMenuWrapper(params: MenuInfo | null, file?: File): void {
        const copyParams = params || latestParams;
        if (!copyParams) {
            return;
        }
        latestParams = params;

        if (copyParams.keyPath.length === 2) {
            const [, action] = copyParams.keyPath;
            if (action === Actions.LOAD_JOB_ANNO) {
                if (file) {
                    Modal.confirm({
                        title: t('Current annotation will be lost'),
                        content: t('You are going to upload new annotations to this job Continue?'),
                        onOk: () => {
                            onClickMenu(copyParams, file);
                        },
                        okButtonProps: {
                            danger: true,
                        },
                        okText: 'Update',
                    });
                }
            } else {
                onClickMenu(copyParams);
            }
        } else if (copyParams.key === Actions.REMOVE_ANNO) {
            Modal.confirm({
                title: t('All annotations will be removed'),
                content: t('You are going to remove all annotations from the client') +
                    t('It will stay on the server till you save a job Continue?'),
                className: 'cvat-modal-confirm-remove-annotation',
                onOk: () => {
                    onClickMenu(copyParams);
                },
                okButtonProps: {
                    danger: true,
                },
                okText: 'Delete',
            });
        } else {
            onClickMenu(copyParams);
        }
    }

    const menus: MenuProps = {
        rootClassName: 'cvat-annotation-menu',
        selectable: false,
        onClick: onClickMenuWrapper,
    };
    menus.items = DumpSubmenu({
        taskMode,
        dumpers,
        dumpActivities,
        menuKey: Actions.DUMP_TASK_ANNO,
        jobsLength: 1,
    });
    menus.items.push(...LoadSubmenu({
        loaders,
        loadActivity,
        onFileUpload: (file: File): void => {
            onClickMenuWrapper(null, file);
        },
        menuKey: Actions.LOAD_JOB_ANNO,
        inferenceIsActive: false,
        isTraining: false,
        jobsLength: 1,
    }));
    // eyes hide export a dataset
    // menus.items.push(...ExportSubmenu({
    //     exporters: dumpers,
    //     exportActivities,
    //     menuKey: Actions.EXPORT_TASK_DATASET,
    //     jobsLength: 1,
    // }));
    menus.items.push(
        {
            key: Actions.REMOVE_ANNO,
            label: t('Remove annotations'),
        },
        {
            key: Actions.OPEN_TASK,
            label: (
                <a href={`/tasks/${taskID}`} onClick={(e: React.MouseEvent) => e.preventDefault()}>
                    {t('Open the task')}
                </a>
            ),
        },
    );
    // { installedReID && <ReIDPlugin /> }
    return (
        <Dropdown menu={menus} trigger={['hover', 'click']}>
            <Button type='link' className='cvat-annotation-header-button'>
                <Icon component={MainMenuIcon} rev='' />
                Menu
            </Button>
        </Dropdown>
    );
}
