// Copyright (C) 2021 Humanome Lab., Inc.
//
import Tooltip, { TooltipProps } from 'antd/lib/tooltip';
import React from 'react';
import { isHoverSupported } from './enviroment';

// eslint-disable-next-line import/prefer-default-export
export const EyesTooltip = (props: TooltipProps): JSX.Element => {
    if (!isHoverSupported()) {
        const {
            children,
        } = props;
        return <>{children}</>;
    }
    return <Tooltip {...props} />;
};
