// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Input, { InputRef } from 'antd/lib/input';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import Tooltip from 'antd/lib/tooltip';
import Select from 'antd/lib/select';
import Form, { FormInstance } from 'antd/lib/form';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    ClockCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import patterns from 'utils/validation-patterns';
import {
    equalArrayHead,
    idGenerator,
    Label,
    Attribute,
} from './common';

export enum AttributeType {
    SELECT = 'SELECT',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
}

type Props = WithTranslation & {
    label: Label | null;
    labelNames?: string[];
    onSubmit: (label: Label | null) => void;
};

class LabelForm extends React.PureComponent<Props, {}> {
    private formRef: React.RefObject<FormInstance>;
    private continueAfterSubmit: boolean;
    private labelInput: React.RefObject<InputRef>;

    constructor(props: Props) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
        this.labelInput = React.createRef<InputRef>();
        this.continueAfterSubmit = false;
    }

    public componentDidMount(): void {
        this.labelInput.current?.focus();
    }

    private handleFinish = (formValues: any): void => {
        const {
            label,
            onSubmit,
        } = this.props;

        onSubmit({
            name: formValues.labelName,
            id: label ? label.id : idGenerator(),
            attributes: (formValues.keys || []).map((key: number, index: number): Attribute => {
                let attrValues = formValues.values[key];
                if (!Array.isArray(attrValues)) {
                    if (formValues.type[key] === AttributeType.NUMBER) {
                        attrValues = attrValues.split(';');
                    } else {
                        attrValues = [attrValues];
                    }
                }

                attrValues = attrValues.map((value: string) => value.trim());

                return {
                    name: formValues.attrName[key],
                    input_type: formValues.type[key],
                    mutable: formValues.mutable[key],
                    id: label && index < label.attributes.length ?
                        label.attributes[index].id : key,
                    values: attrValues,
                };
            }),
        });

        this.formRef.current?.resetFields();

        if (!this.continueAfterSubmit) {
            onSubmit(null);
        } else {
            this.labelInput.current?.focus();
        }
    };

    private handleFinishFailed = (): void => {
        this.labelInput.current?.focus();
    };

    private addAttribute = (): void => {
        const keys = this.formRef.current?.getFieldValue('keys');
        const nextKeys = keys.concat(idGenerator());
        this.formRef.current?.setFieldsValue({
            keys: nextKeys,
        });
    };

    private removeAttribute = (key: number): void => {
        const keys = this.formRef.current?.getFieldValue('keys');
        this.formRef.current?.setFieldsValue({
            keys: keys.filter((_key: number) => _key !== key),
        });
    };

    private renderAttributeNameInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const value = attr ? attr.name : '';

        return (
            <Col span={5}>
                <Form.Item
                    name={`attrName[${key}]`}
                    hasFeedback
                    initialValue={value}
                    rules={[{
                        required: true,
                        message: 'Please specify a name',
                    }, {
                        pattern: patterns.validateAttributeName.pattern,
                        message: patterns.validateAttributeName.message,
                    }]}
                >
                    <Input disabled={locked} placeholder='Name' />
                </Form.Item>
            </Col>
        );
    }

    private renderAttributeTypeInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const type = attr ? attr.input_type.toUpperCase() : AttributeType.SELECT;

        return (
            <Col span={4}>
                <Tooltip title='An HTML element representing the attribute'>
                    <Form.Item
                        name={`type[${key}]`}
                        initialValue={type}
                    >
                        <Select disabled={locked}>
                            <Select.Option value={AttributeType.SELECT}>
                                Select
                            </Select.Option>
                            <Select.Option value={AttributeType.RADIO}>
                                Radio
                            </Select.Option>
                            <Select.Option value={AttributeType.CHECKBOX}>
                                Checkbox
                            </Select.Option>
                            <Select.Option value={AttributeType.TEXT}>
                                Text
                            </Select.Option>
                            <Select.Option value={AttributeType.NUMBER}>
                                Number
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Tooltip>
            </Col>
        );
    }

    private renderAttributeValuesInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const existedValues = attr ? attr.values : [];

        const validator = (_: any, values: string[]): Promise<any> => {
            if (locked && existedValues) {
                if (!equalArrayHead(existedValues, values)) {
                    return Promise.reject(new Error('You can only append new values'));
                }
            }

            for (const value of values) {
                if (!patterns.validateAttributeValue.pattern.test(value)) {
                    return Promise.reject(new Error(`Invalid attribute value: "${value}"`));
                }
            }

            return Promise.resolve();
        };

        return (
            <Tooltip title='Press enter to add a new value'>
                <Form.Item
                    name={`values[${key}]`}
                    initialValue={existedValues}
                    rules={[{
                        required: true,
                        message: 'Please specify values',
                    }, {
                        validator,
                    }]}
                >
                    <Select
                        mode='tags'
                        dropdownStyle={{ display: 'none' }}
                        placeholder='Attribute values'
                    />
                </Form.Item>
            </Tooltip>
        );
    }

    private renderBooleanValueInput(key: number, attr: Attribute | null): JSX.Element {
        const value = attr ? attr.values[0] : 'false';

        return (
            <Tooltip title='Specify a default value'>
                <Form.Item
                    name={`values[${key}]`}
                    initialValue={value}
                >
                    <Select>
                        <Select.Option value='false'> False </Select.Option>
                        <Select.Option value='true'> True </Select.Option>
                    </Select>
                </Form.Item>
            </Tooltip>
        );
    }

    private renderNumberRangeInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const value = attr ? attr.values.join(';') : '';

        const validator = (_: any, strNumbers: string): Promise<any> => {
            const numbers = strNumbers
                .split(';')
                .map((number): number => Number.parseFloat(number));
            if (numbers.length !== 3) {
                return Promise.reject(new Error('Invalid input'));
            }

            for (const number of numbers) {
                if (Number.isNaN(number)) {
                    return Promise.reject(new Error('Invalid input'));
                }
            }

            if (numbers[0] >= numbers[1]) {
                return Promise.reject(new Error('Invalid input'));
            }

            if (+numbers[1] - +numbers[0] < +numbers[2]) {
                return Promise.reject(new Error('Invalid input'));
            }

            return Promise.resolve();
        };

        return (
            <Form.Item
                name={`values[${key}]`}
                initialValue={value}
                rules={[{
                    required: true,
                    message: 'Please set a range',
                }, {
                    validator,
                }]}
            >
                <Input disabled={locked} placeholder='min;max;step' />
            </Form.Item>
        );
    }

    private renderDefaultValueInput(key: number, attr: Attribute | null): JSX.Element {
        const value = attr ? attr.values[0] : '';

        return (
            <Form.Item
                name={`values[${key}]`}
                initialValue={value}
            >
                <Input placeholder='Default value' />
            </Form.Item>
        );
    }

    private renderMutableAttributeInput(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;
        const value = attr ? attr.mutable : false;

        return (
            <Tooltip title='Can this attribute be changed frame to frame?'>
                <Form.Item
                    name={`mutable[${key}]`}
                    initialValue={value}
                    valuePropName='checked'
                >
                    <Checkbox disabled={locked}> Mutable </Checkbox>
                </Form.Item>
            </Tooltip>
        );
    }

    private renderDeleteAttributeButton(key: number, attr: Attribute | null): JSX.Element {
        const locked = attr ? attr.id >= 0 : false;

        return (
            <Tooltip title='Delete the attribute'>
                <Form.Item>
                    <Button
                        type='link'
                        className='cvat-delete-attribute-button'
                        disabled={locked}
                        onClick={(): void => {
                            this.removeAttribute(key);
                        }}
                    >
                        <ClockCircleOutlined rev='' />
                    </Button>
                </Form.Item>
            </Tooltip>
        );
    }

    private renderAttribute = (key: number, index: number): JSX.Element => {
        const {
            label,
        } = this.props;
        const form = Form.useFormInstance();

        const attr = (label &&
            index < label.attributes.length ?
            label.attributes[index] :
            null);

        return (
            <Form.Item key={key}>
                <Row justify='space-between' align='middle'>
                    { this.renderAttributeNameInput(key, attr) }
                    { this.renderAttributeTypeInput(key, attr) }
                    <Col span={6}>
                        {((): JSX.Element => {
                            const type = form.getFieldValue(`type[${key}]`);
                            let element = null;
                            if ([AttributeType.SELECT, AttributeType.RADIO].includes(type)) {
                                element = this.renderAttributeValuesInput(key, attr);
                            } else if (type === AttributeType.CHECKBOX) {
                                element = this.renderBooleanValueInput(key, attr);
                            } else if (type === AttributeType.NUMBER) {
                                element = this.renderNumberRangeInput(key, attr);
                            } else {
                                element = this.renderDefaultValueInput(key, attr);
                            }

                            return element;
                        })()}
                    </Col>
                    <Col span={5}>
                        { this.renderMutableAttributeInput(key, attr) }
                    </Col>
                    <Col span={2}>
                        { this.renderDeleteAttributeButton(key, attr) }
                    </Col>
                </Row>
            </Form.Item>
        );
    };

    private renderLabelNameInput(): JSX.Element {
        const { label, labelNames } = this.props;
        const { t } = this.props;
        const value = label ? label.name : '';
        const locked = label ? label.id >= 0 : false;

        return (
            <Col span={10}>
                <Form.Item
                    name='labelName'
                    hasFeedback
                    initialValue={value}
                    rules={[
                        {
                            required: true,
                            message: t('Please specify a name'),
                        }, {
                            pattern: patterns.validateLabelName.pattern,
                            message: t(patterns.validateLabelName.message),
                        },
                        {
                            validator: (_rule: any, labelName: string) => {
                                if (labelNames && labelNames.includes(labelName)) {
                                    return Promise.reject(new Error('Label name must be unique for the task'));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input disabled={locked} placeholder={t('Label name')} ref={this.labelInput} />
                </Form.Item>
            </Col>
        );
    }

    private renderNewAttributeButton(): JSX.Element {
        return (
            <Col span={3}>
                <Form.Item>
                    <Button type='ghost' onClick={this.addAttribute}>
                        Add an attribute
                        <PlusOutlined rev='' />
                    </Button>
                </Form.Item>
            </Col>
        );
    }

    private renderDoneButton(): JSX.Element {
        const { t } = this.props;
        return (
            <Col>
                <Tooltip title={t('Save the label and return')}>
                    <Button
                        style={{ width: '150px' }}
                        type='primary'
                        htmlType='submit'
                        onClick={(): void => {
                            this.continueAfterSubmit = false;
                        }}
                    >
                        {t('Done')}
                    </Button>
                </Tooltip>
            </Col>
        );
    }

    private renderContinueButton(): JSX.Element {
        const { label } = this.props;
        const { t } = this.props;

        return (
            label ? <div /> : (
                <Col offset={1}>
                    <Tooltip title={t('Save the label and create one more')}>
                        <Button
                            style={{ width: '150px' }}
                            type='primary'
                            htmlType='submit'
                            onClick={(): void => {
                                this.continueAfterSubmit = true;
                            }}
                        >
                            {t('Continue')}
                        </Button>
                    </Tooltip>
                </Col>
            )
        );
    }

    private renderCancelButton(): JSX.Element {
        const { onSubmit } = this.props;
        const { t } = this.props;

        return (
            <Col offset={1}>
                <Tooltip title={t('Do not save the label and return')}>
                    <Button
                        style={{ width: '150px' }}
                        danger
                        onClick={(): void => {
                            onSubmit(null);
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                </Tooltip>
            </Col>
        );
    }

    public render(): JSX.Element {
        // const {
        //     label,
        // } = this.props;
        // form.getFieldDecorator('keys', {
        //     initialValue: label ?
        //         label.attributes.map((attr: Attribute): number => attr.id) :
        //         [],
        // });

        // const keys = form.getFieldValue('keys');
        // const attributeItems = keys.map(this.renderAttribute);

        return (
            <Form
                ref={this.formRef}
                onFinish={this.handleFinish}
                onFinishFailed={this.handleFinishFailed}
            >
                <Row justify='start' align='middle'>
                    { this.renderLabelNameInput() }
                    <Col span={1} />
                    { false && // disable attribute 2020.09
                        this.renderNewAttributeButton() }
                </Row>
                {/* { attributeItems.length > 0 &&
                    (
                        <Row type='flex' justify='start' align='middle'>
                            <Col>
                                <Text>Attributes</Text>
                            </Col>
                        </Row>
                    )}
                { attributeItems.reverse() } */}
                <Row justify='start' align='middle'>
                    { this.renderDoneButton() }
                    { this.renderContinueButton() }
                    { this.renderCancelButton() }
                </Row>
            </Form>
        );
    }
}

export default withTranslation()(LabelForm);
