// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

// import React from 'react';
import { connect } from 'react-redux';

import JobListComponent from 'components/task-page/job-list';
import { updateJobAsync, conditionCheckAsync, getTasksAsync } from 'actions/tasks-actions';
import {
    Task,
    TasksQuery,
    CombinedState,
    ActiveInference,
} from 'reducers/interfaces';
import { cancelInferenceAsync } from 'actions/models-actions';

interface OwnProps {
    task: Task;
}

interface StateToProps {
    loaded: boolean;
    // registeredUsers: any[];
    taskInstance: any;
    loadActivity: string | null;
    activeInference: ActiveInference | null;
}

interface DispatchToProps {
    onJobUpdate(jobInstance: any): void;
    conditionChecked: () => void;
    cancelAutoAnnotation(): void;
    getTasks(query: TasksQuery): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const { task } = own;
    const { id } = task.instance;
    const {
        tasks: {
            activities: {
                loads,
            },
        },
    } = state;

    return {
        loaded: state.tasks.loaded,
        // registeredUsers: state.users.users,
        taskInstance: task.instance,
        loadActivity: id in loads ? loads[id] : null,
        activeInference: state.models.inferences[id] || null,
    };
}

function mapDispatchToProps(dispatch: any, own: OwnProps): DispatchToProps {
    return {
        onJobUpdate: (jobInstance: any): void => dispatch(updateJobAsync(jobInstance)),
        conditionChecked: (): void => dispatch(conditionCheckAsync()),
        cancelAutoAnnotation(): void {
            dispatch(cancelInferenceAsync(own.task.instance.id));
        },
        getTasks(query: TasksQuery): void {
            dispatch(getTasksAsync(query));
        },
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(JobListComponent);
