// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import { Row, Col } from 'antd/lib/grid';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import InputNumber from 'antd/lib/input-number';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { clamp } from 'utils/math';

interface Props {
    autoSave: boolean;
    autoSaveInterval: number;
    aamZoomMargin: number;
    showAllInterpolationTracks: boolean;
    showObjectsTextAlways: boolean;
    automaticBordering: boolean;
    onSwitchAutoSave(enabled: boolean): void;
    onChangeAutoSaveInterval(interval: number): void;
    onChangeAAMZoomMargin(margin: number): void;
    onSwitchShowingInterpolatedTracks(enabled: boolean): void;
    onSwitchShowingObjectsTextAlways(enabled: boolean): void;
    onSwitchAutomaticBordering(enabled: boolean): void;
}

export default function WorkspaceSettingsComponent(props: Props): JSX.Element {
    const {
        autoSave,
        autoSaveInterval,
        aamZoomMargin,
        showAllInterpolationTracks,
        showObjectsTextAlways,
        automaticBordering,
        onSwitchAutoSave,
        onChangeAutoSaveInterval,
        onChangeAAMZoomMargin,
        onSwitchShowingInterpolatedTracks,
        onSwitchShowingObjectsTextAlways,
        onSwitchAutomaticBordering,
    } = props;

    const minAutoSaveInterval = 5;
    const maxAutoSaveInterval = 60;
    const minAAMMargin = 0;
    const maxAAMMargin = 1000;

    const { t } = useTranslation('settingsPage');

    return (
        <div className='cvat-workspace-settings'>
            <Row>
                <Col>
                    <Checkbox
                        className='cvat-text-color cvat-workspace-settings-auto-save'
                        checked={autoSave}
                        onChange={(event: CheckboxChangeEvent): void => {
                            onSwitchAutoSave(event.target.checked);
                        }}
                    >
                        {t('Enable auto save')}
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col className='cvat-workspace-settings-auto-save-interval'>
                    <Text type='secondary'> {t('Auto save every')} </Text>
                    <InputNumber
                        min={minAutoSaveInterval}
                        max={maxAutoSaveInterval}
                        step={1}
                        value={Math.round(autoSaveInterval / (60 * 1000))}
                        onChange={(value: number | null): void => {
                            if (typeof (value) === 'number') {
                                onChangeAutoSaveInterval(Math.floor(
                                    clamp(
                                        value,
                                        minAutoSaveInterval,
                                        maxAutoSaveInterval,
                                    ),
                                ) * 60 * 1000);
                            }
                        }}
                    />
                    <Text type='secondary'> {t('minutes')} </Text>
                </Col>
            </Row>
            <Row className='cvat-workspace-settings-show-interpolated'>
                <Col>
                    <Row>
                        <Col>
                            <Checkbox
                                className='cvat-text-color'
                                checked={showAllInterpolationTracks}
                                onChange={(event: CheckboxChangeEvent): void => {
                                    onSwitchShowingInterpolatedTracks(event.target.checked);
                                }}
                            >
                                {t('Show all interpolation tracks')}
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text type='secondary'> {t('Show hidden interpolated objects in the side panel')} </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='cvat-workspace-settings-show-text-always'>
                <Col>
                    <Row>
                        <Col>
                            <Checkbox
                                className='cvat-text-color'
                                checked={showObjectsTextAlways}
                                onChange={(event: CheckboxChangeEvent): void => {
                                    onSwitchShowingObjectsTextAlways(event.target.checked);
                                }}
                            >
                                {t('Always show object details')}
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text type='secondary'> {('Show text for an object on the canvas not only when the object is activated')}  </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='cvat-workspace-settings-autoborders'>
                <Col>
                    <Row>
                        <Col>
                            <Checkbox
                                className='cvat-text-color'
                                checked={automaticBordering}
                                onChange={(event: CheckboxChangeEvent): void => {
                                    onSwitchAutomaticBordering(event.target.checked);
                                }}
                            >
                                {t('Automatic bordering')}
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Text type='secondary'> {t('Enable automatic bordering for polygons and polylines during drawing/editing')} </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='cvat-workspace-settings-aam-zoom-margin'>
                <Col>
                    <Text className='cvat-text-color'> {t('Attribute annotation mode (AAM) zoom margin')} </Text>
                    <InputNumber
                        min={minAAMMargin}
                        max={maxAAMMargin}
                        value={aamZoomMargin}
                        onChange={(value: number | null): void => {
                            if (typeof (value) === 'number') {
                                onChangeAAMZoomMargin(Math.floor(
                                    clamp(value, minAAMMargin, maxAAMMargin),
                                ));
                            }
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
