// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon from '@ant-design/icons';

import { MergeIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { ActiveControl } from 'reducers/interfaces';
import { EyesTooltip as Tooltip } from 'utils/custom-antd';

export interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
    switchMergeShortcut: string;
    mergeObjects(enabled: boolean): void;
}

function MergeControl(props: Props): JSX.Element {
    const {
        switchMergeShortcut,
        activeControl,
        canvasInstance,
        mergeObjects,
    } = props;
    const { t } = useTranslation();

    const dynamicIconProps = activeControl === ActiveControl.MERGE ?
        {
            className: 'cvat-merge-control cvat-active-canvas-control',
            onClick: (): void => {
                canvasInstance.merge({ enabled: false });
                mergeObjects(false);
            },
        } : {
            className: 'cvat-merge-control',
            onClick: (): void => {
                canvasInstance.cancel();
                canvasInstance.merge({ enabled: true });
                mergeObjects(true);
            },
        };

    return (
        <Tooltip title={t('Merge shapes/tracks {{switchMergeShortcut}}', { switchMergeShortcut })} placement='right'>
            <Icon {...dynamicIconProps} component={MergeIcon} rev='' />
        </Tooltip>
    );
}

export default React.memo(MergeControl);
