// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import Dropdown from 'antd/lib/dropdown';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import type { MenuProps } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import Icon, {
    SettingOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    LogoutOutlined,
} from '@ant-design/icons';

import { AccountIcon, EyesLogo } from 'icons';

export interface HeaderContainerProps extends WithTranslation {
    onLogout: () => void;
    logoutFetching: boolean;
    installedAutoAnnotation: boolean;
    installedTFTrain: boolean; // 2020/04/20 QP
    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    isStaff: boolean;
    serverHost: string;
    username: string;
    toolName: string;
    serverVersion: string;
    serverDescription: string;
    coreVersion: string;
    canvasVersion: string;
    uiVersion: string;
    switchSettingsShortcut: string;
}

class HeaderContainer extends React.PureComponent<HeaderContainerProps & RouteComponentProps> {
    public aboutModal(): void {
        const {
            toolName,
            serverVersion,
            serverDescription,
            coreVersion,
            canvasVersion,
            uiVersion,
        } = this.props;

        Modal.info({
            title: `${toolName}`,
            content: (
                <div>
                    <p>
                        {`${serverDescription}`}
                    </p>
                    <p>
                        <Text strong>
                            Server version:
                        </Text>
                        <Text type='secondary'>
                            {` ${serverVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Core version:
                        </Text>
                        <Text type='secondary'>
                            {` ${coreVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            Canvas version:
                        </Text>
                        <Text type='secondary'>
                            {` ${canvasVersion}`}
                        </Text>
                    </p>
                    <p>
                        <Text strong>
                            UI version:
                        </Text>
                        <Text type='secondary'>
                            {` ${uiVersion}`}
                        </Text>
                    </p>
                    <Row justify='space-around' />
                </div>
            ),
            width: 800,
            okButtonProps: {
                style: {
                    width: '100px',
                },
            },
        });
    }

    public render(): JSX.Element {
        const {
            username,
            switchSettingsShortcut,
            onLogout,
            logoutFetching,
            history,
        } = this.props;
        const { t } = this.props;

        const menus: MenuProps = {
            rootClassName: 'cvat-header-menu',
            items: [
                {
                    key: 'settings',
                    label: (
                        <span>{t('Settings')}</span>
                    ),
                    title: `Press ${switchSettingsShortcut} to switch`,
                    onClick: (): void => {
                        history.push('/settings');
                    },
                    icon: <SettingOutlined rev='' />,
                },
                {
                    key: 'about',
                    label: (
                        <span>{t('About')}</span>
                    ),
                    onClick: () => this.aboutModal(),
                    icon: <InfoCircleOutlined rev='' />,
                },
                {
                    key: 'logout',
                    label: (
                        <span>{t('Logout')}</span>
                    ),
                    onClick: onLogout,
                    disabled: logoutFetching,
                    icon: logoutFetching ? <LoadingOutlined rev='' /> : <LogoutOutlined rev='' />,
                },
            ],
        };

        return (
            <Layout.Header className='cvat-header'>
                <div className='cvat-left-header'>
                    <Icon className='cvat-logo-icon' component={EyesLogo} rev='' />
                </div>
                <div className='cvat-right-header'>
                    <Dropdown
                        menu={menus}
                        className='cvat-header-menu-dropdown'
                        trigger={['hover', 'click']}>
                        <span>
                            <Icon className='cvat-header-account-icon' component={AccountIcon} rev='' />
                            <div className='cvat-header-account'>
                                <span>{username.length > 14 ? `${username.slice(0, 10)} ...` : username}</span>
                            </div>
                        </span>
                    </Dropdown>
                </div>
            </Layout.Header>
        );
    }
}

export default withRouter(withTranslation()(HeaderContainer));
