import React from 'react';
import { connect } from 'react-redux';

import { CombinedState, Model, JobStatus } from 'reducers/interfaces';
import PredictionExamplesComponent from 'components/trained-model-page/prediction-examples';

interface StateToProps {
    jobStatus: JobStatus | null;
}

// interface DispatchToProps {}

interface OwnProps {
    model: Model;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps {
    const tid = own.model.task_id;
    const { trainings } = state.models.activities;
    const jobStatus = (tid != null && tid in trainings) ? trainings[tid] : null;
    return { jobStatus };
}

// function mapDispatchToProps(dispatch: any): DispatchToProps {
//     return {};
// }

function PredictionExamplesContainer(props: StateToProps & OwnProps): JSX.Element {
    return <PredictionExamplesComponent {...props} />;
}

export default connect(
    mapStateToProps,
    // mapDispatchToProps,
)(PredictionExamplesContainer);
